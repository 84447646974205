import { Dialog, Transition } from '@headlessui/react';
import { IconReportSearch } from '@tabler/icons-react';
import { Fragment, useState } from 'react';
import DetailScanTrxPembelian from './DetailScanTrxPembelian';
import DetailScanTrxPenjualan from './DetailScanTrxPenjualan';

export default function OpenSearchTrxJTrxB({
  isOpenModalSearch,
  setScanKode,
  closeModalSearch,
  scanKode,
  loadingGetDetailScan,
  dataScanKode,
  statusKode,
  msg,
  numberWithCommas,
}) {
  // console.log('dataScanKode::: ', dataScanKode);
  function fake(params) {}
  return (
    <>
      <Transition appear show={isOpenModalSearch} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={fake}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex mt-20 items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-md bg-white p-3 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Masukkan Kode Faktur <span className="text-sm text-red-500">Jual / Beli</span>
                  </Dialog.Title>
                  <div className="mt-2">
                    <form className={`flex items-center gap-3`}>
                      <input
                        type="text"
                        onChange={(e) => setScanKode(e.target.value)}
                        placeholder="Scan Untuk Mencari Surat"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      />
                    </form>
                    <div>
                      <div className="text-xl font-semibold">
                        {scanKode === '' || scanKode.length <= 11 ? (
                          ''
                        ) : (
                          <>
                            {dataScanKode.length == 0 ? (
                              <>
                                {loadingGetDetailScan ? (
                                  <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
                                    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <style
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                                        }}
                                      />
                                      <g className="spinner_Wezc">
                                        <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                                        <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                                        <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                                        <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                                        <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                                        <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                                        <circle cx={12} cy="21.5" r="1.5" />
                                      </g>
                                    </svg>
                                    Memuat data....
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              <>
                                {statusKode === 404 ? (
                                  <> {msg} </>
                                ) : (
                                  <>
                                    <div>
                                      {dataScanKode.kode_trx?.substring(0, 4) === 'FKDJ' ? (
                                        <DetailScanTrxPenjualan
                                          msg={msg}
                                          dataScanKode={dataScanKode}
                                          numberWithCommas={numberWithCommas}
                                        />
                                      ) : (
                                        <DetailScanTrxPembelian
                                          msg={msg}
                                          dataScanKode={dataScanKode}
                                          numberWithCommas={numberWithCommas}
                                        />
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-slate-500 px-4 py-2 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-slate-500 focus-visible:ring-offset-2"
                      onClick={closeModalSearch}
                    >
                      Keluar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
