import { Dialog, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { Fragment, useEffect, useState } from 'react';
import { getAuthorizationHeader, http } from '../config/http';

export default function ModalHistoriBarangTerhapus({ isOpen, closeModal }) {
  const [barangTerhapus, setBarangTerhapus] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function getBarangTerhapus() {
      setLoading(true);
      await http
        .get('/trash', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setBarangTerhapus(res.data.data.data);
        })
        .catch(() => {
          console.log('error');
        });
      setLoading(false);
    }
    getBarangTerhapus();
  }, []);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-md bg-white px-4 py-6  shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="text-red-500   flex justify-center font-medium leading-6 "
                  ></Dialog.Title>
                  <div>
                    <div className="bg-white rounded-lg md:max-w-lg h-[500px]">
                      <div className="">
                        <div className="flex items-center justify-between">
                          <h3 className="text-semibold border-b">Histori Barang Terhapus</h3>
                          <button onClick={closeModal} className="rounded-full p-1 bg-slate-100">
                            <IconX />
                          </button>
                        </div>
                        <div>
                          {loading ? (
                            <div>Loading....</div>
                          ) : (
                            <div className="relative overflow-x-auto">
                              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                  <tr>
                                    <th scope="col" className="px-3 py-2">
                                      No
                                    </th>
                                    <th scope="col" className="px-3 py-2">
                                      Kode Barang
                                    </th>
                                    <th scope="col" className="px-3 py-2">
                                      Nama Barang
                                    </th>
                                    <th scope="col" className="px-3 py-2">
                                      Harga Modal
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {barangTerhapus.map((bt, i) => (
                                    <tr
                                      key={i}
                                      className="hover:bg-gray-100 bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                    >
                                      <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                      >
                                        {i + 1}
                                      </th>
                                      <td className="px-3 py-2">{bt.kode_item}</td>
                                      <td className="px-3 py-2">{bt.jenis_kategori.nama_jenis}</td>
                                      <td className="px-3 py-2">{bt.harga_modal}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
