import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { CurrencyInput } from '../../utils/CurrencyInput';

export default function TambahPengeluaran({
  // cabang,
  loadingSubmit,
  isOpenTambahPengeluaran,
  handleSubmitPengeluaran,
  nominal,
  setNominal,
  handleOnChange,
  closeModalTambahPengeluaran,
}) {
  useEffect(() => {
    if (isOpenTambahPengeluaran == false) {
      setNominal(0);
    }
  }, [isOpenTambahPengeluaran]);

  function fake() {}

  return (
    <>
      <Transition appear show={isOpenTambahPengeluaran} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={fake}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-900">
                    Masukkan Pengeluaran
                  </Dialog.Title>
                  <div className="mt-2">
                    <div>
                      <label htmlFor="judul" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">
                        Judul
                      </label>
                      <input
                        type="judul"
                        id="judul"
                        name="title"
                        onChange={handleOnChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Masukkan Judul Pengeluaran"
                        required
                      />
                    </div>
                    <div className="mt-4">
                      <label htmlFor="nominal" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">
                        Nominal
                      </label>

                      <CurrencyInput
                        name="nominal"
                        value={nominal}
                        autoComplete="off"
                        // name="nominal"
                        onChange={(value) => setNominal(value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="mt-4">
                      <div>
                        <label htmlFor="tipe" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Tipe
                        </label>
                        <select
                          id="tipe"
                          onChange={handleOnChange}
                          name="tipe"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option>Pilih Type</option>
                          <option value="pengeluaran">Pengeluaran</option>
                          <option value="pemasukan">Pemasukan</option>
                          <option value="m-banking">M-Banking</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="mt-4">
                      <div>
                        <label
                          htmlFor="cabang_id"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Etalse
                        </label>
                        <select
                          id="cabang_id"
                          onChange={handleOnChange}
                          name="cabang_id"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option>Pilih Etalase</option>
                          {cabang.map((cb) => {
                            return (
                              <option key={cb.id} value={cb.id}>
                                {cb.nama_cabang}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div> */}
                    <div className="mt-4">
                      <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Keterangan
                      </label>
                      <textarea
                        id="message"
                        onChange={handleOnChange}
                        rows={4}
                        name="keterangan"
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50  border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Keterangan transaksi pengeluaran luar"
                        // defaultValue={formData.keterangan}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end gap-2 mt-4">
                    <button
                      type="reset"
                      onClick={closeModalTambahPengeluaran}
                      // disabled={loading ? true : false}
                      className="text-white  bg-slate-600 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium  text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                    >
                      Batal
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmitPengeluaran}
                      disabled={loadingSubmit ? true : false}
                      className="text-white  bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium  text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                    >
                      {loadingSubmit ? 'Proses..' : 'Simpan'}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
