import React from 'react';
import { imageBaseUrl } from '../../config/http';
import Barcode from 'react-barcode';

const TestingPrint = ({ detailPenjualan, isOpen, isTertunda }) => {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (
    <div className="pt-10">
      <div className="flex justify-end mr-28">
        {isTertunda ? (
          <div className="mt-7">{isOpen ? `${detailPenjualan?.tanggal_surat?.split(' ')[0]}` : ``}</div>
        ) : (
          <div className="mt-7">{isOpen ? `${detailPenjualan?.created_at?.split(' ')[0]}` : ``}</div>
        )}
      </div>
      <div className="flex justify-end mr-[80px] mt-[70px]">{detailPenjualan?.kode_trx}</div>
      <div className="mt-14">
        <div className="ml-[200px] ">1</div>
        <div className="ml-[225px] -mt-5 ">{detailPenjualan?.item?.jenis_kategori?.nama_jenis}</div>
        <div className="ml-[410px] -mt-5">{detailPenjualan?.item?.berat} gr</div>
        <div className="ml-[520px] -mt-5">{detailPenjualan?.item?.karat} krt</div>
        <div className="ml-[620px] -mt-5">-</div>
        <div className="ml-[700px] -mt-4">
          Rp {numberWithCommas(detailPenjualan?.total_harga == undefined ? '0' : detailPenjualan?.total_harga)}
        </div>
      </div>
      <div className="ml-10 mt-1">
        {isOpen ? (
          <img
            width={140}
            height={''}
            style={{ maxHeight: '275px', maxWidth: '400px' }}
            src={
              detailPenjualan?.item?.gambar1 === null ? '' : `${imageBaseUrl}${detailPenjualan?.item?.gambar1[0].asli}`
            }
          />
        ) : (
          <div className="ml-[20px] border w-[120px] h-[120px] mb-2"></div>
        )}
      </div>
      <div className="-mt-[70px] ml-[240px]">
        {/* <QRCode value={detailPenjualan.kode_trx === undefined ? 'unkwow' : detailPenjualan.kode_trx} size={60}  /> */}
        <Barcode
          value={detailPenjualan?.kode_trx === undefined ? 'unkwow' : detailPenjualan?.kode_trx}
          size={60}
          marginTop={-30}
          displayValue={false}
        />
      </div>
    </div>
  );
};

export default TestingPrint;
