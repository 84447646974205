import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthorizationHeader, http } from '../config/http';
import Layouts from '../layouts/Layouts';
import { DinamicTitle } from '../utils/DinamicTitle';

export default function ListUsers() {
  DinamicTitle('List Users || Komaladewi');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function getUsers() {
      setLoading(true);
      await http
        .get('/users', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setUsers(res.data.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate('/login');
          }
        });
      setLoading(false);
    }
    getUsers();
  }, [navigate]);
  return (
    <Layouts title={'List Users'}>
      <div className="container mx-auto  px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              {loading ? (
                <>
                  <div className="flex  items-center justify-center">
                    <div className="w-5 h-5  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
                    <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md">Memuat data....</div>
                  </div>
                </>
              ) : (
                <>
                  <table className="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Nomor
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Nama
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Username
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          email
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          role
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100" />
                      </tr>
                    </thead>

                    <tbody>
                      {users.map((user, i) => (
                        <tr key={user.id} className="hover:bg-slate-200 bg-white">
                          <td className="px-5 py-1 border-b border-gray-200 text-sm">
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">{i + 1}</p>
                            </div>
                          </td>
                          <td className="px-5 py-1 border-b border-gray-200 text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">{user.name}</p>
                          </td>
                          <td className="px-5 py-1 border-b border-gray-200 text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">{user.username}</p>
                          </td>
                          <td className="px-5 py-1 border-b border-gray-200 text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">{user.email}</p>
                          </td>
                          <td className="px-5 py-1 border-b border-gray-200 text-sm">
                            <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                              <span aria-hidden className="absolute inset-0 bg-green-200 opacity-50 rounded-full" />
                              <span className="relative">{user.role}</span>
                            </span>
                          </td>
                          <td className="px-5 py-1 border-b border-gray-200 text-sm text-right">
                            <button type="button" className="inline-block text-gray-500 hover:text-gray-700">
                              <svg className="inline-block h-6 w-6 fill-current" viewBox="0 0 24 24">
                                <path d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z" />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
}
