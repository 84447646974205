import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import ConfirmationRefundPenjualan from '../../../components/Corfirmation/ConfirmationRefundPenjualan';

export default function RfPenjualan({
  modalPenjualan,
  closeModalPenjualan,
  detailBarangPenjualan,
  refundBarang,
  loadingRf,
}) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  let [openConfirmationRefundPenjualan, setOpenConfirmationRefundPenjualan] = useState(false);

  function closeModalConfirmationRefundPenjualan() {
    setOpenConfirmationRefundPenjualan(false);
  }

  function openModalConfirmationRefundPenjualan() {
    setOpenConfirmationRefundPenjualan(true);
  }
  return (
    <>
      <Transition appear show={modalPenjualan} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalPenjualan}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <ConfirmationRefundPenjualan
            loadingRf={loadingRf}
            detailBarangPenjualan={detailBarangPenjualan}
            refundBarang={refundBarang}
            openConfirmationRefundPenjualan={openConfirmationRefundPenjualan}
            closeModalConfirmationRefundPenjualan={closeModalConfirmationRefundPenjualan}
          />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="div" className="text-lg font-medium leading-6 text-gray-900">
                    <span className="text-2xl">Detail Transaksi Penjualan</span>
                    <div className="text-base">
                      KODE FAKTUR Penjualan :{' '}
                      <span className="text-base font-light text-slate-700">{detailBarangPenjualan.kode_trx}</span>
                    </div>
                  </Dialog.Title>
                  <div className="border rounded ">
                    <div className="p-1 border-b bg-orange-500/10 text-slate-600">
                      <h2>Detail Barang</h2>
                    </div>
                    <div className="py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Jenis Barang
                      <p className="text-green-500">{detailBarangPenjualan.item?.jenis_kategori?.nama_jenis}</p>
                    </div>
                    <hr className="mx-3" />
                    <div className="py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Berat Item
                      <p className="text-green-500">{detailBarangPenjualan.item?.berat} gram</p>
                    </div>
                    <hr className="mx-3" />
                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Emas
                      <p className="text-green-500">{detailBarangPenjualan.item?.karat} karat</p>
                    </div>

                    <hr className="mx-3" />
                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Harga Jual / gram
                      <p className="text-green-500">
                        Rp{' '}
                        {numberWithCommas(
                          detailBarangPenjualan.harga_jual == undefined ? 0 : detailBarangPenjualan.harga_jual,
                        )}
                      </p>
                    </div>
                    <hr className="mx-3" />
                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Total Harga
                      <p className="text-green-500">
                        Rp{' '}
                        {numberWithCommas(
                          detailBarangPenjualan.total_harga == undefined ? 0 : detailBarangPenjualan.total_harga,
                        )}
                      </p>
                    </div>
                    <hr className="mx-3" />

                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Dijual di Cabang
                      <p className="text-green-500">{detailBarangPenjualan.user?.cabang?.nama_cabang}</p>
                    </div>
                    <hr className="mx-3" />

                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Tanggal Transaksi
                      <p className="text-green-500">{detailBarangPenjualan.created_at}</p>
                    </div>
                  </div>
                  <div className="py-2  flex  gap-2 justify-end">
                    <button
                      onClick={openModalConfirmationRefundPenjualan}
                      className="bg-green-500 text-white
                 rounded-md px-3 py-3 hover:bg-green-600 transition duration-300"
                    >
                      Refund
                    </button>
                    <button
                      onClick={closeModalPenjualan}
                      className="focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-100 bg-slate-600 hover:bg-slate-700 font-medium border"
                    >
                      Tutup
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
