import { Dialog, Transition } from '@headlessui/react';
import { IconQuestionMark } from '@tabler/icons-react';
import { Fragment, useState } from 'react';

export default function ConfirmationRefundPenjualan({
  loadingRf,
  detailBarangPenjualan,
  refundBarang,
  closeModalConfirmationRefundPenjualan,
  openConfirmationRefundPenjualan,
}) {
  return (
    <>
      <Transition appear show={openConfirmationRefundPenjualan} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalConfirmationRefundPenjualan}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    <div className="flex items-center justify-center">
                      <IconQuestionMark
                        className="border border-orange-500 text-orange-500 rounded-full"
                        width={80}
                        height={80}
                      />
                    </div>
                    <div className="flex justify-center text-xl">Apakah kamu yakin?</div>
                  </Dialog.Title>
                  <div className="mt-2 flex justify-center">
                    <p className="text-sm text-gray-500">
                      Silahkan tekan tombol refund barang{' '}
                      <span className="text-md font-semibold text-slate-700">
                        {detailBarangPenjualan.jenis_kategori?.nama_jenis}
                      </span>
                    </p>
                  </div>

                  <div className="mt-4 flex justify-center gap-3">
                    <button
                      onClick={refundBarang}
                      className="bg-green-500 text-white
                 rounded-md py-3 px-6 hover:bg-green-600 transition duration-300"
                    >
                      {loadingRf ? 'Proses..' : 'Refund'}
                    </button>
                    <button
                      onClick={closeModalConfirmationRefundPenjualan}
                      className="bg-gray-500 text-white
                 rounded-md py-3 px-6 hover:bg-gray-600 transition duration-300"
                    >
                      Tidak
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
