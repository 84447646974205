import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export default function ConfirmationPembelian({
  isOpenConfPembelian,
  harga_beli,
  formData,
  closeModalConfPembelian,
  handleSubmitPembelian,
  selectImage,
  loading,
}) {
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const average = Math.round(harga_beli / formData.berat);
  return (
    <>
      <Transition appear show={isOpenConfPembelian} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalConfPembelian}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-900">
                    <span className="text-red-600">PERHATIKAN!!</span>
                  </Dialog.Title>
                  <div className="mt-5 border rounded-md p-2">
                    <div className="flex items-center justify-between">
                      <h3 className="text-2xl font-semibold">Berat</h3>
                      <h2 className="text-2xl">
                        <span className="text-red-600">{formData.berat}</span> gram
                      </h2>
                    </div>
                    <div className="flex items-center justify-between">
                      <h3 className="text-2xl font-semibold">Harga Beli</h3>
                      <h2 className="animate-bounce text-2xl font-semibold text-red-600">
                        Rp {numberWithCommas(harga_beli)}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between">
                      <h3 className="text-2xl font-semibold">Rata - Rata</h3>
                      <h2 className="text-2xl text-yellow-600">
                        Rp {numberWithCommas(average === NaN ? '0' : average)}
                      </h2>
                    </div>
                    <div className="flex items-center justify-start mt-4">
                      <h2 className="text-2xl text-red-600">
                        {isOpenConfPembelian &&
                          selectImage.map((image, index) => (
                            <div key={index}>
                              <img width={120} src={image} key={index} alt="" srcSet="" />
                            </div>
                          ))}
                      </h2>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-end gap-2">
                    <button
                      onClick={closeModalConfPembelian}
                      className="flex items-center bg-slate-500 px-2.5 py-2 font-light hover:bg-slate-600 transition duration-500 rounded text-white"
                    >
                      Tutup
                    </button>
                    <button
                      onClick={handleSubmitPembelian}
                      disabled={loading ? true : false}
                      className="flex items-center bg-green-500 px-2.5 py-2 font-light hover:bg-green-600 transition duration-500 rounded text-white"
                    >
                      {loading ? 'proses...' : 'Simpan Pembelian'}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
