import { Dialog, Transition } from '@headlessui/react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Fragment, useState } from 'react';
import { getAuthorizationHeader, http } from '../../config/http';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function ModalTransaksiTertunda({
  idTransaksiTertunda,
  setOpenLoading,
  getTrxPenjualan,
  isOpenModalTransaksiTertunda,
  closeModaTransaksiTertunda,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  async function submitTransaksiTertunda() {
    setIsLoading(true);
    // console.log(idTransaksiTertunda)
    await http
      .put(`/transaksi_tertunda/proses/${idTransaksiTertunda}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        toast.success('Transaksi berhasil di pindah!!');
        closeModaTransaksiTertunda();
        getTrxPenjualan();
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        } else if (err.response.status === 404) {
          toast.error('Data Tidak di temukan!!');
        } else if (err.response.status === 409) {
          toast.error('Item telah menjadi transaksi asli');
        } else if (err.response.status === 422) {
          toast.error('Transaksi tidak dapat di proses Hari Ini');
        } else {
          toast.error('Terjadi Kesalahan');
        }
      });
    setIsLoading(false);
  }
  return (
    <>
      <Transition appear show={isOpenModalTransaksiTertunda} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModaTransaksiTertunda}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[420px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden  bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="text-red-500   flex justify-center font-medium leading-6 "
                  ></Dialog.Title>
                  <div>
                    <div className="bg-white rounded-lg md:max-w-md ">
                      <div className="flex items-center gap-4">
                        <div className=" flex items-center justify-center w-16 h-16 ">
                          <IconAlertTriangle className="border rounded-full p-2" width={70} height={70} />
                        </div>
                        <div>
                          Apakah anda yakin ingin?
                          <span className="font-bold text-sm">Selesaikan Transaksi</span>
                        </div>
                      </div>
                      <div className="text-right mt-2 gap-2  flex justify-end">
                      <button
                          onClick={closeModaTransaksiTertunda}
                          className="bg-slate-500  text-white px-4 py-2"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={submitTransaksiTertunda}
                          className="bg-orange-500  text-white px-4 py-2"
                        >
                          {isLoading ? 'Loading....' : 'Selesaikan Transaksi'}
                        </button>
                        
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
