import { useEffect } from 'react';
import { useState } from 'react';
import { getAuthorizationHeader, http } from '../../../config/http';
import {
  Chart as ChartJs,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

ChartJs.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
);
export default function GrafikChart() {
  const [chartDataPenjualan, setChartDataPenjualan] = useState({});
  const [chartDataPembelian, setChartDataPembelian] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    async function getGrafikPembelian() {
      try {
        const res = await http.get('/grafik/pembelian', {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setChartDataPembelian({
          labels: res.data.map((lb) => lb.bulan),
          datasets: [
            {
              label: 'Pembelian',
              data: res.data.map((item) => item.total_pembelian),
              fill: true,
              borderColor: 'rgb(255,99,132)',
              backgroundColor: '#0000F6',
              yAxisID: 'y',
            },
            {
              label: 'Total Item',
              data: res.data.map((item) => item.total_item),
              fill: true,
              borderColor: 'rgb(255,99,132)',
              backgroundColor: '#880808',
              yAxisID: 'pembelian',
            },
          ],
        });
        return res.data;
      } catch (err) {
        // console.log('err::: ', err);
        if (err.response == 'undefined') {
          return null;
        } else if (err.response.status == 401) {
          navigate('/login');
        }
      }
    }
    getGrafikPembelian();
  }, [navigate]);

  useEffect(() => {
    async function getGrafikPenjualan() {
      await http
        .get(`/grafik/penjualan`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          // console.log(res.data.map((ti) => ti.total_item));
          const chartPenjualan = {
            labels: res.data.map((dt) => dt.bulan),
            datasets: [
              {
                label: 'Penjualan',
                data: res.data.map((item) => item.total_penjualan),
                fill: true,
                borderColor: 'rgb(255,99,132)',
                backgroundColor: '#00A14E',
                yAxisID: 'y',
              },
              {
                label: 'Laba',
                data: res.data.map((item) => item.total_laba),
                fill: true,
                borderColor: 'rgb(255,99,132)',
                backgroundColor: '#ED901B',
              },
              {
                label: 'Total Item',
                data: res.data.map((item) => item.total_item),
                fill: true,
                borderColor: 'rgb(255,99,132)',
                backgroundColor: '#880808',
                yAxisID: 'penjualan',
              },
            ],
          };
          setChartDataPenjualan(chartPenjualan);
        })
        .catch((err) => {
          if (err.response == 'undefined') {
            return null;
          } else if (err.response.status == 401) {
            navigate('/login');
          }
        });
    }
    getGrafikPenjualan();
  }, [navigate]);

  return (
    <div className="grid gap-2 md:grid-cols-2 mx-3 ">
      <div className="">
        <div className="bg-white rounded shadow-md md:p-4 ">
          {chartDataPenjualan && chartDataPenjualan.datasets && (
            <Bar
              data={chartDataPenjualan}
              options={{
                scales: {
                  y: {
                    beginAtZero: true,
                    type: 'linear',
                    position: 'left',
                  },
                  penjualan: {
                    beginAtZero: true,
                    type: 'linear',
                    position: 'right',
                  },
                },
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  legend: { position: 'top' },
                  title: { display: true, text: 'Penjualan' },
                },
              }}
            />
          )}
        </div>
      </div>
      <div className="">
        <div className="bg-white p-4 ">
          {chartDataPembelian && chartDataPembelian.datasets && (
            <Bar
              data={chartDataPembelian}
              options={{
                scales: {
                  y: {
                    beginAtZero: true,
                    type: 'linear',
                    position: 'left',
                  },
                  pembelian: {
                    beginAtZero: true,
                    type: 'linear',
                    position: 'right',
                  },
                },
                responsive: true,
                plugins: {
                  legend: { position: 'top' },
                  title: { display: true, text: 'Pembelian' },
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
