import {
  IconCalendarTime,
  IconChevronLeft,
  IconChevronRight,
  IconListDetails,
  IconPrinter,
  IconReload,
  IconSearch,
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultItem from '../components/Admin/DefaultItem';
import { getAuthorizationHeader, http } from '../config/http';
import Layouts from '../layouts/Layouts';
import InputBarang from '../components/Admin/InputBarang';
import DetailItem from './Modal/DetailItem';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import PrintBandrol from '../components/Admin/PrintBandrol';
import { DinamicTitle } from '../utils/DinamicTitle';
import EditItem from './Modal/EditItem';
import Input from '../components/Input';
import ConfirmationDeleteItem from '../components/Admin/ConfirmationDeleteItem';
import { toast } from 'react-toastify';
import useKarat from './features/useKarat';
import { IconTransferIn } from '@tabler/icons-react';
import TransferBanyakBarang from '../components/Admin/PindahBarangBanyak';
import TransferBarangToCabang from '../components/Admin/TransferBarangToCabang';
import useCabang from './features/useCabang';
import LoadingIndicator from '../components/LoadingIndicator';

function ListItemAdmin() {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  // console.log('items::: ', items);
  const [kategori, setKategori] = useState([]);
  const [selectedKategori, setSelectedKategori] = useState('');
  const [bakiId, setBakiId] = useState('');
  const [selectJenisKategori, setSelectJenisKategori] = useState('');
  const [totalModal, setTotalModal] = useState(0);
  const [jmlBarang, setJmlBarang] = useState(0);
  const [jmlBerat, setJmlBerat] = useState(0);
  // const [cabang, setCabang] = useState([]);
  const { cabang } = useCabang();

  const [isSubmitItem, setIsSubmitItem] = useState(false);

  const [detailItem, setDetailItem] = useState([]);
  // console.log('detailItem::: ', detailItem);
  const [openLoading, setOpenLoading] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  //   const [openCalender, setOpenCalender] = useState(false);

  let [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(0);
  const [selectStatus, setSelectStatus] = useState('');
  const [selectCabang, setSelectCabang] = useState('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const awal = moment(startDate).format('DD-MM-YYYY');
  const akhir = moment(endDate).format('DD-MM-YYYY');

  const [openPrintBandrol, setOpenPrintBandrol] = useState(false);
  const [openPindahBanyakBarang, setOpenPindahBanyakBarang] = useState(false);
  const [totalModalFilter, setTotalModalFilter] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const [deleteId, setDeleteId] = useState('');
  const [barangDelete, setBarangDelete] = useState([]);
  const [loadingDel, setLoadingDel] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const { karatOption, karatOptionId, setKaratOptionId } = useKarat();
  const [filterBerat, setFilterBerat] = useState('');

  const [berat, setBerat] = useState('');

  const filterBeratBarang = () => {
    setBerat(filterBerat);
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  };
  function handleSelect(date) {
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // useEffect(() => {
  //   async function getCabangSelect() {
  //     await http
  //       .get(`cabang`, {
  //         headers: { Authorization: getAuthorizationHeader() },
  //       })
  //       .then((res) => {
  //         setCabang(res.data.data);
  //       })
  //       .catch((err) => {
  //         if (err.response === 'undefined') {
  //           return null;
  //         } else if (err.response.status === 401) {
  //           navigate('/login');
  //         }
  //       });
  //   }
  //   getCabangSelect();
  // }, [navigate]);

  async function getKategori() {
    await http
      .get('/kategori', {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setKategori(res.data);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
  }
  useEffect(() => {
    getKategori();
  }, [navigate]);

  const handleKategoriChange = (event) => {
    setSelectedKategori(event.target.value);
    setSelectJenisKategori('');
  };

  const handleJenisKategoriChange = (event) => {
    setSelectJenisKategori(event.target.value);
  };

  const [search, setSearch] = useState('');

  async function getItems() {
    setLoading(true);
    await http
      .get(
        `/item${search === '' ? `?page=${currentPage}` : `?kode=${search}`}${selectedKategori === '' ? `` : `&kategori=${selectedKategori}`}${selectJenisKategori === '' ? `` : `&jenis_kategori=${selectJenisKategori}`
        }${selectStatus === '' ? `` : `&status=${selectStatus}`}${selectCabang === '' ? `` : `&cabang=${selectCabang}`}${startDate === null && endDate === null ? `` : `&tgl_awal=${awal}&tgl_akhir=${akhir}`
        }${bakiId === '' ? '' : `&no_baki=${bakiId}`}${karatOptionId === '' ? '' : `&karat=${karatOptionId}`}${berat === '' ? '' : `&berat=${berat}`}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        },
      )
      .then((res) => {
        setItems(res.data.data.data);
        setTotalModal(res.data.total_modal);
        setTotalModalFilter(res.data.total_modal_filter);
        setJmlBarang(res.data.data.total);
        setJmlBerat(res.data.total_berat_page);
        setPage(res.data.data.last_page);
        setItemsPerPage(res.data.data.per_page);
        setCurrentPage(res.data.data.current_page);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoading(false);
  }
  useEffect(() => {
    getItems();
  }, [
    selectedKategori,
    bakiId,
    isDelete,
    search.length >= 10 ? search : '',
    selectJenisKategori,
    isEdit,
    currentPage,
    selectStatus,
    selectCabang,
    awal,
    akhir,
    isSubmitItem,
    karatOptionId,
    berat,
  ]);
  function closeModal() {
    setIsOpen(false);
  }
  function closeModalDetail() {
    setOpenDetail(false);
  }

  function openModal() {
    setIsOpen(true);
    console.log('hallo');
  }
  function checkBerat(number) {
    const beratConfirm = parseFloat(number);
    return beratConfirm % 1 === 0
      ? beratConfirm.toString()
      : beratConfirm
        .toFixed(2)
        .replace(/\.?0+$/, '')
        .replace('.', ',');
  }

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isCheckedItems, setIsCheckedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectPindahItems, setSelectPindahItems] = useState([]);
  // console.log(selectPindahItems);
  // SELECT CHECKBOX
  function handleCheckboxChange(event, item) {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, item]);
      setSelectPindahItems([...selectPindahItems, item]);
    } else {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
      setSelectPindahItems(selectPindahItems.filter((selectedItem) => selectedItem !== item));
    }
  }

  function handleCheckAll(event) {
    setIsCheckedAll(event.target.checked);
    setIsCheckedItems(isCheckedItems.map(() => event.target.checked));
    setSelectedItems([...items]);
    setSelectPindahItems([...items]);
    if (event.target.checked === false) {
      setSelectedItems([]);
      setSelectPindahItems([]);
    }
  }
  // END SELECT CHECKBOX
  function closeModalPrintBandrol() {
    setOpenPrintBandrol(false);
  }
  function closeModalPindahBarangBanyak() {
    setOpenPindahBanyakBarang(false);
  }
  function openModalPindahBarangBanyak() {
    setOpenPindahBanyakBarang(true);
  }
  function openModalPrintBandrol() {
    setOpenPrintBandrol(true);
  }

  function nextPage() {
    setCurrentPage(currentPage + 1);
    setSelectedItems([]);
    setIsCheckedAll(false);
  }

  function prevPage() {
    setCurrentPage(currentPage - 1);
    setSelectedItems([]);
    setIsCheckedAll(false);
  }
  DinamicTitle('List Barang || Komaladewi');

  let [isOpenEditItem, setIsOpenEditItem] = useState(false);

  function closeModalEditItem() {
    setIsOpenEditItem(false);
  }

  function openModalEditItem() {
    setIsOpenEditItem(true);
  }

  async function getItemEdit(id) {
    setOpenLoading(true);
    await http
      .get(`item/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setDetailItem(res.data.data);
        openModalEditItem();
      });
    setOpenLoading(false);
  }

  const [filterBaki, setFilterBaki] = useState([]);

  useEffect(() => {
    const numbersArray = [];
    for (let i = 1; i <= 20; i++) {
      numbersArray.push(i);
    }
    setFilterBaki(numbersArray);
  }, []);

  const [itemsPerPage, setItemsPerPage] = useState('');
  const startIndex = (currentPage - 1) * itemsPerPage;

  async function showDetailItem(id) {
    setOpenLoading(true);
    await http
      .get(`item/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setDetailItem(res.data.data);
        setOpenDetail(true);
      });
    setOpenLoading(false);
  }

  let [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  let [isOpenModalPindah, setIsOpenModalPindah] = useState(false);
  const [loadingPin, setLoadingPin] = useState(false);


  function closeModaDelete() {
    setIsOpenModalDelete(false);
  }

  function openModalDelete() {
    setIsOpenModalDelete(true);
  }

  function closeModaPindah() {
    setIsOpenModalPindah(false);
  }

  function openModalPindah() {
    setIsOpenModalPindah(true);
  }

  async function getIdPindahItem(id) {
    setLoadingPin(true);
    await http
      .get(`item/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setDetailItem(res.data.data);
        openModalPindah();
        setLoadingPin(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getIdDelete(id) {
    setLoadingDel(true);
    await http
      .get(`item/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        openModalDelete();
        setDeleteId(res.data.data.id);
        setBarangDelete(res.data.data);
        setLoadingDel(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingDel(false);
  }
  const [keterangan, setKeterangan] = useState('')
  async function deleteBarang(e) {
    e.preventDefault();
    setIsDelete(true);
    await http
      .post(`item/hapus`, {
        item_id: deleteId,
        keterangan: keterangan
      }, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        closeModaDelete();
        toast.success(res.data.message);
        // console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
    setIsDelete(false);
  }

  const user = localStorage.getItem('role');
  const [openModalTransferToToko, setOpenModalTransferToToko] = useState(false)
  function closeOpenModalTransferToToko() {
    setOpenModalTransferToToko(false);
  }
  function reloadDate() {
    getItems();
  }

  return (
    <Layouts
      title={'List Barang'}
      dateFilter={`${awal === 'Invalid date' ? '' : ` ${awal}`} ${awal == akhir ? `` : `${akhir === 'Invalid date' ? '' : ` s/d ${akhir}`}`}`}
      compFilter={
        <div className="flex items-center gap-0.5">
          <button onClick={() => setOpenModalTransferToToko(true)} className='p-2.5 bg-blue-500 text-white'>
            Transfer
          </button>
          <div className="dropdown dropdown-bottom ">
            <div tabIndex={0} className="bg-orange-500 text-white p-2 cursor-pointer">
              <IconCalendarTime />
            </div>
            <div tabIndex={0} className="dropdown-content menu -right-52 p-2">
              <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
            </div>
          </div>
          <button onClick={() => window.location.reload()} className="bg-slate-800 text-white p-2 cursor-pointer">
            <IconReload className="text-white" />
          </button>
          <Input
            onChange={(e) => setSearch(e.target.value)}
            className="input input-bordered rounded-none p-1"
            autoComplete="off"
            type="search"
            name="search"
            placeholder="Cari Kode Barang"
          />
        </div>
      }
      openModal={openModal}
    >
      <ConfirmationDeleteItem
        reloadDate={reloadDate}
        isDelete={isDelete}
        deleteBarang={deleteBarang}
        setKeterangan={setKeterangan}
        barangDelete={barangDelete}
        isOpenModalDelete={isOpenModalDelete}
        closeModaDelete={closeModaDelete}
      />
      <InputBarang
        setIsSubmitItem={() => setIsSubmitItem(true)}
        closeModal={closeModal}
        isOpen={isOpen}
        kategori={kategori}
      />
      <EditItem
        setIsEdit={setIsEdit}
        kategori={kategori}
        cabang={cabang}
        detailItem={detailItem}
        isOpenEditItem={isOpenEditItem}
        closeModalEditItem={closeModalEditItem}
      />
      <DetailItem
        checkBerat={checkBerat}
        detailItem={detailItem}
        closeModalDetail={closeModalDetail}
        openDetail={openDetail}
      />
      {openLoading || loadingDel || loadingPin ? (
        <LoadingIndicator />
      ) : (
        ''
      )}
      <div className="flex bg-white items-center gap-3 mt-0.5">
        {/* filter */}
        <div className="flex items-center gap-3 w-full scrollbar overflow-x-auto  ">
          <div className="flex items-center gap-0.5 justify-between">
            <div>
              <div className="relative group">
                <select
                  value={selectedKategori}
                  onChange={handleKategoriChange}
                  className="border p-2 cursor-pointer"
                >
                  <option className="pt-6" value="">
                    KATEGORI
                  </option>
                  {kategori.map((kt) => (
                    <option key={kt.id} value={kt.id}>
                      {kt.nama_kategori}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <div className="relative group">
                {selectedKategori && (
                  <select
                    onChange={handleJenisKategoriChange}
                    value={selectJenisKategori}
                    className="p-2 border cursor-pointer"
                  >
                    <option value="">JENIS KATEGORI</option>
                    {kategori
                      .find((kt) => kt.id == selectedKategori)
                      ?.jenis_kategori.map((jk) => (
                        <option key={jk.id} value={jk.id}>
                          {jk.nama_jenis}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
            <div className="">
              <div className="relative group ">
                <select
                  onChange={(e) => setSelectStatus(e.target.value)}
                  className="p-2 border cursor-pointer"
                >
                  <option className="pt-6">STATUS</option>
                  <option className="pt-6" value={1}>
                    Tersedia
                  </option>
                  <option className="pt-6" value={0}>
                    Tidak Tersedia
                  </option>
                </select>
              </div>
            </div>
            <div className="">
              <div className="relative group ">
                <select
                  onChange={(e) => setBakiId(e.target.value)}
                  className="p-2 border cursor-pointer"
                >
                  <option className="pt-6">BAKI</option>
                  {filterBaki.map((ftb) => {
                    return (
                      <option value={ftb} key={ftb}>
                        {ftb}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="">
              <div className="relative group ">
                <select
                  onChange={(e) => setSelectCabang(e.target.value)}
                  className="p-2 border cursor-pointer"
                >
                  <option className="pt-6" value="">
                    ETALASE
                  </option>
                  {cabang.map((cb) => (
                    <option key={cb.id} value={cb.id}>
                      {cb.nama_cabang}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <div className="relative group ">
                <select
                  onChange={(e) => setKaratOptionId(e.target.value)}
                  className="p-2 border cursor-pointer"
                >
                  <option className="pt-6" value="">
                    KARAT
                  </option>
                  {karatOption.map((kto) => {
                    return (
                      <option value={kto} key={kto}>
                        {kto}
                      </option>
                    );
                  })}
                </select>
              </div>

            </div>
            <div className="max-w-lg flex w-52 gap-0.5 items-center">
              <Input onChange={(e) => setFilterBerat(e.target.value)} placeholder="Cari Berat" />
              <button onClick={filterBeratBarang} className="bg-teal-500 px-1.5 h-[2.30rem] text-white">
                <IconSearch />
              </button>
            </div>
          </div>
          <div className="flex w-full justify-end md:mr-1">
            <div className="flex gap-0.5 items-center justify-end">

              {user !== 'admin' && (
                <div
                  onClick={openModalPindahBarangBanyak}
                  className="bg-blue-500 text-white p-1.5 cursor-pointer"
                >
                  <IconTransferIn />
                </div>
              )}
              <button onClick={reloadDate} className="bg-slate-800 text-white p-1.5 cursor-pointer">
                <IconReload className="text-white" />
              </button>
              <div onClick={openModalPrintBandrol} className="bg-orange-500 text-white p-1.5 cursor-pointer">
                <IconPrinter />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <PrintBandrol
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openPrintBandrol={openPrintBandrol}
          closeModalPrintBandrol={closeModalPrintBandrol}
        />
        <TransferBarangToCabang
          openPindahBarangBanyak={openModalTransferToToko}
          setOpenPindahBanyakBarang={setOpenModalTransferToToko}
          reloadDate={reloadDate}
          closeModalBanyakBarang={closeOpenModalTransferToToko}
          selectedItems={selectPindahItems}
          refreshPage={refreshPage}
          setSelectedItems={setSelectPindahItems}
        />
        <TransferBanyakBarang
          cabang={cabang}
          reloadDate={reloadDate}
          selectedItems={selectPindahItems}
          setSelectedItems={setSelectPindahItems}
          openPindahBarangBanyak={openPindahBanyakBarang}
          setOpenPindahBanyakBarang={setOpenPindahBanyakBarang}
          closeModalBanyakBarang={closeModalPindahBarangBanyak}
          refreshPage={refreshPage}
        />

        {loading ? (
          <div className="flex  ">
            <div className=" font-semibold px-2  rounded-br-md rounded-bl-md flex items-center gap-3">
              <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                  }}
                />
                <g className="spinner_Wezc">
                  <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                  <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                  <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                  <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                  <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                  <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                  <circle cx={12} cy="21.5" r="1.5" />
                </g>
              </svg>
              Memuat data....
            </div>
          </div>
        ) : (
          <div className="relative mt-0.5 overflow-x-auto shadow-md border-t overflow-hidden">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs sticky top-0 border-b text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>

                  <th scope="col" className="px-2 border-r py-3 whitespace-nowrap">
                    No
                  </th>
                  <th scope="col" className="px-2 border-r py-3 whitespace-nowrap">
                    Kode Barang
                  </th>
                  <th scope="col" className="px-6 border-r py-3 whitespace-nowrap">
                    Nama Barang
                  </th>
                  <th scope="col" className="px-6 border-r py-3 whitespace-nowrap">
                    BAKI
                  </th>
                  <th scope="col" className="px-6 border-r py-3 whitespace-nowrap">
                    Berat | Karat
                  </th>
                  <th scope="col" className="px-6 border-r py-3 whitespace-nowrap">
                    Lokasi
                  </th>
                  <th scope="col" className="px-4 border-r py-3 whitespace-nowrap">
                    option
                  </th>
                  <th scope="col">
                    <input
                      type="checkbox"
                      checked={isCheckedAll}
                      onChange={handleCheckAll}
                      className="cursor-pointer ml-2 checkbox checkbox-primary w-4 h-4 border-slate-700 rounded"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <DefaultItem
                  cabang={cabang}
                  currentPage={currentPage}
                  setSelectedItems={setSelectedItems}
                  startIndex={startIndex}
                  getIdDelete={getIdDelete}
                  getIdPindahItem={getIdPindahItem}
                  page={page}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedItems={selectedItems}
                  checkBerat={checkBerat}
                  showDetailItem={showDetailItem}
                  openModalEditItem={openModalEditItem}
                  getItemEdit={getItemEdit}
                  items={items}
                  search={search}
                  isCheckedItems={isCheckedItems}
                />
              </tbody>
            </table>
          </div>
        )}
        <div className="bg-white bottom-0   left-0 py-2 w-full absolute">
          <div className="flex justify-between">
            <div className="lg:flex hidden items-center  gap-4 mx-3 lg:mx-10">
              <div className="md:flex items-center">
                <div className="text-base underline uppercase ">MODAL : &nbsp; </div>
                <div className="text-base whitespace-nowrap font-semibold text-slate-800">
                  Rp {numberWithCommas(totalModal == totalModalFilter ? totalModal : totalModalFilter)}
                </div>
              </div>
              <div className="md:flex items-center">
                <div className="text-sm md:text-base whitespace-nowrap underline  uppercase ">Total Barang : </div>
                <div className="text-base font-semibold text-slate-800">&nbsp; {jmlBarang} barang</div>
              </div>
              <div className="md:flex items-center">
                <div className="text-sm md:text-base whitespace-nowrap underline  uppercase ">Total Berat : </div>
                <div className="text-base font-semibold text-slate-800">&nbsp; {jmlBerat} gram</div>
              </div>
            </div>
            {/* mobile */}
            <div className="dropdown dropdown-top lg:hidden mx-3 lg:mx-10">
              <div
                tabIndex={0}
                className="bg-green-600 cursor-pointer hover:bg-green-700 hover:shadow-md hover:scale-110 p-2 text-white rounded-md"
              >
                <IconListDetails />
              </div>
              <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-[350px]">
                <div className="  gap-4 mx-10">
                  <div className="flex items-center justify-between mb-4 border-b">
                    <div className="text-base font-semibold uppercase ">MODAL : </div>
                    <div className="text-base whitespace-nowrap font-semibold text-slate-800">
                      Rp {numberWithCommas(totalModal == totalModalFilter ? totalModal : totalModalFilter)}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-4 border-b">
                    <div className="text-base font-semibold uppercase ">Total Barang : </div>
                    <div className="text-base font-semibold text-slate-800">{jmlBarang} barang</div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-base font-semibold uppercase">Total Berat : </div>
                    <div className="text-base font-semibold text-slate-800"> {jmlBerat} gram</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="items-center flex justify-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 mr-4 sm:flex">
              <span className="block">
                Page {currentPage} of {page}
              </span>
              <div className="space-x-1">
                <button
                  onClick={prevPage}
                  disabled={currentPage < 1}
                  title="previous"
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                >
                  <IconChevronLeft />
                </button>
                <button
                  onClick={nextPage}
                  title="next"
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                >
                  <IconChevronRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
}

export default ListItemAdmin;
