import { IconChevronDown, IconChevronLeft, IconChevronRight, IconReload } from '@tabler/icons-react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthorizationHeader, http } from '../../config/http';
import Layouts from '../../layouts/Layouts';
import { DinamicTitle } from '../../utils/DinamicTitle';
import DataOpname from './DataOpname';
import DetailBarangOpname from './DetailBarangOpname';

export default function BarangOpname() {
  DinamicTitle('Stok Opname || Komaladewi');
  let [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [barangOpname, setBarangOpname] = useState([]);
  const [totalBarangOpname, setTotalBarangOpname] = useState('');
  const [cabangOpname, setCabangOpname] = useState([]);
  const [detailBarangOpname, setDetailBarangOpname] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitUpdate, setSubmitUpdate] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const [selectCabang, setSelectCabang] = useState('');
  const [search, setSearch] = useState('');
  const [current_page, setCurrentPage] = useState(1);
  const [selectTeknisi, setSelectTeknisi] = useState('');
  const [page, setPage] = useState('');
  const [teknisi, setTeknisi] = useState([]);

  useEffect(() => {
    async function getTeknisi() {
      await http
        .get(`/teknisi`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setTeknisi(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getTeknisi();
  }, []);

  useEffect(() => {
    async function getCabangOpname() {
      await http
        .get(`cabang`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setCabangOpname(res.data.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getCabangOpname();
  }, [navigate]);

  useEffect(() => {
    async function getBarangOpname() {
      setLoading(true);
      await http
        .get(
          `stok-opname?page=${current_page}${selectCabang === '' ? '' : `&cabang=${selectCabang}`}${selectTeknisi === '' ? `` : `&teknisi=${selectTeknisi}`}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          },
        )
        .then((res) => {
          setPage(res.data.data.last_page);
          setBarangOpname(res.data.data.data);
          setTotalBarangOpname(res.data.data?.total);
          setCurrentPage(res.data.data.current_page);
          setSubmitUpdate(false);
          return res.data.data;
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
      setLoading(false);
    }
    getBarangOpname();
  }, [isSubmitUpdate === true, selectCabang, navigate, selectTeknisi]);

  async function getDetailBarangOpname(id) {
    setOpenLoading(true);
    await http
      .get(`/stok-opname/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setDetailBarangOpname(res.data.data);
        setIsOpen(true);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setOpenLoading(false);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Layouts>
      <div className="flex-grow mx-4 flex">
        {openLoading ? (
          <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
            <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
          </div>
        ) : (
          <DetailBarangOpname
            isOpen={isOpen}
            setSubmitUpdate={() => setSubmitUpdate(true)}
            detailBarangOpname={detailBarangOpname}
            closeModal={closeModal}
          />
        )}

        <div className="flex flex-col bg-blue-gray-50  w-full py-4">
          <div className="flex items-center gap-3 mb-1   rounded-md">
            <div className="flex justify-end">
              <input
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search...."
                className="input shadow-md input-bordered input-md w-full max-w-xs"
              />
            </div>
            <div className="flex items-center justify-between gap-0.5">
              <div className=" flex items-center ">
                <div className="relative rounded-md  group  py-1">
                  <select
                    onChange={(e) => setSelectCabang(e.target.value)}
                    className=" appearance-none focus:border-none border-none inline-block py-3 pl-3 pr-8 rounded-md leading-tight w-full shadow-md "
                  >
                    <option className="pt-6" value="">
                      Pilih Etalase
                    </option>
                    {cabangOpname.map((cb) => (
                      <option key={cb.id} value={cb.id}>
                        {cb.nama_cabang}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center ">
                    <IconChevronDown className="stroke-1 w-4" />
                  </div>
                </div>
                <div className="relative rounded-md  group px-2 py-1">
                  <select
                    onChange={(e) => setSelectTeknisi(e.target.value)}
                    className=" appearance-none focus:border-none border-none inline-block py-3 pl-3 pr-8 rounded-md leading-tight w-full shadow-md "
                  >
                    <option className="pt-6" value="">
                      Pilih Teknisi
                    </option>
                    {teknisi.map((tk) => (
                      <option key={tk.id} value={tk.id}>
                        {tk.nama_teknisi}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center px-2">
                    <IconChevronDown className="stroke-1 w-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="flex  items-center justify-center">
              <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
                <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                    }}
                  />
                  <g className="spinner_Wezc">
                    <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                    <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                    <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                    <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                    <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                    <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                    <circle cx={12} cy="21.5" r="1.5" />
                  </g>
                </svg>
                Memuat data....
              </div>
            </div>
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs sticky top-0 border-b text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      KODE FAKTUR
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Kondisi
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Berat
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Emas
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Teknisi
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Total Harga
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <DataOpname
                    search={search}
                    getDetailBarangOpname={getDetailBarangOpname}
                    barangOpname={barangOpname}
                  />
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Layouts>
  );
}
