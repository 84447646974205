import { IconCalendar, IconChevronLeft, IconChevronRight, IconSearch } from '@tabler/icons-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import { getAuthorizationHeader, http } from '../../config/http';
import Layouts from '../../layouts/Layouts';
import { DinamicTitle } from '../../utils/DinamicTitle';
import { IconReload } from '@tabler/icons-react';
import useKarat from '../features/useKarat';
import DetailHistoriTransfer from './DetailHistoriTransfer';

const HistoriTransferMasuk = () => {
  const navigate = useNavigate();
  const { karatOption, karatOptionId, setKaratOptionId } = useKarat();
  const [historiBarangKeluar, setHistoriBarangKeluar] = useState([]);
  const [openLoading, setOpenLoading] = useState(false);
  const [detailBarangTransferKeluar, setDetailTransferBarangKeluar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectCabang, setSelectCabang] = useState([]);
  const [idCabang, setIdCabang] = useState(0);
  const [idKategori, setIdKategori] = useState(0);
  const [kategori, setKategori] = useState([]);

  const [openCalender, setOpenCalender] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const awal = moment(startDate).format('DD-MM-YYYY');
  const akhir = moment(endDate).format('DD-MM-YYYY');

  // const [totalHargaPenjualan, setTotalHargaPenjualan] = useState(0);
  // const [totalHargaFilter, setTotalHargaFilter] = useState(0);

  const [filterBerat, setFilterBerat] = useState('');
  const [berat, setBerat] = useState('');

  const filterBeratBarang = () => {
    setBerat(filterBerat);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState('');

  // function numberWithCommas(x) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }

  const [itemsPerPage, setItemsPerPage] = useState('');
  const startIndex = (currentPage - 1) * itemsPerPage;

  useEffect(() => {
    async function getHistoriTransferKeluar() {
      setLoading(true);
      await http
        .get(
          `/history_transfer/barang_masuk?cabang_id=${idCabang}
          ${idKategori === 0 ? `` : `&kategori=${idKategori}`}${startDate === null && endDate === null ? `` : `&tgl_awal=${awal}&tgl_akhir=${akhir}`
          }${karatOptionId === '' ? '' : `&karat=${karatOptionId}`}${berat === '' ? '' : `&berat=${berat}`}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          },
        )
        .then((res) => {
          // console.log(res.data.data)
          setHistoriBarangKeluar(res.data.data);
          setCurrentPage(res.data.data.current_page);
          setItemsPerPage(res.data.data.per_page);
          setLastPage(res.data.data.last_page);
          return res.data.data;
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
      setLoading(false);
    }
    if (idCabang !== 0) {
      getHistoriTransferKeluar();
    } else {
    }
  }, [
    idCabang,
    idKategori,
    search.length >= 12 ? search : '',
    awal,
    akhir,
    startDate,
    endDate,
    currentPage,
    navigate,
    karatOptionId,
    berat,
  ]);

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  async function showDetailPenjualan(id) {
    setOpenLoading(true);
    await http
      .get(`history_transfer/detail_barang/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setDetailTransferBarangKeluar(res.data.data);
        setIsOpen(true);
      });
    setOpenLoading(false);
  }

  useEffect(() => {
    async function getCabangSelect() {
      await http
        .get(`cabang/brankas`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setSelectCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getCabangSelect();
  }, [navigate]);

  useEffect(() => {
    async function getKategori() {
      await http
        .get('/kategori', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setKategori(res.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getKategori();
  }, []);

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  };

  function handleSelect(date) {
    // console.log('date::: ', date);
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
  }

  function checkBerat(number) {
    const beratConfirm = parseFloat(number);
    return beratConfirm % 1 === 0
      ? beratConfirm.toString()
      : beratConfirm
        .toFixed(2)
        .replace(/\.?0+$/, '')
        .replace('.', ',');
  }
  DinamicTitle('Histori Transfer || Komaladewi');

  return (
    <Layouts
      title={'Histori Transfer (Masuk)'}
      compFilter={
        <div className="flex items-center gap-0.5">
          <div className="dropdown dropdown-bottom">
            <div
              tabIndex={0}
              onClick={() => setOpenCalender(!openCalender)}
              className="bg-orange-500 text-white p-1 cursor-pointer"
            >
              <IconCalendar className="text-white" />
            </div>
            <div tabIndex={0} className={`dropdown-content -right-40 menu p-2 shadow bg-base-100`}>
              <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
            </div>
          </div>
          <div onClick={() => window.location.reload()} className="bg-gray-800 text-white p-1 cursor-pointer">
            <IconReload className="text-white" />
          </div>
          {/* <div className="flex justify-end ">
            <Input className="input input-bordered" type="text" onChange={(e) => setSearch(e.target.value)} autoComplete="off" placeholder="Cari Penjualan" />
          </div> */}
        </div>
      }
    >
      <div className="mt-0.5 ">
        <div className="flex bg-white items-center justify-between scrollbar-none overflow-x-auto">
          <div className="flex items-center justify-center md:justify-start w-full gap-3">
            <div>
              <div className="flex  gap-0.5">
                <select
                  name=""
                  id=""
                  onChange={(e) => setIdCabang(e.target.value)}
                  className=" p-2 cursor-pointer border-r "
                >
                  <option value="">Asal</option>
                  {selectCabang?.map((cb) => (
                    <option value={cb.id} key={cb.id}>
                      {cb.nama_cabang}
                    </option>
                  ))}
                </select>
                <select
                  name=""
                  id=""
                  onChange={(e) => setIdKategori(e.target.value)}
                  className=" p-2 cursor-pointer border-r "
                >
                  <option value="">Kategori</option>
                  {kategori.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.nama_kategori}
                    </option>
                  ))}
                </select>
                <select
                  name=""
                  id=""
                  onChange={(e) => setKaratOptionId(e.target.value)}
                  className="p-2  cursor-pointer "
                >
                  <option value=" ">Karat</option>
                  {karatOption.map((kto) => (
                    <option key={kto} value={kto}>
                      {kto}
                    </option>
                  ))}
                </select>

                <div className="max-w-lg flex w-52 gap-0.5 items-center">
                  <Input onChange={(e) => setFilterBerat(e.target.value)} placeholder="Cari Berat" />
                  <button onClick={filterBeratBarang} className="bg-teal-500 px-1.5 h-[2.30rem] text-white">
                    <IconSearch />
                  </button>
                  <button
                    onClick={() => window.location.reload()}
                    className="bg-gray-800 text-white px-1.5 h-[2.30rem]"
                  >
                    <IconReload />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='border-t mt-0.5'>
          <DetailHistoriTransfer
            label={'Detail Histori Transfer Keluar'}
            checkBerat={checkBerat}
            closeModal={closeModal}
            detailPenjualan={detailBarangTransferKeluar}
            isOpen={isOpen}
          />
          {openLoading ? (
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
              <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
            </div>
          ) : (
            ''
          )}
          <div className="flex gap-2">
            <div className="inline-block min-w-full shadow-md overflow-hidden">
              <div className="relative overflow-x-auto shadow-md  overflow-hidden scrollbar-thin">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        No
                      </th>
                      <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Kode Barang
                      </th>
                      <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Nama Barang
                      </th>
                      {/* <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Kode
                      </th> */}
                      <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Berat/Karat
                      </th>
                      {/* <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Harga
                      </th>
                      <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Rata/Gram
                      </th>
                      <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Kasir
                      </th> */}
                      <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Tanggal Transfer
                      </th>
                      {/* <th className="px-5 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Status
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <>
                        <tr className="flex  items-center justify-center">
                          <td className="w-5 h-5  rounded-full animate-spin border-4 border-dashed border-slate-800 border-t-transparent"></td>
                          <td className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md">Memuat data....</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {historiBarangKeluar?.map((trx, i) => (
                          <tr
                            key={trx.id}
                            onClick={() => showDetailPenjualan(trx.id)}
                            className="hover:bg-gray-100 bg-white cursor-pointer"
                          >
                            <td className="px-5 py-0 border-r border-b border-gray-200  text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">{i + 1}.</p>
                            </td>
                            <td className="px-5 py-0 border-r whitespace-nowrap border-b border-gray-200  text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {trx?.kode_item}
                              </p>
                            </td>
                            <td className="px-5 py-0 border-r whitespace-nowrap border-b border-gray-200  text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {trx?.jenis_kategori?.nama_jenis}{' '}
                                {/* {trx.is_refund == false ? `` : <span className="text-red-600 text-sm font-light">dikembalikan</span>}{' '} */}
                              </p>
                            </td>

                            <td className="px-5 py-0 border-r whitespace-nowrap border-b border-gray-200  text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {trx?.berat} gr / {trx?.karat} krt
                              </p>
                            </td>
                            {/* <td className="px-5 py-0 border-r whitespace-nowrap border-b border-gray-200  text-sm">
                              Rp {numberWithCommas(trx.total_harga == undefined ? '0' : trx.total_harga)}
                            </td>
                            <td className="px-5 py-0 border-r whitespace-nowrap border-b border-gray-200  text-sm">
                              Rp {numberWithCommas(trx.total_harga == undefined ? '0' : Math.round(trx.total_harga / trx.item?.berat))} / gr
                            </td>
                            <td className="px-5 py-0 border-r whitespace-nowrap border-b border-gray-200  text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">{trx.user?.name}</p>
                            </td> */}
                            <td className="px-5 py-0 border-r whitespace-nowrap border-b border-gray-200  text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">{trx.created_at}</p>
                            </td>
                            {/* <td className="px-5 py-0 whitespace-nowrap border-b border-gray-200  text-sm">
                              <p>{trx.is_refund ? (
                                  <Tooltip title="Refuned">
                                  <div className='bg-red-700 w-4 h-4 rounded-full'/>
                                </Tooltip>
                              ) : (
                                <Tooltip title="not Refund">
                                  <div className='bg-green-700 w-4 h-4 rounded-full'/>
                                </Tooltip>
                              )}</p>
                            </td> */}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white bottom-0 flex justify-between left-0 py-4 w-full absolute">
        <div className="flex justify-end w-full mx-5">
          <div className="items-center flex justify-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 sm:flex">
            <span className="block">
              Page {currentPage} of {lastPage}{' '}
            </span>
            <div className="space-x-1">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                title="previous"
                type="button"
                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
              >
                <IconChevronLeft />
              </button>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                title="next"
                type="button"
                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
              >
                <IconChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default HistoriTransferMasuk;
