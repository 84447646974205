import { Navigate, useLocation } from 'react-router-dom';

export default function ProtectedRoute({ children }) {
  const auth = {
    token: localStorage.getItem('token'),
  };
  const location = useLocation();
  if (!auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
