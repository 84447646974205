import { useEffect, useState } from 'react';

const useKarat = () => {
  const [karatOptionId, setKaratOptionId] = useState('');
  // console.log('karatOptionId::: ', karatOptionId);
  const [karatOption, setKaratOption] = useState([]);

  useEffect(() => {
    const options = [];
    for (let i = 16; i <= 24; i++) {
      options.push(i);
    }
    setKaratOption(options);
  }, []);
  return {
    karatOption,
    setKaratOptionId,
    karatOptionId,
  };
};

export default useKarat;
