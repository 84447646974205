import { Dialog, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { Fragment } from 'react';

export default function KodeItemShow({ closeOpenKode, openKode, kode }) {
  return (
    <>
      <Transition appear show={openKode} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeOpenKode}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform  rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Kode Barang
                  </Dialog.Title>
                  <div
                    onClick={closeOpenKode}
                    className="absolute cursor-pointer text-white top-[-5px] rounded-full p-1 right-[-5px] bg-slate-500"
                  >
                    <IconX />
                  </div>
                  <div className="mt-2">
                    <h2 className="text-normal font-semibold text-gray-500">{kode}</h2>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
