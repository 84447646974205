import { IconCalendarEvent, IconCalendarTime, IconReload, IconPlus } from '@tabler/icons-react';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Calendar } from 'react-date-range';
import CustomBerat from './CustomBerat';

function SmPembelian({
  cabangFilterSm,
  summaryPembelian,
  totalBeratPembelian,
  loadingDataPembelian,
  totalHargaPembelian,
  setIdCabangFilterPembelian,
  setDateFilterPembelian,
  reloadPembelian,
  totalBijiPembelian,
  setKaratFilterPembelian
}) {

  
  const [customBerat, setCustomBerat] = useState('');
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  function handleSelect(date) {
    // console.log(moment(date).format('DD-MM-YYYY'));
    setDateFilterPembelian(moment(date).format('DD-MM-YYYY'));
  }
  const average = Math.round(totalHargaPembelian / totalBeratPembelian)
  const karatOption = [{ karat: 16 }, { karat: 17 }, { karat: 18 }, { karat: 20 }, { karat: 21 }, { karat: 22 }, { karat: 23 }, { karat: 24 }];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="">
      <div className="px-4 pt-2 pb-1 flex items-center justify-between bg-green-500/50">
        <h6 className="font-bold uppercase text-sm text-slate-900">Pembelian</h6>
        <div className="flex items-center gap-3">
          <select className="p-1 cursor-pointer" onChange={(e) => setIdCabangFilterPembelian(e.target.value)} name="" id="">
            <option value=" ">Pilih Etalase</option>
            {cabangFilterSm.map((cbf) => (
              <option key={cbf.id} value={cbf.id}>
                {cbf.nama_cabang}
              </option>
            ))}
          </select>
          <select id='' onChange={(e) => setKaratFilterPembelian(e.target.value)} className="p-1 cursor-pointer">
            <option value=" ">Pilih Karat</option>
            {karatOption.map((item, i) => (
              <option key={i} value={item.karat}>
                {item.karat}
              </option>
            ))}
          </select>
          <div className="dropdown dropdown-bottom">
            <div tabIndex={0} className="bg-orange-500 text-white p-1 rounded cursor-pointer">
              <IconCalendarTime size={20} />
            </div>
            <div tabIndex={0} className="dropdown-content menu -right-52 ">
              <Calendar size={20} onChange={handleSelect} date={new Date()} />
            </div>
          </div>
          <button onClick={reloadPembelian} className="text-white p-1 rounded bg-orange-500">
            <IconReload size={20} />
          </button>
          <CustomBerat setCustomBerat={setCustomBerat} dropdown={dropdown} dropdownOpen={dropdownOpen} setDropdownOpen={setDropdownOpen} trigger={trigger} />
        </div>
      </div>
      <div className="p-2 overflow-x-auto">
        {loadingDataPembelian ? (
          <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
            <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                }}
              />
              <g className="spinner_Wezc">
                <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                <circle cx={12} cy="21.5" r="1.5" />
              </g>
            </svg>
            Memuat data....
          </div>
        ) : (
          <table className="w-full border text-sm text-left rounded-md overflow-hidden">
            <thead className="text-xs sticky top-0 border-b uppercase bg-gray-50 rounded-md dark:bg-gray-700">
              <tr>
                <th>
                  No
                </th>
                <th>
                  Kategori
                </th>
                <th>
                  biji
                </th>
                <th>
                  berat
                </th>
                <th>
                  Harga
                </th>
                <th>
                  Rata - Rata
                </th>
              </tr>
            </thead>
            <tbody>
              {summaryPembelian.map((sp, i) => {
                const rata_rata = Math.round(sp.total_harga_pembelian / sp.total_berat_pembelian)
                return (
                  <tr
                    key={i} className="border-b"
                  >
                    <td>{i + 1}.</td>
                    <td>{sp.kategori}</td>
                    <td>{sp.jumlah_item_pembelian}</td>
                    <td>{sp.total_berat_pembelian}</td>
                    <td>
                      Rp {numberWithCommas(sp.total_harga_pembelian == undefined ? '0' : sp.total_harga_pembelian)}
                    </td>
                    <td>
                      Rp {numberWithCommas(isNaN(rata_rata) ? 0 : rata_rata)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        <hr />
        <div className="mt-2 border text-sm rounded-md p-1 overflow-x-auto scrollbar-none flex items-center">
          {/* <div className="font-semibold flex justify-between">JUMLAH :  </div> */}
          <div className="whitespace-nowrap">
            [ <span className="text-red-600">{totalBijiPembelian}</span> biji ]&nbsp;
          </div>
          <div className="whitespace-nowrap ml-3">
            [ <span className="text-red-600">{totalBeratPembelian}</span> gram ]&nbsp;
          </div>
          <div className="whitespace-nowrap ml-3">
            [{' '}
            <span className="text-red-600">Rp.{numberWithCommas(totalHargaPembelian == undefined ? '0' : totalHargaPembelian)}</span>
            ]&nbsp;
          </div>
          <div className="whitespace-nowrap ml-3">
            [ rata-rata  <span className="text-red-600">Rp.
              {numberWithCommas(isNaN(average) ? 0 : average)}
            </span>
            ]
          </div>
          <div className="whitespace-nowrap ml-3">
            {customBerat !== '' && (
              <>
                <span className='text-sm'>berat lebih : </span>
                <span className="text-red-600">
                  {customBerat}
                </span> &nbsp;
                {customBerat !== '' && 'gram'}

              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmPembelian;


// <div className="ml-[75px] font-semibold">  {totalBeratPembelian}</div>
//           <div className="ml-[70px] font-semibold">Rp {numberWithCommas(totalHargaPembelian == undefined ? '0' : totalHargaPembelian)}</div>
