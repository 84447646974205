import React, { useState } from 'react';
import Layouts from '../../layouts/Layouts';
import { DinamicTitle } from '../../utils/DinamicTitle';
import Masuk from './Masuk';
import Keluar from './Keluar';

const TransferBarang = () => {
  const dummyData = {
    status: true,
    message: 'Data barang masuk',
    data: [
      {
        id: 9,
        cabang_id: 1,
        user_id: 2,
        jenis_kategori_id: 4,
        kode_item: 'C1302230007',
        nomor_rak: '21',
        berat: '2.000',
        karat: 22,
        harga_modal: 859000,
        is_ready: true,
        is_brankas: 0,
        gambar1: null,
        to_toko: '2024-06-01 19:15:01',
        to_brankas: '2024-05-30 23:09:54',
        is_hapus: 0,
        created_at: '30/05/2024 23:09:54',
        updated_at: '01/06/2024 19:15:01',
      },
    ],
  };
  const [barangMasuk, setBarangMasuk] = useState(dummyData.data);

  DinamicTitle('Transfer Barang');
  return (
    <Layouts title={'Histori Transfer Barang'}>
      <div className="p-4">
        <div className="">
          <Masuk barangMasuk={barangMasuk} />
        </div>
      </div>
    </Layouts>
  );
};

export default TransferBarang;
