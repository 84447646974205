import React, { useEffect, useState } from 'react'
import Layouts from '../layouts/Layouts'
import useBarangTerhapus from './features/useBarangTerhapus';
import useKategori from './features/useKategori';
import useCabang from './features/useCabang';
import { IconCalendarTime, IconEdit, IconRefresh, IconEye } from '@tabler/icons-react';
import { DateRangePicker } from 'react-date-range';
import { DinamicTitle } from '../utils/DinamicTitle';
import RestoreBarang from './Modal/RestoreBarang';
import { IconChevronLeft } from '@tabler/icons-react';
import { IconChevronRight } from '@tabler/icons-react';
import DetailItem from './Modal/DetailItem';
import { getAuthorizationHeader, http } from '../config/http';
import LoadingIndicator from '../components/LoadingIndicator';
import Input from '../components/Input';
import { IconSearch } from '@tabler/icons-react';
import { IconReload } from '@tabler/icons-react';

const BarangTerhapus = () => {
    DinamicTitle('List Barang dikeluarkan')
    const {
        barangTerhapus,
        loadingBarangTerhapus,
        selectedKategori,
        setSelectedKategori,
        selectJenisKategori,
        setSelectJenisKategori,
        setSelectCabang,
        karatOption,
        setKaratOptionId,
        selectionRange,
        getBarangTerhapus,
        handleSelect,
        prevPage,
        nextPage,
        currentPage,
        filterBeratBarang,
        setFilterBerat,
        page
    } = useBarangTerhapus();

    function reloadData() {
        getBarangTerhapus();
    }
    const { kategori, loadingKategori } = useKategori();
    const { cabang } = useCabang();
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isOpenModalRestore, setIsOpenModalRestore] = useState(false);
    const [detailItemTerhapus, setDetailItemTerhapus] = useState(null);
    const [isOpenDetailItemHapus, setIsOpenDetailItemHapus] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    function closeDetailItemHapus() {
        setIsOpenDetailItemHapus(false);
    }
    function openDetailItemHapus() {
        setIsOpenDetailItemHapus(true);
    }
    const closeModalRestore = () => {
        setIsOpenModalRestore(false)
    }

    const handleSingleSelect = (id) => {
        const isSelected = selectedIds.includes(id);
        if (isSelected) {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedIds([]);
            setSelectAll(false);
        } else {
            const allIds = barangTerhapus.map((bt) => bt.id);
            setSelectedIds(allIds);
            setSelectAll(true);
        }
    };

    const handleKategoriChange = (event) => {
        setSelectedKategori(event.target.value);
        setSelectJenisKategori('');
    };
    const handleJenisKategoriChange = (event) => {
        setSelectJenisKategori(event.target.value);
    };
    async function getDetailItemTerhapus(id) {
        // console.log(id);
        setOpenLoading(true);
        await http
            .get(`/trash/${id}`, {
                headers: { Authorization: getAuthorizationHeader() },
            })
            .then((res) => {
                setDetailItemTerhapus(res.data.data);
                openDetailItemHapus();
            });
        setOpenLoading(false);
    }

    return (
        <Layouts title={'List Barang dikeluarkan'}
            compFilter={
                <div className='flex items-center gap-0.5'>
                    <button onClick={() => setIsOpenModalRestore(true)} className='bg-green-500 px-1 py-2 text-white'>
                        Restore Barang
                    </button>
                    <div className="dropdown dropdown-bottom ">
                        <div tabIndex={0} className="bg-orange-500 text-white p-1.5 cursor-pointer">
                            <IconCalendarTime />
                        </div>
                        <div tabIndex={0} className="dropdown-content menu -right-36 ">
                            <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
                        </div>
                    </div>
                    <button onClick={reloadData} className="bg-slate-800 text-white p-1.5 cursor-pointer">
                        <IconReload className="text-white" />
                    </button>
                </div>
            }
        >
            <RestoreBarang
                reloadData={reloadData}
                selectedIds={selectedIds}
                closeModalRestore={closeModalRestore}
                isOpenModalRestore={isOpenModalRestore}
            />
            <DetailItem
                closeModalDetail={closeDetailItemHapus}
                detailItem={detailItemTerhapus}
                openDetail={isOpenDetailItemHapus}
            />
            {openLoading && <LoadingIndicator />}
            <div className='bg-white mt-0.5 flex'>
                <select
                    onChange={(e) => setSelectCabang(e.target.value)}
                    className="p-2 border border-t-0 cursor-pointer mr-0.5"
                >
                    <option className="pt-6" value="">
                        Etalase
                    </option>
                    {cabang.map((cb) => (
                        <option key={cb.id} value={cb.id}>
                            {cb.nama_cabang}
                        </option>
                    ))}
                </select>
                <select
                    value={selectedKategori}
                    onChange={handleKategoriChange}
                    className=" border border-t-0 p-2 mr-0.5 cursor-pointer active:border-none"
                >
                    <option className="pt-6" value="">
                        Pilih Kategori
                    </option>
                    {loadingKategori && (
                        <option disabled className="pt-6" value="">
                            Loading....
                        </option>
                    )}
                    {kategori.map((kt) => (
                        <option key={kt.id} value={kt.id}>
                            {kt.nama_kategori}
                        </option>
                    ))}
                </select>
                {selectedKategori && (
                    <select
                        onChange={handleJenisKategoriChange}
                        value={selectJenisKategori}
                        className="border border-t-0 p-2 mr-0.5 cursor-pointer"
                    >
                        <option value="">Pilih Jenis</option>
                        {kategori
                            .find((kt) => kt.id == selectedKategori)
                            ?.jenis_kategori.map((jk) => (
                                <option key={jk.id} value={jk.id}>
                                    {jk.nama_jenis}
                                </option>
                            ))}
                    </select>
                )}
                <select
                    onChange={(e) => setKaratOptionId(e.target.value)}
                    className="p-2 border border-t-0 mr-0.5 cursor-pointer"
                >
                    <option className="pt-6" value="">
                        Filter Karat
                    </option>
                    {karatOption.map((kto) => {
                        return (
                            <option value={kto} key={kto}>
                                {kto}
                            </option>
                        );
                    })}
                </select>
                <div className="max-w-lg flex w-52 gap-0.5 items-center">
                    <Input onChange={(e) => setFilterBerat(e.target.value)} placeholder="Cari Berat" />
                    <button onClick={filterBeratBarang} className="bg-teal-500 px-1.5 h-[2.30rem] text-white">
                        <IconSearch />
                    </button>
                </div>
            </div>
            {loadingBarangTerhapus ? (
                <span>Loading....</span>
            ) : (
                <div className="shadow-lg mt-0.5 rounded overflow-hidden">
                    <table className="w-full table-fixed">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="w-[5%] py-2 px-3 text-left border-r text-gray-600 font-smibold">No</th>
                                <th className="w-1/4 py-2 px-3 text-left border-r text-gray-600 font-smibold">Kode Barang</th>
                                <th className="w-1/4 py-2 px-3 text-left border-r text-gray-600 font-smibold">Nama Barang</th>
                                <th className="w-1/4 py-2 px-3 text-left border-r text-gray-600 font-smibold">Berat | Karat</th>
                                <th className="w-1/4 py-2 px-3 text-left border-r text-gray-600 font-smibold">Tanggal Hapus</th>
                                <th className="w-[10%] py-2 px-3 text-left border-r text-gray-600 font-smibold">Action</th>
                                <th className="w-[5%] py-2 px-3 text-left border-r text-gray-600 font-smibold">
                                    <input
                                        type="checkbox"
                                        checked={selectAll}
                                        className='cursor-pointer checkbox checkbox-primary w-4 h-4 border-slate-700 rounded'
                                        onChange={handleSelectAll}
                                        name=""
                                        id=""
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white ">
                            {barangTerhapus.map((bt, i) => (
                                <tr key={i} className='hover:bg-slate-200'>
                                    <td className="py-2 px-3 border-b border-r border-gray-200">{i + 1}</td>
                                    <td className="py-2 px-3 border-b border-r border-gray-200 truncate">{bt.kode_item}</td>
                                    <td className="py-2 px-3 border-b border-r border-gray-200 truncate">{bt.jenis_kategori.nama_jenis}</td>
                                    <td className="py-2 px-3 border-b border-r border-gray-200">{`${bt.berat} gr | ${bt.karat} Krt`}</td>
                                    <td className="py-2 px-3 border-b border-r border-gray-200">
                                        {bt.created_at}
                                    </td>
                                    <td className="w-[5%] text-center py=-y px-3 border-b border-r border-gray-200">
                                        <IconEye onClick={() => getDetailItemTerhapus(bt.id)} className='bg-green-500 p-1 text-white cursor-pointer' />
                                        {/* <IconEdit onClick={() => getDetailItemTerhapus(bt.id)} className='bg-green-500 p-1 text-white cursor-pointer' /> */}
                                    </td>
                                    <td className="py-2 px-3 border-b border-r border-gray-200">
                                        <input
                                            checked={selectedIds.includes(bt.id)}
                                            onChange={() => handleSingleSelect(bt.id)}
                                            className='cursor-pointer checkbox checkbox-primary w-4 h-4 border-slate-700 rounded'
                                            type="checkbox"
                                            name=""
                                            id=""
                                        />
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>

                </div>
            )}
            <div className='bg-white bottom-0 left-0 py-2 w-full absolute'>
                <div className="items-center flex justify-end space-y-2 text-xs sm:space-y-0 sm:space-x-3 mr-4 sm:flex">
                    <span className="block">
                        Page {currentPage} of {page}
                    </span>
                    <div className="space-x-1">
                        <button
                            onClick={prevPage}
                            disabled={currentPage < 1}
                            title="previous"
                            type="button"
                            className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                        >
                            <IconChevronLeft />
                        </button>
                        <button
                            onClick={nextPage}
                            title="next"
                            type="button"
                            className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                        >
                            <IconChevronRight />
                        </button>
                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default BarangTerhapus