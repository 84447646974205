import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuthorizationHeader, http } from '../config/http';

export default function InputBarangRusak({
  isOpenModalBarangRusak,
  setIsSubmitBarangRusak,
  kategori,
  closeModalBarangRusak,
}) {
  const navigate = useNavigate();

  const [jenis_kategori_id, setJeniKategoriId] = useState('');
  const [berat, setBerat] = useState('');
  const [karat, setKarat] = useState('');
  // const [cabang_id, setCabangId] = useState('');
  const [jenisKategori, setJenisKategori] = useState([]);
  const [keterangan, setKeterangan] = useState('');

  const [loadingSubmitBarangRusak, setLoadingSubmitBarangRusak] = useState(false);

  // console.log({
  //   jenis_kategori: jenis_kategori_id,
  //   berat: berat,
  //   karat: karat,
  //   keterangan: keterangan,
  // });

  useEffect(() => {
    const regex = /^\d*\.?\d{0,3}$/;
    if (berat && !regex.test(berat)) {
      if (berat.length > 3) {
        setBerat(berat.slice(0, berat.length - 1));
      } else {
        setBerat(berat);
      }
    }
  }, [berat]);

  async function selectJenisKategori(id) {
    await http
      .get(`/jenis/kategori/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setJenisKategori(res.data.data);
        // console.log('data ::', res)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function resetForm() {
    setJeniKategoriId('');
    setBerat('');
    setKarat('');
    setKeterangan('');
  }

  async function handleSubmitBarangRusak(e) {
    e.preventDefault();
    setLoadingSubmitBarangRusak(true);
    setIsSubmitBarangRusak(true);
    const data = {
      jenis_kategori_id,
      berat,
      karat,
      keterangan,
    };
    try {
      const response = await http.post(`item_rusak`, data, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      toast.success('Berhasil Menambahkan Barang Rusak!!!');
      setLoadingSubmitBarangRusak(false);

      resetForm();
      closeModalBarangRusak();
    } catch (error) {
      console.log(error);
    }
    setIsSubmitBarangRusak(false);
    setLoadingSubmitBarangRusak(false);
  }

  const karatOption = [
    { karat: 16 },
    { karat: 18 },
    { karat: 20 },
    { karat: 21 },
    { karat: 22 },
    { karat: 23 },
    { karat: 24 },
  ];
  function fake(params) {}

  return (
    <>
      <Transition appear show={isOpenModalBarangRusak} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={fake}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-2xl   font-semibold mb-2 leading-6 text-gray-900">
                    Tambah Barang Rusak
                  </Dialog.Title>
                  <div className="mt-6">
                    <div className="mb-6">
                      <label
                        htmlFor="kategori"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Pilih Kategori
                      </label>
                      <select
                        onChange={(e) => selectJenisKategori(e.target.value)}
                        className="select select-bordered w-full rounded-none"
                      >
                        <option value="">Pilih Kategori</option>
                        {kategori.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nama_kategori}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="kategori"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Pilih Jenis
                      </label>
                      <select
                        value={jenis_kategori_id}
                        onChange={(e) => setJeniKategoriId(e.target.value)}
                        className="select select-bordered w-full rounded-none"
                      >
                        <option value="">Pilih Jenis</option>
                        {jenisKategori.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nama_jenis}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="mb-6">
                        <label htmlFor="berat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Berat
                        </label>
                        <input
                          type="number"
                          id="berat"
                          autoComplete="off"
                          value={berat}
                          onChange={(e) => setBerat(e.target.value)}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        />
                      </div>
                      <div className="mb-6">
                        <label htmlFor="karat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Karat
                        </label>
                        <select
                          value={karat}
                          id={'karat'}
                          onChange={(e) => setKarat(e.target.value)}
                          className="select select-bordered w-full rounded-none"
                        >
                          <option value="">Pilih Karat</option>
                          {karatOption.map((item, i) => (
                            <option key={i} value={item.karat}>
                              {item.karat}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Keterangan
                    </label>
                    <textarea
                      id="message"
                      value={keterangan}
                      onChange={(e) => setKeterangan(e.target.value)}
                      rows={4}
                      name="keterangan"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50  border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Keterangan barang rusak / barang dikeluarkan"
                      // defaultValue={formData.keterangan}
                    />
                  </div>
                  <div className="flex justify-end mt-6 gap-2">
                    <button
                      type="reset"
                      onClick={closeModalBarangRusak}
                      // disabled={loading ? true : false}
                      className="text-white  bg-slate-600 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                    >
                      Batal
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmitBarangRusak}
                      disabled={loadingSubmitBarangRusak ? true : false}
                      className="text-white  bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                    >
                      {loadingSubmitBarangRusak ? 'Proses..' : 'Simpan'}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
