import React from 'react'

const LoadingIndicator = () => {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full flex items-center justify-center h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 ">
            <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
        </div>
    )
}

export default LoadingIndicator