import React from 'react';

function DataOpname({ barangOpname, getDetailBarangOpname, search }) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (
    <>
      {barangOpname
        .filter((data) => data.pembelian?.kode_trx?.includes(search))
        .map((bo, i) => (
          <tr
            key={bo.id}
            onClick={() => getDetailBarangOpname(bo.id)}
            className="bg-white border-b dark:bg-gray-800 cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            <td className="px-6 py-2">{i + 1}</td>
            <td className="px-6 py-2">{bo.pembelian?.kode_trx}</td>
            <td className="px-6 py-2">{bo.pembelian?.kondisi_barang}</td>
            <td className="px-6 py-2"> {bo.pembelian.berat} gram</td>
            <td className="px-6 py-2"> {bo.pembelian?.karat} karat</td>
            <td className="px-6 py-2"> {bo.teknisi?.nama_teknisi}</td>
            <td className="px-6 py-2">
              Rp {numberWithCommas(bo.pembelian?.harga_beli == undefined ? '0' : bo.pembelian?.harga_beli)}
            </td>
          </tr>
        ))}
    </>
  );
}

export default DataOpname;
