import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuthorizationHeader, http } from '../../config/http';
import { CurrencyInput } from '../../utils/CurrencyInput';
import KodeItemBarangOpname from './KodeItemBarangOpname';

export default function DetailBarangOpname({ closeModal, isOpen, detailBarangOpname, setSubmitUpdate }) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    berat: null,
  });
  const [loading, setLoading] = useState(false);

  // TRANFER BERAT
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [tranferData, setTranferData] = useState({
    berat: '',
  });
  const [cabang, setCabang] = useState([]);
  const [cabang_id, setCabangId] = useState('');
  const [harga_modal, setHargaModal] = useState(0);

  const [kodeBarangTranfer, setKodeBarangTransfer] = useState('');

  async function handleSubmitTranferBarang(e) {
    const berat = tranferData.berat;
    e.preventDefault();
    const data = {
      cabang_id,
      harga_modal,
      berat,
    };
    setLoadingTransfer(true);
    try {
      const response = await http.post(`/stok-opname/transfer_item/${detailBarangOpname.pembelian?.kode_trx}`, data, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      // setKodeBarangTransfer(response.data.data)
      setKodeBarangTransfer(response.data.data.kode_item);
      openModalKodeBarang();
      toast.success(response.data.message);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
    setLoadingTransfer(false);
  }

  // END TRANFER BERAT

  useEffect(() => {
    const regex = /^\d*\.?\d{0,3}$/;
    if (tranferData.berat && !regex.test(tranferData.berat)) {
      setFormData({
        berat: tranferData.berat.slice(0, -1),
      });
    }
  }, [tranferData.berat]);

  function handleChangeBeratTranfer(e) {
    setTranferData({ ...tranferData, [e.target.name]: e.target.value });
  }

  function handleChangeEdit(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const { berat } = formData;

  async function handleSubmitEditDataOpname(e) {
    e.preventDefault();
    const data = {
      berat,
    };
    setLoading(true);
    try {
      const response = await http.put(`/stok-opname/${detailBarangOpname.id}`, data, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      toast.success(response.data.message);
      setSubmitUpdate();
      closeModal();
      return response.data;
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    async function getCabangSelect() {
      await http
        .get(`cabang`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getCabangSelect();
  }, [navigate]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  let [isOpenKodeBarang, setIsOpenKodeBarang] = useState(false);

  function closeModalKodeBarang() {
    setIsOpenKodeBarang(false);
    window.location.reload();
  }

  function openModalKodeBarang() {
    setIsOpenKodeBarang(true);
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <KodeItemBarangOpname
            kodeBarangTranfer={kodeBarangTranfer}
            closeModalKodeBarang={closeModalKodeBarang}
            isOpenKodeBarang={isOpenKodeBarang}
          />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-md bg-white px-6 pb-3 pt-5 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="div" className="text-2xl mb-2 font-medium leading-6 text-gray-900">
                    Detail Barang Opname
                    <div className="text-base">
                      KODE FAKTUR Pembelian :{' '}
                      <span className="text-base font-light text-slate-700">
                        {detailBarangOpname.pembelian?.kode_trx}
                      </span>
                    </div>
                  </Dialog.Title>
                  <div className="grid grid-cols-2 gap-2">
                    <form action="" method="post">
                      <div className="border rounded">
                        <div className="p-1 border-b bg-orange-500/10 text-slate-600">
                          <h2>Detail Barang</h2>
                        </div>
                        <div className="py-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Berat
                          <p className="text-green-500">{detailBarangOpname.pembelian?.berat} gram</p>
                        </div>
                        <hr />
                        <div className="py-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Berat 1
                          <p className="text-green-500">
                            {detailBarangOpname.berat1 == null ? ' - ' : detailBarangOpname.berat1} gram
                          </p>
                        </div>
                        <hr />
                        <div className="py-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Berat 2
                          <p className="text-green-500">
                            {detailBarangOpname.berat2 == null ? ' - ' : detailBarangOpname.berat2} gram
                          </p>
                        </div>
                        <hr />
                        <div className="py-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Berat 3
                          <p className="text-green-500">
                            {detailBarangOpname.berat3 == null ? ' - ' : detailBarangOpname.berat3} gram
                          </p>
                        </div>
                        <hr />
                        <div className=" py-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Emas
                          <p className="text-green-500">
                            {detailBarangOpname.pembelian?.karat == null ? '-' : detailBarangOpname.pembelian?.karat}{' '}
                            karat
                          </p>
                        </div>
                        <hr />
                        <div className=" py-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Modal (Pembelian) /gram
                          <p className="text-green-500">
                            Rp{' '}
                            {numberWithCommas(
                              detailBarangOpname.pembelian?.harga_beli == undefined
                                ? '0'
                                : detailBarangOpname.pembelian?.harga_beli,
                            )}
                          </p>
                        </div>
                        <hr />
                        <div className=" py-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Asal Barang (Pembelian)
                          <p className="text-green-500">{detailBarangOpname.pembelian?.user?.cabang?.nama_cabang}</p>
                        </div>
                        <hr />
                        <div className=" py-1 text-base font-normal items-center text-slate-600 flex justify-between mb-1 px-5">
                          Rubah Berat
                          <div className="flex gap-2 justify-between">
                            <input
                              type="text"
                              placeholder="berat"
                              name="berat"
                              onChange={handleChangeEdit}
                              required
                              className="input input-bordered w-full max-w-[100px]"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 flex gap-2 justify-end">
                        <button
                          onClick={handleSubmitEditDataOpname}
                          type="submit"
                          className="bg-green-500 px-4 py-2.5 rounded-md text-white hover:bg-green-600 transition duration-500"
                        >
                          {loading ? 'Loading...' : 'Rubah Berat'}
                        </button>
                        <button
                          type="reset"
                          className="bg-slate-500 px-4 py-2.5 rounded-md text-white hover:bg-slate-600 transition duration-500"
                          onClick={closeModal}
                        >
                          Cencel
                        </button>
                      </div>
                    </form>
                    <div className="border rounded-md">
                      <div className="p-1 border-b bg-green-500/10 text-slate-600">
                        <h2>Transfer Barang</h2>
                      </div>
                      <div className="px-3 mt-2">
                        <h2 className="font-normal text-sm text-slate-600">Pilih Etalase</h2>
                        <select onChange={(e) => setCabangId(e.target.value)} className="select select-bordered w-full">
                          <option className="pt-6">Pilih Etalase</option>
                          {cabang.map((cb) => (
                            <option key={cb.id} value={cb.id}>
                              {cb.nama_cabang}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="px-3 mt-2">
                        <h2 className="font-normal text-sm text-slate-600">Harga Modal</h2>
                        <CurrencyInput
                          name="harga_modal"
                          value={harga_modal}
                          onChange={(value) => setHargaModal(() => value)}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="px-3 mt-2">
                        <h2 className="font-normal text-sm text-slate-600">Berat</h2>
                        <input
                          type="number"
                          id="berat"
                          name="berat"
                          value={tranferData.berat}
                          onChange={handleChangeBeratTranfer}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className=" absolute bottom-5 right-8">
                        <button
                          onClick={handleSubmitTranferBarang}
                          type="button"
                          className="bg-orange-500 px-4 py-2.5 rounded-md text-white hover:bg-orange-600 transition duration-500"
                        >
                          {loadingTransfer ? 'Prosess..' : 'Transfer'}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
