import React from 'react';
import { IconPlus } from '@tabler/icons-react';
import Transition from '../utils/Transition';

const CustomLaporanCashflow = ({
  customBerat,
  customNama,
  trigger,
  setDropdownOpen,
  dropdownOpen,
  dropdown,
  submitCustom,
  setCustomBerat,
  setCustomNama,
}) => {
  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        {/* <img className="w-8 h-8 rounded-full" src={UserAvatar} width="32" height="32" alt="User" /> */}
        <div className="flex items-center truncate">
          <button className="bg-orange-500 text-white p-1 rounded">
            <IconPlus size={20} />
          </button>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
            <div>
              <label htmlFor="nama" className="text-sm font-normal lowercase">
                Nama
              </label>
              <input
                value={customNama || ''}
                onChange={(e) => setCustomNama(e.target.value)}
                type="text"
                name=""
                id="nama"
                placeholder="Nama"
                className="p-1.5 border border-slate-500 rounded-md text-sm font-normal"
              />
            </div>
            <div>
              <label htmlFor="berat" className="text-sm font-normal lowercase">
                Berat
              </label>
              <input
                value={customBerat || ''}
                onChange={(e) => setCustomBerat(e.target.value)}
                type="number"
                name=""
                id="berat"
                placeholder="Berat lebih"
                className="p-1.5 border border-slate-500 rounded-md text-sm font-normal"
              />
            </div>
            <div className="flex justify-end">
              <button onClick={submitCustom} className="px-2 text-sm bg-blue-500 text-white rounded-sm py-1 mt-2">
                simpan
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default CustomLaporanCashflow;
