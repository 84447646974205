import React from 'react';
import Navbar from './Navbar';

const LayoutsKasir = ({ children }) => {
  return (
    <div className="font-sans h-screen leading-normal tracking-normal relative">
      <Navbar />
      <div className="container w-full mx-auto pt-20">
        <div className="w-full px-4 md:px-10 md:mt-8 mb-16 text-gray-800 leading-normal">{children}</div>
      </div>
    </div>
  );
};

export default LayoutsKasir;
