import { IconX } from '@tabler/icons-react';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import soundSuccess from '../../../src/assets/sound.mp3';
import { CurrencyInput } from '../../utils/CurrencyInput';
import Stroke from '../Kasir/Stroke';
import TestingPrint from '../Kasir/TestingPrint';
import CetakStroke from '../Kasir/CetakStroke';
import { getAuthorizationHeader, http } from '../../config/http';
import PrintSuratTrxPenjualan from '../Transaksi/PrintSuratTrxPenjualan';

export default function DetailTransaksiTertunda({ itemCabang, kodeItem, loading }) {
  // console.log('itemCabang::: ', itemCabang);
  const [hargaPenjualan, setHarga] = useState(0);
  const [msg, setMsg] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [harga_jual, setHargaJual] = useState('');

  const [dataPrint, setDataPrint] = useState([]);
  // console.log('dataPrint::: ', dataPrint);
  let [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const item_id = itemCabang.id;

  function playSound() {
    new Audio(soundSuccess).play();
  }

  const handleChange = (newValue) => {
    if (newValue === undefined) {
      setHarga(0);
    } else {
      setHarga(newValue);
      setHargaJual(newValue);
    }
  };
  const [gambar, setGambar] = useState('');
  // console.log('gambar::: ', gambar);
  const toko = localStorage.getItem('name');
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const SubmitPenjualan = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const data = { harga_jual, item_id, gambar };
    try {
      const res = await http.post('/transaksi_tertunda', data, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      setIsOpen(true);
      playSound();
      setDataPrint(res.data.data);
      return res.data;
    } catch (error) {
      if (error.response.status === 404) {
        toast.error('Data Tidak ditemukan!!!');
      } else if (error.response.status === 422) {
        toast.error('Harga Tidak Boleh Kosong!!');
      } else if (error.response.status === 409) {
        toast.error('Silahkan Scan Item!!!');
      } else {
        toast.error('Terjadi Kesalahan!!');
      }
    }
    setBtnLoading(false);
  };

  // print struk
  const strukComponent = useRef();

  function closeModal() {
    setIsOpen(false);
  }

  function checkBerat(number) {
    const beratConfirm = parseFloat(number);
    return beratConfirm % 1 === 0
      ? beratConfirm.toString()
      : beratConfirm
        .toFixed(2)
        .replace(/\.?0+$/, '')
        .replace('.', ',');
  }

  const [selectImage, setSelectImage] = useState([]);
  // console.log('selectImage::: ', selectImage);
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    // console.log('selectedFiles::: ', selectedFiles);
    const selectedFilesArray = Array.from(selectedFiles);

    if (selectImage.length < 1) {
      const reader1 = new FileReader();
      reader1.addEventListener('load', function () {
        setGambar(reader1.result.split(',')[1]);
      });
      reader1.readAsDataURL(selectedFiles[0]);
    }
    // const file = event.target.files[0];
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    // setSelectImage((previousImages) => previousImages.concat(imagesArray));
    setSelectImage(imagesArray);
    // FOR BUG IN CHROME
    event.target.value = '';
  };

  function deleteHandler(image) {
    setSelectImage(selectImage.filter((e) => e !== image));
    setGambar('');
    URL.revokeObjectURL(image);
  }

  const tipe_surat = localStorage.getItem('tipe_surat');
  return (
    <>
      <div className="flex-wrap mt-4 md:px-10 lg:px-20">
        <div className="bg-white shadow rounded-md p-3">
          <h1 className='uppercase font-semibold text-lg border-b mx-5'>Penggarus {toko}</h1>
          <form onSubmit={SubmitPenjualan} className="rounded-md mt-2 p-4">
            <div>
              {msg && (
                <div className="text-2xl flex items-center justify-center font-semibold text-gray-700">{msg}</div>
              )}
            </div>
            <div>
              {tipe_surat === '1' ? (
                <div
                  ref={strukComponent}
                  className="invisible print:visible absolute bg-white print:h-screen print:w-full"
                >
                  <PrintSuratTrxPenjualan isTertunda isOpen={isOpen} detailPenjualan={dataPrint}/>
                  {/* <Stroke isOpen={isOpen} dataPrint={dataPrint} checkBerat={checkBerat} /> */}
                </div>
              ) : (
                <div
                  ref={strukComponent}
                  className="invisible print:visible absolute bg-white print:h-screen print:w-full"
                >
                  <TestingPrint isOpen={isOpen} dataPrint={dataPrint} checkBerat={checkBerat} isTertunda />
                </div>
              )}
              <div className="w-full ">
                <div className="font-medium">
                  <div className="flex justify-between items-center p-2 mb-2 border-b">
                    <div>Kode Barang</div>
                    <div>{loading ? 'memuat data...' : <>{itemCabang.kode_item}</>}</div>
                  </div>
                  <div className="flex justify-between items-center p-2 mb-2 border-b">
                    <div>Nama Barang</div>
                    <div>{loading ? 'memuat data...' : <>{itemCabang.jenis_kategori?.nama_jenis}</>}</div>
                  </div>
                  <div className="flex justify-between items-center p-2 mb-2 border-b">
                    <div>Berat</div>
                    <div>{loading ? 'memuat data...' : <>{itemCabang.berat} gram</>}</div>
                  </div>
                  <div className="flex justify-between items-center p-2 mb-2 border-b">
                    <div>Emas</div>
                    <div>{loading ? 'memuat data...' : <>{itemCabang.karat} karat</>}</div>
                  </div>
                  <div className="flex justify-between items-center p-2 mb-2">
                    <div>Total Harga</div>
                    <div>
                      <CurrencyInput
                        value={hargaPenjualan}
                        onChange={handleChange}
                        className="input input-bordered w-full max-w-xs"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 mb-2 border-b">
                    <div>Harga Rata - Rata</div>
                    <div>
                      <p className='text-xl font-bold'>Rp. {numberWithCommas(itemCabang.berat ? Math.round(hargaPenjualan/itemCabang.berat) : '')}</p>
                    </div>
                  </div>
                  <div className="mb-3 hidden">
                    <div>
                      <div className={`mt-2 flex gap-4 p-2  ${selectImage.length === 0 ? 'hidden' : ''}`}>
                        {selectImage.map((image, index) => (
                          <div key={index}>
                            <button
                              onClick={() => deleteHandler(image)}
                              className="absolute bg-white/50 rounded-full p-1"
                            >
                              <IconX width={20} />
                            </button>
                            <img width={90} src={image} alt="" srcSet="" />
                          </div>
                        ))}
                      </div>
                      <div>
                        {selectImage.length > 0 &&
                          (selectImage.length > 1 ? (
                            <div>
                              <p className="text-sm mx-3 text-red-500">
                                Kamu Hanya bisa Upload 1 Gambar <br />
                                <span className="text-sm">
                                  Silahkan Hapus <b> {selectImage.length - 1} </b> dari {selectImage.length} Gambar
                                  diatas
                                </span>
                              </p>
                            </div>
                          ) : (
                            <div className="text-sm text-green-600 mx-3">
                              UPLOAD {selectImage.length} IMAGE
                              {selectImage.length === 1 ? '' : 'S'}
                            </div>
                          ))}
                      </div>
                      <div className="flex justify-between p-2 mb-2 items-center">
                        <div>Masukkan Gambar</div>
                        <input
                          type="file"
                          onChange={handleImageChange}
                          className="
                        file:bg-blue-500 file:rounded-md file:border-none w-[230px]  file:p-2 file:text-white file:cursor-pointer
                          bg-green-500/20 rounded-md  p-2 font-semibold
                        "
                          multiple
                          accept="image/png , image/jpeg, image/webp"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CetakStroke strukComponent={strukComponent} closeModal={closeModal} isOpen={isOpen} />
              <div className="mt-5 flex items-center justify-end">
                <button
                  disabled={btnLoading ? true : false}
                  onClick={SubmitPenjualan}
                  className="bg-orange-500 text-white
                  px-3 py-1.5 hover:bg-orange-600 transition duration-300"
                >
                  {btnLoading ? 'Proses ...' : 'Simpan Penggarus'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
