import { Dialog, Transition } from '@headlessui/react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Fragment, useState } from 'react';

export default function ConfirmationDeletePembelian({
  isDelete,
  deletePembelian,
  pembelianDelete,
  isOpenModalDelete,
  closeModalDelete,
}) {
  return (
    <>
      <Transition appear show={isOpenModalDelete} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalDelete}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="text-red-500   flex justify-center font-medium leading-6 "
                  ></Dialog.Title>
                  <div>
                    <div className="bg-white rounded-lg md:max-w-md ">
                      <div className="flex items-center gap-4">
                        <div className=" flex items-center justify-center w-16 h-16 ">
                          <IconAlertTriangle className="border rounded-full p-2" width={70} height={70} />
                        </div>
                        <div className="mt-4  md:ml-2  md:text-left">
                          <p className="font-bold ">Hapus Pembelian</p>
                          <p className="text-sm text-gray-700 mt-1">
                            Apakah Anda Yakin untuk <span className="text-red-500 font-bold">menghapus ?</span>{' '}
                            Pembelian dengan Kode{' '}
                            <span className="text-red-500 font-bold">{pembelianDelete.kode_trx}</span>
                          </p>
                        </div>
                      </div>
                      <div className="text-right mt-2 gap-2  flex justify-end">
                        <button onClick={deletePembelian} className="bg-red-500 rounded-md text-white px-4 py-2">
                          {isDelete ? 'Prosess ...' : 'Hapus'}
                        </button>
                        <button onClick={closeModalDelete} className="bg-slate-500 rounded-md text-white px-4 py-2">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
