import { Dialog, Transition } from '@headlessui/react';
import { IconPrinter, IconX } from '@tabler/icons-react';
import { Fragment } from 'react';
import Logo from '../../assets/logo.png';

export default function CetakStrokePembelian({ isOpen, closeModal, dataPrintPembelian }) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function closeFake() {}

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeFake}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center  justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md print:max-w-xl modal-stroke transform overflow-hidden rounded bg-white p-6 print:p-0 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900"></Dialog.Title>
                  <div>
                    <div className="flex items-center justify-center">
                      <img src={Logo} alt="" srcSet="" width={80} />
                    </div>
                    <div className="flex items-center justify-center">
                      <h2 className="uppercase underline font-bold text-2xl">Komaladewi</h2>
                    </div>
                    <div className="flex items-center justify-center tracking-[1px] -mt-4 font-semibold text-lg">
                      <p>{dataPrintPembelian.user?.cabang?.telepon}</p>
                    </div>
                    <div className="flex items-center justify-center tracking-[1px] -mt-5">
                      <p>Jl {dataPrintPembelian.user?.cabang?.alamat}</p>
                    </div>
                  </div>
                  <span>====================================</span>
                  <div className="font-stroke-pembelian">
                    Kasir : {dataPrintPembelian.user?.cabang?.nama_cabang}
                    <h2>
                      {dataPrintPembelian.kode_trx} {dataPrintPembelian.created_at}
                    </h2>
                  </div>
                  <span>====================================</span>
                  <div className="mt-2 py-4 text-slate-900">
                    <div className="flex  items-center justify-between font-stroke-pembelian">
                      <h2>Nama Barang</h2>
                      <h2>{dataPrintPembelian.jenis_kategori?.nama_jenis}</h2>
                    </div>
                    <div className="flex my-2 items-center justify-between font-stroke-pembelian">
                      <h2>Berat</h2>
                      <h2>{dataPrintPembelian.berat} gram</h2>
                    </div>
                    <div className="flex my-2 items-center justify-between font-stroke-pembelian">
                      <h2>Emas</h2>
                      <h2>{dataPrintPembelian.karat} karat</h2>
                    </div>
                    <span>====================================</span>
                    <div className="flex my-2 items-center justify-between font-stroke-pembelian">
                      <h2>Jumlah Item</h2>
                      <h2> 1x</h2>
                    </div>

                    <div className="flex my-2 items-center justify-between font-stroke-pembelian">
                      <h2>Harga / gram</h2>
                      <h2>
                        Rp{' '}
                        {numberWithCommas(
                          dataPrintPembelian.harga_beli == undefined ? '' : dataPrintPembelian.harga_beli,
                        )}
                      </h2>
                    </div>
                    <span>====================================</span>
                    <div className="flex my-2 items-center justify-between font-stroke-pembelian">
                      <h2>Total Harga</h2>
                      <h2>
                        Rp{' '}
                        {numberWithCommas(
                          dataPrintPembelian.total_harga == undefined ? '' : dataPrintPembelian.total_harga,
                        )}
                      </h2>
                    </div>
                  </div>
                  <div className="mt-4 flex items-center justify-between print:invisible">
                    <button
                      type="button"
                      className="flex my-2 items-center bg-green-500 px-2 py-1 font-light hover:bg-green-600 transition duration-500 rounded text-white"
                      onClick={() => window.print()}
                    >
                      <IconPrinter />
                      Print
                    </button>
                    <button
                      type="button"
                      className="flex my-2 items-center bg-slate-500 px-2 py-1 font-light hover:bg-slate-600 transition duration-500 rounded text-white"
                      onClick={() => window.location.reload()}
                    >
                      <IconX />
                      Tutup
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
