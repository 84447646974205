import {
  IconArrowAutofitRight,
  IconAlignBoxRightBottom,
  IconChevronLeft,
  IconChevronRight,
  IconPlus,
  IconRefresh,
} from '@tabler/icons-react';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthorizationHeader, http } from '../config/http';
import Layouts from '../layouts/Layouts';
import { DinamicTitle } from '../utils/DinamicTitle';
import DetailSaldoKas from './DetailSaldoKas';
import TambahSaldoKAS from './TambahSaldoKAS';

export default function HistorySaldo() {
  DinamicTitle('Saldo KAS');
  const [history, setHistory] = useState([]);
  const [totalSaldo, setTotalSaldo] = useState(0);
  const [cabang, setCabang] = useState([]);

  const [saldoCabang, setSaldoCabang] = useState([]);
  const [loading, setLoading] = useState(false);

  // filter
  const [search, setSearch] = useState('');
  const [idCabang, setIdCabang] = useState('');
  const [tipe, setTipe] = useState('');

  const [submitTambahSaldo, setSubmitTambahSaldo] = useState(false);

  function submitTambahSaldoKas() {
    setSubmitTambahSaldo(true);
  }

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  // console.log('lastPage::: ', lastPage);

  useEffect(() => {
    async function getHistory() {
      setLoading(true);
      await http
        .get(`/history/saldo?page=${currentPage}&cabang=${idCabang}&tipe=${tipe}`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          // console.log('res::: ', res.data)
          setSaldoCabang(res.data.saldo_cabang);
          setTotalSaldo(res.data.total_saldo);
          setCurrentPage(res.data.current_page);
          setLastPage(res.data.last_page_url);
          // console.log('res.data.last_page_url::: ', res.data.last_page_url);
          setHistory(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
      setLoading(false);
    }
    getHistory();
  }, [idCabang, tipe, currentPage, navigate, submitTambahSaldo === true]);

  useEffect(() => {
    async function getSaldoCabang() {
      await http
        .get('/cabang', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getSaldoCabang();
  }, [navigate]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // TAMBAH SALDO
  let [isOpenTambahSaldo, setIsOpenTambahSaldo] = useState(false);

  function closeModalTambahSaldo() {
    setIsOpenTambahSaldo(false);
  }

  function openModalTambahSaldo() {
    setIsOpenTambahSaldo(true);
  }
  const [detailSaldoKas, setDetailSaldoKas] = useState([]);
  const [loadingSaldo, setLoadingSaldo] = useState(false);
  async function getDetailSaldoKAS(id) {
    setLoadingSaldo(true);
    await http
      .get(`/history/saldo/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setDetailSaldoKas(res.data.data);
        setIsOpen(true);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingSaldo(false);
  }

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <Layouts
      title={'Saldo KAS'}
      compFilter={
        <div className="dropdown dropdown-left md:hidden ">
          <Tooltip title="Saldo">
            <button tabIndex={0} className="bg-green-500 p-3 text-white rounded-md hover:bg-green-600">
              <IconAlignBoxRightBottom />
            </button>
          </Tooltip>
          <div tabIndex={0} className="dropdown-content menu p-4 shadow bg-base-100 rounded-md w-64">
            <h1>Saldo : Rp {numberWithCommas(totalSaldo == undefined ? '0' : totalSaldo)}</h1>
            <div className="border rounded-md p-2">
              {saldoCabang.map((cb) => (
                <div key={cb.id}>
                  <div className="mb-2">
                    <div className="text-xl mb-2 font-semibold text-slate-700">{cb.cabang?.nama_cabang}</div>
                    <div className="text-slate-700">
                      Saldo : Rp {numberWithCommas(cb.saldo == undefined ? '0' : cb.saldo)}
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
              <button
                type="button"
                onClick={openModalTambahSaldo}
                className="inline-flex w-full whitespace-nowrap justify-center btn bg-orange-500 gap-2 text-lg hover:bg-green-700 border-none text-white"
              >
                Setor / Tarik
                <IconArrowAutofitRight />
              </button>
            </div>
          </div>
        </div>
      }
    >
      {loadingSaldo ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
          <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
        </div>
      ) : (
        <></>
      )}

      <div className="flex gap-0.5">
        <div className="p-4 hidden md:block bg-white w-[400px] max-w-[450px] h-screen">
          <div className="flex items-center justify-between">
            <div>
              <div className="font-light text-xl">Total Saldo : </div>
              <h2 className="font-bold text-2xl">Rp {numberWithCommas(totalSaldo == undefined ? '0' : totalSaldo)}</h2>
            </div>
            <div className="flex items-center gap-2">
              <Tooltip title="Refresh">
                <button onClick={() => window.location.reload()} className="bg-slate-400 text-white rounded p-2">
                  <IconRefresh />
                </button>
              </Tooltip>
            </div>
          </div>
          <hr className="mt-4 stroke-2 border border-black/30" />
          <div className="mx-2 mt-5 border-2 rounded p-5">
            {saldoCabang.map((cb) => (
              <div key={cb.id}>
                <div className="mb-2">
                  <div className="text-xl mb-2 font-semibold text-slate-700">{cb.cabang?.nama_cabang}</div>
                  <div className="text-slate-700">
                    Saldo : Rp {numberWithCommas(cb.saldo == undefined ? '0' : cb.saldo)}
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
          <div className="mx-2 mt-2">
            <button
              type="button"
              onClick={openModalTambahSaldo}
              className="inline-flex w-full rounded-none whitespace-nowrap justify-center btn bg-green-600 gap-2 text-lg hover:bg-green-700 border-none text-white"
            >
              Setor / Tarik
              <IconArrowAutofitRight />
            </button>
          </div>
        </div>
        <div className="w-full">
          <div className="flex items-center gap-0.5 scrollbar-none overflow-x-auto">
            <div className="">
              <input
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search...."
                className="input input-bordered rounded-none input-md shadow-md w-[200px] max-w-md"
              />
            </div>
            <div className="flex items-center justify-between gap-0.5">
              <div className="">
                <div className="relative  group">
                  <select onChange={(e) => setIdCabang(e.target.value)} className="select select-bordered rounded-none shadow-md">
                    <option className="" value="">
                      Semua Etalase
                    </option>
                    {cabang.map((cb) => (
                      <option key={cb.id} value={cb.id}>
                        {cb.nama_cabang}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="">
                <div className="relative  group">
                  <select onChange={(e) => setTipe(e.target.value)} className="select select-bordered rounded-none shadow-md">
                    <option className="pt-6">Filter Tipe</option>
                    <option className="pt-6" value="Penjualan">
                      Penjualan
                    </option>
                    <option className="pt-6" value="Pembelian">
                      Pembelian
                    </option>
                    <option className="pt-6" value="Refund_Penjualan">
                      Refund Penjualan
                    </option>
                    <option className="pt-6" value="Refund_Pembelian">
                      Refund Pembelian
                    </option>
                    <option className="pt-6" value="Setor_Tunai">
                      Setor Tunai
                    </option>
                    <option className="pt-6" value="Tarik_Tunai">
                      Tarik Tunai
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <DetailSaldoKas detailSaldoKas={detailSaldoKas} isOpen={isOpen} closeModal={closeModal} />
          <TambahSaldoKAS
            submitTambahSaldoKas={submitTambahSaldoKas}
            cabang={cabang}
            isOpenTambahSaldo={isOpenTambahSaldo}
            closeModalTambahSaldo={closeModalTambahSaldo}
          />
          {loading ? (
            <div className="flex  items-center justify-center">
              <div className=" font-semibold rounded-br-md rounded-bl-md flex items-center gap-3">
                <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                    }}
                  />
                  <g className="spinner_Wezc">
                    <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                    <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                    <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                    <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                    <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                    <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                    <circle cx={12} cy="21.5" r="1.5" />
                  </g>
                </svg>
                Memuat data....
              </div>
            </div>
          ) : (
            <div className="relative overflow-x-auto  overflow-hidden">
              {history.length === 0 ? (
                <span>Data Kosong....</span>
              ) : (

                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs sticky top-0 border-b text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-1 border-r whitespace-nowrap">
                        <p>No</p>
                      </th>
                      <th scope="col" className="px-6 py-1 border-r whitespace-nowrap">
                        <p>KODE FAKTUR</p>
                      </th>
                      <th scope="col" className="px-6 py-1 border-r whitespace-nowrap">
                        <p>Nominal</p>
                      </th>
                      <th scope="col" className="px-6 py-1 border-r whitespace-nowrap">
                        <p>Saldo Akhir</p>
                      </th>
                      <th scope="col" className="px-6 py-1 border-r whitespace-nowrap">
                        <p>Tipe</p>
                      </th>
                      <th scope="col" className="px-6 py-1 border-r whitespace-nowrap">
                        <p>Etalase</p>
                      </th>
                      <th scope="col" className="px-6 py-1 border-r whitespace-nowrap">
                        <p>Tanggal</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="flex-1 text-gray-700 sm:flex-none ">

                    {history
                      .filter((data) => data.kode_trx?.toLowerCase().includes(search.toLowerCase()))
                      .map((hs, i) => (
                        <tr
                          key={i}
                          onClick={() => getDetailSaldoKAS(hs.id)}
                          className=" bg-white border-b dark:bg-gray-800 cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                          <td className="p-1 whitespace-nowrap md:p-2">{i + 1}.</td>
                          <td className="p-1 whitespace-nowrap md:p-2">{hs.kode_trx}</td>
                          <td className="p-1 whitespace-nowrap md:p-2">
                            Rp {numberWithCommas(hs.saldo_awal == undefined ? '0' : hs.saldo_awal)}
                          </td>
                          <td className="p-1 whitespace-nowrap md:p-2">
                            Rp {numberWithCommas(hs.saldo_akhir == undefined ? '0' : hs.saldo_akhir)}
                          </td>
                          <td className="p-1 whitespace-nowrap md:p-2">{hs.tipe}</td>
                          <td className="p-1 whitespace-nowrap md:p-2">{hs.cabang}</td>
                          <td className="p-1 whitespace-nowrap md:p-2">{hs.tanggal}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="bg-white bottom-0 flex justify-between left-0 py-4 w-full absolute">
        <div className="flex justify-end w-full mx-5">
          <div className="items-center flex justify-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 sm:flex">
            <span className="block">
              Page {currentPage} of {lastPage}{' '}
            </span>
            <div className="space-x-1">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                title="previous"
                type="button"
                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
              >
                <IconChevronLeft />
              </button>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                title="next"
                type="button"
                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
              >
                <IconChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
}
