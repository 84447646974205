import React, { useState } from 'react';
import UserMenu from './UserMenu';
import { Tooltip } from 'antd';
import { IconTrashX } from '@tabler/icons-react';
import ModalHistoriBarangTerhapus from './ModalHistoriBarangTerhapus';

function Header({ sidebarOpen, setSidebarOpen, title, openModal, compFilter, dateFilter }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  function closeModal() {
    setIsOpenModal(false);
  }
  return (
    <header className="sticky top-0 bg-white border-b border-slate-200 z-10">
      <ModalHistoriBarangTerhapus isOpen={isOpenModal} closeModal={closeModal} />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <h1 className="hidden whitespace-nowrap lg:block -ml-3 text-xl font-bold mt-3">{title}</h1>
          <div className="flex justify-between items-center w-full">
            <div>
              <p className="font-semibold mt-5 ml-4 text-sm text-slate-900 md:block hidden">
                {dateFilter === 'Invalid date' ? '' : <span className="text-sm "> {dateFilter}</span>}
              </p>
              {/* <div className=''>
              <h2 className='hidden whitespace-nowrap lg:block ml-3 text-lg font-bold mt-2'>{dateFilter}</h2>
            </div> */}

              {/* Hamburger button */}
              <button
                className="text-slate-500 hover:text-slate-600 lg:hidden"
                aria-controls="sidebar"
                aria-expanded={sidebarOpen}
                onClick={(e) => {
                  e.stopPropagation();
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <rect x="4" y="5" width="16" height="2" />
                  <rect x="4" y="11" width="16" height="2" />
                  <rect x="4" y="17" width="16" height="2" />
                </svg>
              </button>
            </div>
            <div>{compFilter}</div>
          </div>

          {/* Header: Right side */}
          <div className="md:flex items-center hidden">
            <hr className="w-px h-6 bg-slate-200 mx-3" />
            {/* <Tooltip title="Histori Barang">
              <button onClick={() => setIsOpenModal(true)} className="bg-slate-300 rounded-md p-1.5">
                <IconTrashX />
              </button>
            </Tooltip> */}
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
