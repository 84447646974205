import React from 'react';

function DetailScanTrxPenjualan({ dataScanKode, numberWithCommas, msg }) {
  
  return (
    <>
      {/* <h1 className="mt-3">{msg} </h1> */}
      <div className="border rounded mt-2 ">
        <div className="p-1 border-b flex items-center justify-between bg-blue-500/10 text-slate-600">
          <h2>Detail Transaksi Penjualan</h2>
          <h2 className="font-light">
            Tanggal : {msg == 'Maaf, item bukan dari toko ini' ? '-' : dataScanKode.created_at}
          </h2>
        </div>
        <div className="py-2 text-base font-normal text-slate-600 flex justify-between px-5">
          Jenis Barang
          <p className="text-green-500">
            {msg == 'Maaf, item bukan dari toko ini' ? '-' : dataScanKode?.item?.jenis_kategori?.nama_jenis}
          </p>
        </div>
        <div className="py-2 text-base font-normal text-slate-600 flex justify-between px-5">
          Berat Item
          <p className="text-green-500">
            {msg == 'Maaf, item bukan dari toko ini' ? '-' : dataScanKode?.item?.berat} gram
          </p>
        </div>
        <hr className="mx-3" />
        <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
          Emas
          <p className="text-green-500">
            {msg == 'Maaf, item bukan dari toko ini' ? '-' : dataScanKode?.item?.karat} karat
          </p>
        </div>
        <hr className="mx-3" />
        <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
          Total Harga
          <p className="text-green-500">
            Rp{' '}
            {numberWithCommas(
              dataScanKode.total_harga == undefined
                ? '0'
                : msg == 'Maaf, item bukan dari toko ini'
                  ? '-'
                  : dataScanKode.total_harga,
            )}
          </p>
        </div>
        <hr className="mx-3" />
        <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
          Diterbitkan di Cabang
          <p className="text-green-500">
            {msg == 'Maaf, item bukan dari toko ini' ? '-' : dataScanKode.user?.cabang?.nama_cabang}
          </p>
        </div>
        <hr className="mx-3" />
        <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
          Refund
          <p className="text-green-500">{dataScanKode.is_refund === 0 ? <>Belum di Refund</> : <>Sudah di Refund</>}</p>
        </div>
      </div>
    </>
  );
}

export default DetailScanTrxPenjualan;
