import { Dialog, Transition } from '@headlessui/react';
import { IconEdit, IconX } from '@tabler/icons-react';
import { Tooltip } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuthorizationHeader, http, imageBaseUrl } from '../../config/http';
import { CurrencyInput } from '../../utils/CurrencyInput';
import ImageComponent from '../../utils/ImageComponent';
import EditTrxPembelian from './EditTrxPembelian';
import { PhotoView } from 'react-photo-view';

export default function DetailTransaksiPembelian({
  kodeTransaksi,
  closeModal,
  isSubmitBarangOpname,
  isOpen,
  addToOpname,
  detailPembelian,
  handleOnChangeTeknisi,
}) {
  // console.log('detailPembelian::: ', detailPembelian);
  const navigate = useNavigate();
  const [getTeknisiName, setGetTeknisiName] = useState([]);

  const [cabang_id, setCabangId] = useState('');
  const [harga_modal, setHargaModal] = useState(0);
  const [loadingTransfer, setLoadingTransferBarang] = useState(false);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function fakeClose() {}

  async function handleSubmitTranferItem(e) {
    e.preventDefault();
    const data = { cabang_id, harga_modal };
    setLoadingTransferBarang(true);
    try {
      const response = await http.post(`/pembelian/transfer_item/${kodeTransaksi}`, data, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      toast.success(response.data.message);
      closeModal();
      setLoadingTransferBarang(false);
      return response.data;
    } catch (error) {
      // console.log('error::: ', error);
      if (error.response.status === 401) {
        navigate('/login');
      } else if (error.response.status === 409) {
        toast.error(error.response.data.message);
      } else if (error.response.status === 422) {
        toast.error('Silahkan Masukkan Form Transfer Barang');
      }
    }
    setLoadingTransferBarang(false);
  }

  useEffect(() => {
    async function getTeknisi() {
      await http
        .get(`teknisi`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setGetTeknisiName(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getTeknisi();
  }, [navigate]);

  const [cabang, setCabang] = useState([]);

  useEffect(() => {
    async function getCabangSelect() {
      await http
        .get(`cabang`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getCabangSelect();
  }, [navigate]);

  const [load, setLoad] = useState(false);
  const [openImage, setOpenImage] = useState(false);

  const harga_beli_gram = detailPembelian.total_harga / detailPembelian.berat;

  // let [isOpenEditTrx, setIsOpenEditTrx] = useState(false)

  // function closeModalEditTrx() {
  //   setIsOpenEditTrx(false)
  // }

  // function openModalEditTrx() {
  //   setIsOpenEditTrx(true)
  // }

  return (
    <>
      {/* <EditTrxPembelian detailPembelian={detailPembelian} isOpenEditTrx={isOpenEditTrx} closeModalEditTrx={closeModalEditTrx}/> */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={fakeClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          {openImage && isOpen && (
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20  flex flex-col items-center justify-center">
              <div
                onClick={() => setOpenImage(false)}
                className={'absolute top-52 right-7 lg:top-16 lg:right-[280px] md:right-[120px] md:top-28 '}
              >
                <div className="bg-white p-2 rounded-full cursor-pointer hover:bg-white/50">
                  <IconX />
                </div>
              </div>
              <div>
                {!load && (
                  <div className="max-w-[48px] max-h-[48px]  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
                )}
                <img
                  onLoad={() => setLoad(true)}
                  width={''}
                  className={'lg:w-[700px] md:w-[500px] w-[350px] scale-50'}
                  height={''}
                  src={detailPembelian.gambar === null ? '' : `${imageBaseUrl}${detailPembelian.gambar[0].asli}`}
                />
              </div>
            </div>
          )}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded- bg-white px-6 pt-5 pb-3 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-xl font-medium leading-6 text-gray-900">
                    <h2>Pembelian</h2>
                    <h2 className="text-base">
                      KODE FAKTUR : <span className="font-light">{detailPembelian.kode_trx}</span>
                    </h2>
                  </Dialog.Title>
                  <div className="grid gap-2">
                    <div>
                      <div className="border">
                        <div className="p-3 flex justify-between items-center border-b bg-orange-500/10 text-slate-600">
                          <h2>Detail Barang</h2>
                          {/* <Tooltip title="edit item">
                            <button onClick={openModalEditTrx} className='bg-blue-500 [&>svg]:stroke-1 text-white rounded-sm hover:bg-blue-600 cursor-pointer'><IconEdit /></button>
                          </Tooltip> */}
                        </div>
                        <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Nama Barang
                          <div className="text-gray-900">
                            <div className="rounded-sm text-green-500 pb-2">
                              {detailPembelian.jenis_kategori?.nama_jenis}
                            </div>
                          </div>
                        </div>

                        <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Berat
                          <div className="text-gray-900">
                            <div className="rounded-sm text-green-500 pb-2">{detailPembelian.berat} Gram</div>
                          </div>
                        </div>

                        <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Emas
                          <div className="text-gray-900">
                            <div className="rounded-sm text-green-500 pb-2">{detailPembelian.karat} Karat</div>
                          </div>
                        </div>

                        <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Modal Beli / gram
                          <div className="text-gray-900">
                            <>
                              <div className="rounded-sm text-green-500 pb-2">
                                Rp{' '}
                                {numberWithCommas(
                                  detailPembelian?.harga_beli == undefined ? '0' : Math.round(harga_beli_gram),
                                )}
                              </div>
                            </>
                          </div>
                        </div>

                        <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Total Harga
                          <div className="text-gray-900">
                            <>
                              <div className="rounded-sm text-green-500 pb-2">
                                Rp{' '}
                                {numberWithCommas(
                                  detailPembelian.total_harga == undefined ? '0' : detailPembelian.total_harga,
                                )}
                              </div>
                            </>
                          </div>
                        </div>

                        <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Surat
                          <div className="text-gray-900">
                            {detailPembelian.surat === 0 ? (
                              <>
                                <div className="rounded-sm text-green-500 pb-2">Tidak Bersurat</div>
                              </>
                            ) : (
                              <>
                                <div className="rounded-sm text-green-500 pb-2">Bersurat</div>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Kondisi
                          <div className="text-gray-900">
                            <>
                              <div className="rounded-sm text-green-500 pb-2">{detailPembelian.kondisi_barang}</div>
                            </>
                          </div>
                        </div>

                        <div className="mt-1 text-base font-normal text-slate-600  px-5">
                          Keterangan
                          <p className="text-green-600 bg-green-500/20 rounded p-2 text-sm">
                            {detailPembelian.keterangan}
                          </p>
                        </div>
                        <div className="mt-1 text-base mb-2 font-normal text-slate-600  px-5">
                          Gambar
                          <div className="mt-2 overflow-hidden">
                            {/* {isOpen && (
                              <PhotoView src={
                                detailPembelian.gambar === null
                                  ? ''
                                  : `${imageBaseUrl}${detailPembelian.gambar[0].asli}`
                              }>
                                <img src={
                                  detailPembelian.gambar === null
                                    ? ''
                                    : `${imageBaseUrl}${detailPembelian.gambar[0].asli}`
                                } alt="" srcSet="" width={'80px'}
                                height={'80px'} />
                              </PhotoView>
                            )} */}
                            {isOpen && (
                              <ImageComponent
                                onClick={() => setOpenImage(true)}
                                className={'max-w-[80px]  hover:scale-110 cursor-pointer'}
                                width={'80px'}
                                height={'80px'}
                                style={{ objectFit: 'cover' }}
                                src={
                                  detailPembelian.gambar === null
                                    ? ''
                                    : `${imageBaseUrl}${detailPembelian.gambar[0].asli}`
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="mt-2 hidden px-5 mb-2">
                          <select
                            onChange={handleOnChangeTeknisi}
                            name="teknisi_id"
                            className="select select-bordered w-full"
                          >
                            <option>Pilih Teknisi</option>
                            {getTeknisiName.map((tk) => (
                              <option key={tk.id} value={tk.id}>
                                {tk.nama_teknisi}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="border rounded-md hidden">
                      <div className={`p-1 border-b  bg-green-500/10 text-slate-600  `}>
                        <h2>Transfer Barang</h2>
                      </div>

                      <div className={` `}>
                        <div className="px-3 mt-8">
                          <select
                            onChange={(e) => setCabangId(e.target.value)}
                            className="select select-bordered w-full"
                          >
                            <option className="pt-6">Pilih Etalase</option>
                            {cabang.map((cb) => (
                              <option key={cb.id} value={cb.id}>
                                {cb.nama_cabang}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="px-3 mt-8">
                          <CurrencyInput
                            name="harga_modal"
                            value={harga_modal}
                            onChange={(value) => setHargaModal(() => value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex gap-2 justify-end">
                    {/* <button
                      type="button"
                      className="text-white mr-44  bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                      onClick={addToOpname}
                    >
                      {isSubmitBarangOpname ? 'Proses..' : 'Pindah'}
                    </button> */}
                    {/* <button
                      type="button"
                      onClick={handleSubmitTranferItem}
                      className="text-white hidden bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                    >
                      {loadingTransfer ? 'Proses..' : 'Transfer'}
                    </button> */}
                    {/* <button
                      type="button"
                      className="text-white   bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                      onClick={addToOpname}
                    >
                      {isSubmitBarangOpname ? 'Proses..' : 'Pindah'}
                    </button> */}
                    <button
                      className="focus:outline-none py-2 px-5 shadow-sm text-center text-gray-100 bg-slate-600 hover:bg-slate-700 font-medium border"
                      onClick={closeModal}
                    >
                      Tutup
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
