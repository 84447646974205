import React from 'react';
import QRCode from 'react-qr-code';
import { imageBaseUrl } from '../../config/http';

function PrintSuratTrxPenjualan({ detailPenjualan, isOpen, isTertunda }) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <div className="p-2">
      <div className="flex justify-end mr-[90px]">
        {isTertunda ? (
          <div className="mt-2 text-sm">{isOpen ? `${detailPenjualan.tanggal_surat.split(' ')[0]}` : ``}</div>
        ) : (
          <div className="mt-2 text-sm">{isOpen ? `${detailPenjualan.created_at.split(' ')[0]}` : ``}</div>
        )}
      </div>
      <div className="flex justify-end mr-[36px] mt-[58px] mb-[52px]">
        <div className="text-[15px]">{detailPenjualan.item?.kode_item}</div>
      </div>
      <div className="grid grid-cols-5 px-2 gap-2 ml-4 ">
        <h2 className="font-semibold text-sm ml-8 mt-1">1</h2>
        <h2 className="font-semibold -ml-10 mt-1 text-sm whitespace-nowrap">
          {detailPenjualan.item?.jenis_kategori.nama_jenis}
        </h2>
        <h2 className="font-semibold ml-[88px] whitespace-nowrap text-sm">{detailPenjualan.item?.berat}</h2>
        <h2 className="font-semibold ml-12 text-sm">{detailPenjualan.item?.karat}</h2>
        <h2 className="font-semibold text-sm -ml-4">
          Rp {numberWithCommas(detailPenjualan.total_harga == undefined ? '0' : detailPenjualan.total_harga)}
        </h2>
      </div>
      <div className="mt-[60px] flex ml-28">
        <div>
          {isOpen ? (
            <img
              width={180}
              height={150}
              style={{ maxHeight: '275px', maxWidth: '400px' }}
              src={
                detailPenjualan.item?.gambar1 === null ? '' : `${imageBaseUrl}${detailPenjualan.item?.gambar1[0].asli}`
              }
            />
          ) : (
            <div className="ml-[20px] border w-[120px] h-[120px] mb-2"></div>
          )}
        </div>
        <div className="ml-[190px] -mt-2">
          <h2 className="font-semibold text-sm">
            Rp {numberWithCommas(detailPenjualan.total_harga == undefined ? '0' : detailPenjualan.total_harga)}
          </h2>
        </div>
      </div>
      <div className="absolute bottom-4 right-[10px]">
        <div className=" p-1 bg-white">
          <QRCode value={detailPenjualan.kode_trx === undefined ? 'unkwow' : detailPenjualan.kode_trx} size={60} />
        </div>
        <div className="text-sm tracking-tighter mr-4 -mt-1">{detailPenjualan.kode_trx}</div>
      </div>
    </div>
  );
}

export default PrintSuratTrxPenjualan;
