import {
  IconActivity,
  IconCoin,
  IconCurrencyDollar,
  IconGardenCart,
  IconGitBranch,
  IconLogout,
  IconUsers,
  IconMoneybag,
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuthorizationHeader, http } from '../config/http';
import Layouts from '../layouts/Layouts';
import BarangCabang from '../components/Kasir/BarangCabang';
import { DinamicTitle } from '../utils/DinamicTitle';
import GrafikChart from '../components/Admin/Grafik/BarChart';
import LayoutsKasir from '../layouts/LayoutsKasir';
import SpinnerGetData from '../components/common/SpinnerGetData';

const Dashboard = () => {
  DinamicTitle('Dashboard || Komaladewi');
  const check = localStorage.getItem('role');
  const navigate = useNavigate();
  const [length, setLength] = useState('');
  const [penjualanHariIni, setPenjualanHariIni] = useState(0);
  const [saldoPenjualanHariIni, setsaldoPenjualanHariIni] = useState(0);
  const [pembelianHariIni, setPembelianHariIni] = useState('');
  const [total_saldo, setTotalSaldo] = useState('');

  const [saldoPembelian, setSaldoPembelian] = useState('');

  const [barangCabang, setBarangCabang] = useState([]);
  const [loadingDataCabang, setLoadingDataCabang] = useState(false);
  const [totalBarangCabang, setTotalBarangCabang] = useState(0);
  // console.log('totalBarangCabang::: ', totalBarangCabang);

  const role = localStorage.getItem('role');

  // useEffect(() => {
  //   async function getBarangCabang() {
  //     setLoadingDataCabang(true);
  //     await http
  //       .get(`item`, {
  //         headers: { Authorization: getAuthorizationHeader() },
  //       })
  //       .then((res) => {
  //         setBarangCabang(res.data.data.data);
  //       })
  //       .catch((err) => {
  //         if (err.response === 'undefined') {
  //           return null;
  //         } else if (err.response.status === 401) {
  //           navigate('/login');
  //         }
  //       });
  //     setLoadingDataCabang(false);
  //   }
  //   getBarangCabang();
  // }, [navigate]);

  const [totalBeratSemuaBarang, setTotalBeratSemuaBarang] = useState(0);
  const [totalBeratPenjualan, setTotalBeratPenjualan] = useState(0);
  const [totalBeratPembelian, setTotalBeratPembelian] = useState(0);
  const [loadingGetDataHome, setLoadingGetDataHome] = useState(false);

  const [jumlahBarangKeluarHariIni, setJumlahBarangKeluarHariIni] = useState(0);
  const [jumlahBarangMasukHariIni, setJumlahBarangMasukKariIni] = useState(0);
  const [jumlahBeratBarangMasukHariIni, setJumalahBeratBarangMasukHariIni] = useState(0);
  const [jumlahBeratBarangKeluarHariIni, setJumlahBeratBarangKeluarHariIni] = useState(0);

  useEffect(() => {
    async function getTransaksiHariIni() {
      setLoadingGetDataHome(true);
      await http
        .get(`home`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          // console.log('res::: ', res.data);
          setTotalBarangCabang(res.data.total_barang);
          setJumlahBarangKeluarHariIni(res.data.jumlah_barang_keluar_hari_ini);
          setJumlahBarangMasukKariIni(res.data.jumlah_barang_masuk_hari_ini);
          setJumlahBeratBarangKeluarHariIni(res.data.total_berat_barang_keluar_hari_ini);
          setJumalahBeratBarangMasukHariIni(res.data.total_berat_barang_masuk_hari_ini);
          setSaldoPembelian(res.data.total_saldo_pembelian_hari_ini);
          setsaldoPenjualanHariIni(res.data.total_saldo_penjualan_hari_ini);
          setTotalSaldo(res.data.total_saldo);
          setPenjualanHariIni(res.data.total_penjualan_hari_ini);
          setPembelianHariIni(res.data.total_pembelian_hari_ini);
          setTotalBeratSemuaBarang(res.data.total_berat_barang);
          setTotalBeratPenjualan(res.data.total_berat_penjualan_hari_ini);
          setTotalBeratPembelian(res.data.total_berat_pembelian_hari_ini);
        })
        .catch((err) => {
          if (err.code === 'ERR_NETWORK') {
            toast.warning('Periksa Jaringan Internet Anda!');
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
      setLoadingGetDataHome(false);
    }
    getTransaksiHariIni();
  }, [navigate]);

  const toko = localStorage.getItem('toko');

  const [saldoCabang, setSaldoCabang] = useState(0);
  // useEffect(() => {
  //   async function getSaldoCabang() {
  //     await http
  //       .get('/history/saldo', {
  //         headers: { Authorization: getAuthorizationHeader() },
  //       })
  //       .then((res) => {
  //         // console.log('res::: ', res);
  //         setSaldoCabang(res.data.saldo_cabang.map((sd) => sd.saldo));
  //       })
  //       .catch((err) => {
  //         if (err.response === 'undefined') {
  //           return null;
  //         } else if (err.response.status === 401) {
  //           navigate('/login');
  //         }
  //       });
  //   }
  //   getSaldoCabang();
  // }, [navigate]);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const check_login = localStorage.getItem('role');
  function roundNumber(number) {
    return number?.toFixed(3);
  }

  if (check_login === 'kasir') {
    return (
      <LayoutsKasir>
        <div className={`flex flex-wrap`}>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-blue-600">
                    <IconGitBranch className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-end">
                  <h5 className="font-bold uppercase text-gray-500">Jumlah Barang</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>
                        {totalBarangCabang} BIJI | {totalBeratSemuaBarang} GR
                      </span>
                    )}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-indigo-600">
                    <IconGardenCart className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">Jumlah Penjualan Hari Ini</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>
                        {penjualanHariIni} BIJI | {roundNumber(totalBeratPenjualan)} GR
                      </span>
                    )}{' '}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-red-600">
                    <IconGardenCart className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">Jumlah Pembelian Hari Ini</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>
                        {pembelianHariIni} BIJI | {totalBeratPembelian} GR{' '}
                      </span>
                    )}
                    <span className="text-red-500">
                      <i className="fas fa-caret-up" />
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className={`${role == 'kasir' ? '' : 'hidden'} w-full md:w-1/2 xl:w-1/3 md:p-2 p-2`}>
            <div className="bg-white border  shadow p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-orange-600">
                    <IconMoneybag className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500"> Total Saldo</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? <SpinnerGetData /> : <span>Rp {numberWithCommas(saldoCabang)}</span>}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-indigo-900">
                    <IconCurrencyDollar className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">Total Pembelian Hari ini</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? <SpinnerGetData /> : <span>Rp {numberWithCommas(saldoPembelian)}</span>}

                    <span className="text-red-500">
                      <i className="fas fa-caret-up" />
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-green-600">
                    <IconCurrencyDollar className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">Total Penjualan hari ini</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>Rp {numberWithCommas(saldoPenjualanHariIni)}</span>
                    )}
                    <span className="text-red-500">
                      <i className="fas fa-caret-up" />
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Taruh Sini */}
      </LayoutsKasir>
    );
  } else {
    return (
      <Layouts title={`Dashboard ${ check === 'admin' ? 'Admin' : toko ? toko : ''}`}>
        <div className={`flex flex-wrap`}>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-blue-600">
                    <IconGitBranch className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-end">
                  <h5 className="font-bold uppercase text-gray-500">Jumlah Barang</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>
                        {totalBarangCabang} BIJI | {totalBeratSemuaBarang} GR
                      </span>
                    )}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-indigo-600">
                    <IconGardenCart className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-end">
                  <h5 className="font-bold uppercase whitespace-nowrap text-gray-500">Jumlah Penjualan Hari Ini</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>
                        {penjualanHariIni} BIJI | {roundNumber(totalBeratPenjualan)} GR
                      </span>
                    )}{' '}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-red-600">
                    <IconGardenCart className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-end">
                  <h5 className="font-bold uppercase whitespace-nowrap text-gray-500">Jumlah Pembelian Hari Ini</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>
                        {pembelianHariIni} BIJI | {totalBeratPembelian} GR{' '}
                      </span>
                    )}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-orange-600">
                    <IconCurrencyDollar className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">Total Saldo</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? <SpinnerGetData /> : <span>Rp {numberWithCommas(total_saldo)}</span>}
                    <span className="text-red-500">
                      <i className="fas fa-caret-up" />
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-green-600">
                    <IconCurrencyDollar className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">Total Penjualan Hari ini</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>Rp {numberWithCommas(saldoPenjualanHariIni)}</span>
                    )}
                    <span className="text-red-500">
                      <i className="fas fa-caret-up" />
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-slate-600">
                    <IconCurrencyDollar className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">Total Pembelian hari ini</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? <SpinnerGetData /> : <span>Rp {numberWithCommas(saldoPembelian)}</span>}
                    <span className="text-red-500">
                      <i className="fas fa-caret-up" />
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className={`${role == 'kasir' ? '' : 'hidden'} w-full md:w-1/2 xl:w-1/3 md:p-2 p-2`}>
            <div className="bg-white border  shadow p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-orange-600">
                    <IconMoneybag className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">Saldo</h5>
                  <h3 className="font-bold text-base">Rp {numberWithCommas(saldoCabang)}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 w-full">
          <div className="w-full  md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-yellow-600">
                    <IconActivity className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">BARANG KE ETALASE HARI INI</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>
                        {' '}
                        {jumlahBarangKeluarHariIni} BIJI | {jumlahBeratBarangKeluarHariIni} GR{' '}
                      </span>
                    )}
                    <span className="text-red-500">
                      <i className="fas fa-caret-up" />
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full  md:p-2 p-2">
            <div className="bg-white border  shadow p-[17px]">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className=" p-3 bg-red-600">
                    <IconLogout className="text-white" />
                  </div>
                </div>
                <div className="flex-1 text-right ">
                  <h5 className="font-bold uppercase text-gray-500">BARANG KE BRANKAS HARI INI</h5>
                  <h3 className="font-bold text-base">
                    {loadingGetDataHome ? (
                      <SpinnerGetData />
                    ) : (
                      <span>
                        {' '}
                        {jumlahBarangMasukHariIni} BIJI | {jumlahBeratBarangMasukHariIni} GR{' '}
                      </span>
                    )}
                    {/* {loadingGetDataHome ? <SpinnerGetData/> :<span>{jumlahBarangMasukHariIni} EMAS</span>} */}
                    <span className="text-red-500">
                      <i className="fas fa-caret-up" />
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            {check === 'kasir' ? (
              <BarangCabang barangCabang={barangCabang} loadingDataCabang={loadingDataCabang} />
            ) : (
              <GrafikChart />
            )}
          </div>
        </div>
      </Layouts>
    );
  }
};

export default Dashboard;


// <div className="grid sm:grid-cols-2 w-full">
//           <div className="w-full  md:p-2 p-2">
//             <div className="bg-white border  shadow p-[17px]">
//               <div className="flex flex-row items-center">
//                 <div className="flex-shrink pr-4">
//                   <div className=" p-3 bg-yellow-600">
//                     <IconActivity className="text-white" />
//                   </div>
//                 </div>
//                 <div className="flex-1 text-right ">
//                   <h5 className="font-bold uppercase text-gray-500">BARANG KE ETALASE HARI INI</h5>
//                   <h3 className="font-bold text-base">
//                     {loadingGetDataHome ? (
//                       <SpinnerGetData />
//                     ) : (
//                       <span>
//                         {' '}
//                         {jumlahBarangKeluarHariIni} BIJI | {jumlahBeratBarangKeluarHariIni} GR{' '}
//                       </span>
//                     )}
//                     <span className="text-red-500">
//                       <i className="fas fa-caret-up" />
//                     </span>
//                   </h3>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="w-full  md:p-2 p-2">
//             <div className="bg-white border  shadow p-[17px]">
//               <div className="flex flex-row items-center">
//                 <div className="flex-shrink pr-4">
//                   <div className=" p-3 bg-red-600">
//                     <IconLogout className="text-white" />
//                   </div>
//                 </div>
//                 <div className="flex-1 text-right  ">
//                   <h5 className="font-bold uppercase text-gray-500">BARANG KE BRANKAS HARI INI</h5>
//                   <h3 className="font-bold text-base">
//                     {loadingGetDataHome ? (
//                       <SpinnerGetData />
//                     ) : (
//                       <span>
//                         {' '}
//                         {jumlahBarangMasukHariIni} BIJI | {jumlahBeratBarangMasukHariIni} GR{' '}
//                       </span>
//                     )}
//                     {/* {loadingGetDataHome ? <SpinnerGetData/> :<span>{jumlahBarangMasukHariIni} EMAS</span>} */}
//                     <span className="text-red-500">
//                       <i className="fas fa-caret-up" />
//                     </span>
//                   </h3>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>