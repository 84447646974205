import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BarangTerkini from '../components/Admin/Summary/BarangTerkini';
import KondisiItemPembelian from '../components/Admin/Summary/KondisiItemPembelian';
import SmPembelian from '../components/Admin/Summary/SmPembelian';
import SmPenjualan from '../components/Admin/Summary/SmPenjualan';
import { getAuthorizationHeader, http } from '../config/http';
import Layouts from '../layouts/Layouts';
import { DinamicTitle } from '../utils/DinamicTitle';
import SmTransaksiTertunda from '../components/Admin/Summary/SmTransaksiTertunda';

function Summary() {
  DinamicTitle('Ringkasan Transaksi || Komaladewi');
  const navigate = useNavigate();
  const [summaryPenjualan, setSummaryPenjualan] = useState([]);
  // console.log('summaryPenjualan::: ', summaryPenjualan);
  const [loadingDataPenjualan, setLoadingDataPenjualan] = useState(false);
  const [cabangFilterSm, setCabangFilterSm] = useState([]);
  const [idCabangFilterPenjualan, setIdCabangFilterPenjualan] = useState('');
  const [karatFilterPenjualan, setKaratFilterPenjualan] = useState('');
  const [karatFilterPembelian, setKaratFilterPembelian] = useState('');

  const [dateFilterPenjualan, setDateFilterPenjualan] = useState('');

  const [summaryPembelian, setSummaryPembelian] = useState([]);
  const [loadingDataPembelian, setLoadingDataPembelian] = useState(false);
  const [idCabangFilterPembelian, setIdCabangFilterPembelian] = useState('');
  const [dateFilterPembelian, setDateFilterPembelian] = useState('');

  const [kondisiItemPembelian, setKondisiPembelianItem] = useState([]);
  const [loadingKondisiItem, setLoadingKondisiItem] = useState(false);

  const [totalHargaPembelian, setTotalHargaPembelian] = useState('');
  const [totalBeratPembelian, setTotalBeratPembelian] = useState('');
  const [totalBijiPembelian, setTotalBijiPembelian] = useState('');

  const [totalHargaPenjualan, setTotalHargaPenjualan] = useState('');
  const [totalBeratPenjualan, setTotalBeratPenjualan] = useState('');
  const [totalBijiPenjualan, setTotalBijiPenjualan] = useState('');

  // console.log('kondisiItemPembelian::: ', kondisiItemPembelian);

  useEffect(() => {
    getSummaryPenjualan();
  }, [idCabangFilterPenjualan, dateFilterPenjualan, karatFilterPenjualan, navigate]);

  function reloadPenjualan() {
    const nextFilterDate = '';
    // const nextFilterCabang = '';
    setDateFilterPenjualan(nextFilterDate);
    getSummaryPenjualan();
    // setIdCabangFilterPenjualan(nextFilterCabang);
  }

  // GET PENJUALAN SUMMARY
  async function getSummaryPenjualan() {
    setLoadingDataPenjualan(true);
    // console.log(`::: ${idCabangFilterPenjualan === '' ? '' : `?cabang=${idCabangFilterPenjualan}`}`);
    // console.log(`::: ${dateFilterPenjualan === '' ? `` : `${idCabangFilterPenjualan === '' ? `?` : `&`}tanggal=${dateFilterPenjualan}`}`);
    // console.log(`::: ${dateFilterPenjualan === '' ? `` : `${karatFilterPenjualan === '' ? `?` : `&`}karat=${karatFilterPenjualan}`}`);
    await http
      .get(
        `/summary/penjualan${idCabangFilterPenjualan === '' ? '' : `?cabang=${idCabangFilterPenjualan}`}${
          dateFilterPenjualan === ''
            ? ``
            : `${idCabangFilterPenjualan === '' ? `?` : `&`}tanggal=${dateFilterPenjualan}`
        }${
          karatFilterPenjualan === ''
            ? ``
            : `${idCabangFilterPenjualan === '' ? `?` : `&`}karat=${karatFilterPenjualan}`
        }`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        },
      )
      .then((res) => {
        // console.log('res pen::: ', res.data);
        setTotalBeratPenjualan(res.data.total_berat_perhari);
        setTotalBijiPenjualan(res.data.total_item_perhari);
        setTotalHargaPenjualan(res.data.total_harga_perhari);
        setSummaryPenjualan(res.data.data);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingDataPenjualan(false);
  }
  useEffect(() => {
    async function getCabangFilterSm() {
      await http
        .get(`/cabang`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setCabangFilterSm(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getCabangFilterSm();
  }, [navigate]);
  // END GET PENJUALAN SUMMARY
  // GET PEMBELIAN SUMMARY

  // console.log('totalBeratPembelian::: ', totalBeratPembelian);
  async function getSummaryPembelian() {
    setLoadingDataPembelian(true);
    setLoadingKondisiItem(true);
    const filterPembelian = `${idCabangFilterPembelian === '' ? '' : `?cabang=${idCabangFilterPembelian}`}${
      dateFilterPembelian === '' ? `` : `${idCabangFilterPembelian === '' ? `?` : `&`}tanggal=${dateFilterPembelian}`
    }${
      karatFilterPembelian === '' ? `` : `${idCabangFilterPembelian === '' ? `?` : `&`}karat=${karatFilterPembelian}`
    }`;
    await http
      .get(`/summary/pembelian${filterPembelian}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        // console.log("pembelian ",res.data.data)
        setTotalBijiPembelian(res.data.data.total_item_perhari);
        setTotalHargaPembelian(res.data.data.total_harga_perhari);
        setTotalBeratPembelian(res.data.data.total_berat_perhari);
        setKondisiPembelianItem(res.data.data.summary_kondisi_item_pembelian);
        setSummaryPembelian(res.data.data.summary_item_pembelian);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingDataPembelian(false);
    setLoadingKondisiItem(false);
  }

  useEffect(() => {
    getSummaryPembelian();
  }, [idCabangFilterPembelian, dateFilterPembelian, karatFilterPembelian, navigate]);

  function reloadPembelian() {
    const nextDate = '';
    // const nextCabang = ''
    getSummaryPembelian();
    setDateFilterPembelian(nextDate);
    // setIdCabangFilterPembelian(nextCabang);
  }

  // END GET PEMBELIAN SUMMARY

  // GET BARANG TERKINI

  const [barangTerkini, setBarangTerkini] = useState([]);
  const [loadingBarangTerkini, setLoadingBarangTerkini] = useState(false);

  const [totalHargaBarangTerkini, setTotalHargaBarangTerkini] = useState('');
  const [totalBeratBarangTerkini, setTotalBeratBarangTerkini] = useState('');
  const [totalBijiBarangTerkini, setTotalBijiBarangTerkini] = useState('');
  const [karatFilterBT, setKaratFilterBT] = useState('');
  const [idCabangFilterBT, setFilterCabangBT] = useState('');
  const [dateFilterBT, setDateFilterBT] = useState('');

  function reloadBarangTerkini() {
    const nextDate = '';
    // const nextCabang = ''
    getBarangTerkini();
    setDateFilterBT(nextDate);
    // setFilterCabangBT(nextCabang);
  }

  async function getBarangTerkini() {
    setLoadingBarangTerkini(true);
    await http
      .get(
        `summary/item_terkini${idCabangFilterBT === '' ? '' : `?cabang=${idCabangFilterBT}`}${
          karatFilterBT === '' ? `` : `${idCabangFilterBT === '' ? `?` : `&`}karat=${karatFilterBT}`
        }`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        },
      )
      .then((res) => {
        // console.log('res::: ', res);
        setBarangTerkini(res.data.data);
        setTotalBeratBarangTerkini(res.data.total_berat);
        setTotalBijiBarangTerkini(res.data.total_item);
        setTotalHargaBarangTerkini(res.data.total_modal);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingBarangTerkini(false);
  }

  useEffect(() => {
    getBarangTerkini();
  }, [navigate, idCabangFilterBT, dateFilterBT, karatFilterBT]);

  const [karatPenjualan, setKaratPenjualan] = useState([]);
  const [loadingKaratPenjualan, setLoadingKaratPenjualan] = useState(false);
  const [idCabangFilterKaratPenjualan, setIdCabangFilterKaratPenjualan] = useState('');
  const [dateFilterKaratPenjualan, setDateFilterKaratPenjualan] = useState('');
  const [totalBeratPerHariKaratPenjualan, setTotalBeratPerHariKaratPenjualan] = useState(0);
  const [totalItemPerhariKaratPenjualan, setTotalItemPerhariKaratPenjualan] = useState(0);
  const [totalHargaPerhariKaratPenjualan, setTotalHargaPerhariKaratPenjualan] = useState(0);
  function reloadKaratPenjualan() {
    const nextFilterDate = '';
    setDateFilterKaratPenjualan(nextFilterDate);
    getKaratPenjualan();
  }
  async function getKaratPenjualan() {
    setLoadingKaratPenjualan(true);
    const filterKaratPenjualan = `${idCabangFilterKaratPenjualan === '' ? '' : `?cabang=${idCabangFilterKaratPenjualan}`}${
      dateFilterKaratPenjualan === ''
        ? ``
        : `${idCabangFilterKaratPenjualan === '' ? `?` : `&`}tanggal=${dateFilterKaratPenjualan}`
    }`;
    await http
      .get(`/summary/karat_penjualan${filterKaratPenjualan}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        // console.log('res::: ', res.data);
        setTotalBeratPerHariKaratPenjualan(res.data.total_berat_perhari);
        setTotalItemPerhariKaratPenjualan(res.data.total_item_perhari);
        setTotalHargaPerhariKaratPenjualan(res.data.total_harga_perhari);
        setKaratPenjualan(res.data.data);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingKaratPenjualan(false);
  }
  useEffect(() => {
    getKaratPenjualan();
  }, [idCabangFilterKaratPenjualan, dateFilterKaratPenjualan]);

  const [karatPembelian, setKaratPembelian] = useState([]);
  const [loadingKaratPembelian, setLoadingKaratPembelian] = useState(false);
  const [idCabangFilterKaratPembelian, setIdCabangFilterKaratPembelian] = useState('');
  const [dateFilterKaratPembelian, setDateFilterKaratPembelian] = useState('');
  const [totalBeratPerHariKaratPembelian, setTotalBeratPerHariKaratPembelian] = useState(0);
  const [totalItemPerhariKaratPembelian, setTotalItemPerhariKaratPembelian] = useState(0);
  const [totalHargaPerhariKaratPembelian, setTotalHargaPerhariKaratPembelian] = useState(0);
  function reloadKaratPembelian() {
    const nextFilterDate = '';
    setDateFilterKaratPembelian(nextFilterDate);
    getKaratPembelian();
  }
  async function getKaratPembelian() {
    setLoadingKaratPembelian(true);
    const filterKaratPembelian = `${idCabangFilterKaratPembelian === '' ? '' : `?cabang=${idCabangFilterKaratPembelian}`}${
      dateFilterKaratPembelian === ''
        ? ``
        : `${idCabangFilterKaratPembelian === '' ? `?` : `&`}tanggal=${dateFilterKaratPembelian}`
    }`;
    await http
      .get(`/summary/karat_pembelian${filterKaratPembelian}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        // console.log('res::: ', res.data);
        setKaratPembelian(res.data.data);
        setTotalBeratPerHariKaratPembelian(res.data.total_berat_perhari);
        setTotalItemPerhariKaratPembelian(res.data.total_item_perhari);
        setTotalHargaPerhariKaratPembelian(res.data.total_harga_perhari);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingKaratPembelian(false);
  }
  useEffect(() => {
    getKaratPembelian();
  }, [idCabangFilterKaratPembelian, dateFilterKaratPembelian]);

  const [loadingDataTransaksiTertunda, setLoadingDataTransaksiTertunda] = useState(false);
  const [idCabangFilterTransaksiTertunda, setIdCabangFilterTransaksiTertunda] = useState('');
  const [dateFilterTransaksiTertunda, setDateFilterTransaksiTertunda] = useState('');
  const [karatFilterTransaksiTertunda, setKaratTransaksiTertunda] = useState('');

  const [totalBeratTransaksiTertunda, setTotalBeratTransaksiTertunda] = useState('');
  const [totalBijiTransaksiTertunda, setTotalBijiTransaksiTertunda] = useState('');
  const [totalHargaTransaksiTertunda, setTotalHargaTransaksiTertunda] = useState('');
  const [summaryTransaksiTertunda, setSummaryTransaksiTertunda] = useState([]);

  async function getSummaryTransaksiTertunda() {
    setLoadingDataTransaksiTertunda(true);
    // console.log(`::: ${idCabangFilterPenjualan === '' ? '' : `?cabang=${idCabangFilterPenjualan}`}`);
    // console.log(`::: ${dateFilterPenjualan === '' ? `` : `${idCabangFilterPenjualan === '' ? `?` : `&`}tanggal=${dateFilterPenjualan}`}`);
    // console.log(`::: ${dateFilterPenjualan === '' ? `` : `${karatFilterPenjualan === '' ? `?` : `&`}karat=${karatFilterPenjualan}`}`);
    await http
      .get(
        `/transaksi_tertunda/summary${idCabangFilterTransaksiTertunda === '' ? '' : `?cabang=${idCabangFilterTransaksiTertunda}`}${
          dateFilterTransaksiTertunda === ''
            ? ``
            : `${idCabangFilterTransaksiTertunda === '' ? `?` : `&`}tanggal=${dateFilterTransaksiTertunda}`
        }${
          karatFilterTransaksiTertunda === ''
            ? ``
            : `${idCabangFilterTransaksiTertunda === '' ? `?` : `&`}karat=${karatFilterTransaksiTertunda}`
        }`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        },
      )
      .then((res) => {
        console.log('res pen::: ', res.data);
        setTotalBeratTransaksiTertunda(res.data.total_berat_perhari);
        setTotalBijiTransaksiTertunda(res.data.total_item_perhari);
        setTotalHargaTransaksiTertunda(res.data.total_harga_perhari);
        setSummaryTransaksiTertunda(res.data.data);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingDataTransaksiTertunda(false);
  }

  useEffect(() => {
    getSummaryTransaksiTertunda();
  }, [idCabangFilterTransaksiTertunda, dateFilterTransaksiTertunda, karatFilterTransaksiTertunda]);

  function reloadTransaksiTertunda() {
    const nextFilterDate = '';
    // const nextFilterCabang = '';
    setDateFilterTransaksiTertunda(nextFilterDate);
    getSummaryTransaksiTertunda();
    // setIdCabangFilterPenjualan(nextFilterCabang);
  }
  return (
    <Layouts title={'LAPORAN TRANSAKSI'}>
      <div className="grid lg:grid-cols-2 gap-2 m-2">
        <div className="w-full bg-white shadow-md rounded">
          <SmPenjualan
            setIdCabangFilterPenjualan={setIdCabangFilterPenjualan}
            setDateFilterPenjualan={setDateFilterPenjualan}
            cabangFilterSm={cabangFilterSm}
            loadingDataPenjualan={loadingDataPenjualan}
            totalBeratPenjualan={totalBeratPenjualan}
            totalHargaPenjualan={totalHargaPenjualan}
            totalBijiPenjualan={totalBijiPenjualan}
            summaryPenjualan={summaryPenjualan}
            reloadPenjualan={reloadPenjualan}
            setKaratFilterPenjualan={setKaratFilterPenjualan}
          />
        </div>

        <div className="w-full bg-white shadow-md  rounded">
          <SmPembelian
            cabangFilterSm={cabangFilterSm}
            totalHargaPembelian={totalHargaPembelian}
            totalBeratPembelian={totalBeratPembelian}
            totalBijiPembelian={totalBijiPembelian}
            summaryPembelian={summaryPembelian}
            loadingDataPembelian={loadingDataPembelian}
            setIdCabangFilterPembelian={setIdCabangFilterPembelian}
            setDateFilterPembelian={setDateFilterPembelian}
            reloadPembelian={reloadPembelian}
            setKaratFilterPembelian={setKaratFilterPembelian}
          />
        </div>
        {/* <div className="w-full bg-white shadow-md rounded-md">
          <SmKaratPenjualan 
            karatPenjualan={karatPenjualan} 
            loadingKaratPenjualan={loadingKaratPenjualan} 
            totalBeratPerHariKaratPenjualan={totalBeratPerHariKaratPenjualan}
            totalItemPerhariKaratPenjualan={totalItemPerhariKaratPenjualan}
            totalHargaPerhariKaratPenjualan={totalHargaPerhariKaratPenjualan}
            cabangFilterSm={cabangFilterSm} 
            setIdCabangFilterKaratPenjualan={setIdCabangFilterKaratPenjualan}
            setDateFilterKaratPenjualan={setDateFilterKaratPenjualan}
            reloadKaratPenjualan={reloadKaratPenjualan}
          />
        </div>
        <div className="w-full bg-white shadow-md rounded-md">
          <SmKaratPembelian
            karatPembelian={karatPembelian}
            loadingKaratPembelian={loadingKaratPembelian}
            totalBeratPerHariKaratPembelian={totalBeratPerHariKaratPembelian}
            totalItemPerhariKaratPembelian={totalItemPerhariKaratPembelian}
            totalHargaPerhariKaratPembelian={totalHargaPerhariKaratPembelian}
            cabangFilterSm={cabangFilterSm}
            setIdCabangFilterKaratPembelian={setIdCabangFilterKaratPembelian}
            setDateFilterKaratPembelian={setDateFilterKaratPembelian}
            reloadKaratPembelian={reloadKaratPembelian}
          />
        </div> */}
        <div className="w-full bg-white shadow-md   rounded-md">
          <BarangTerkini
            setDateFilterBT={setDateFilterBT}
            setFilterCabangBT={setFilterCabangBT}
            cabangFilterSm={cabangFilterSm}
            reloadBarangTerkini={reloadBarangTerkini}
            totalBijiBarangTerkini={totalBijiBarangTerkini}
            totalHargaBarangTerkini={totalHargaBarangTerkini}
            totalBeratBarangTerkini={totalBeratBarangTerkini}
            loadingBarangTerkini={loadingBarangTerkini}
            barangTerkini={barangTerkini}
            setKaratFilterBT={setKaratFilterBT}
          />
        </div>
        {/* <div className="w-full bg-white rounded-md shadow-md   max-h-[200px]">
          <KondisiItemPembelian
            kondisiItemPembelian={kondisiItemPembelian}
            loadingKondisiItem={loadingKondisiItem}
            cabangFilterSm={cabangFilterSm}
          />
        </div> */}
        <div className="w-full bg-white shadow-md rounded">
          <SmTransaksiTertunda
            setIdCabangFilterPenjualan={setIdCabangFilterTransaksiTertunda}
            setDateFilterPenjualan={setDateFilterTransaksiTertunda}
            cabangFilterSm={cabangFilterSm}
            loadingDataPenjualan={loadingDataTransaksiTertunda}
            totalBeratPenjualan={totalBeratTransaksiTertunda}
            totalHargaPenjualan={totalHargaTransaksiTertunda}
            totalBijiPenjualan={totalBijiTransaksiTertunda}
            summaryPenjualan={summaryTransaksiTertunda}
            reloadPenjualan={reloadTransaksiTertunda}
            setKaratFilterPenjualan={setKaratTransaksiTertunda}
          />
        </div>
      </div>
    </Layouts>
  );
}

export default Summary;
