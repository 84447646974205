import React from 'react';
import { NavLink } from 'react-router-dom';

const SuccessAction = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div>
        <h2 className="text-2xl font-semibold">Login Berhasil!</h2>
        <NavLink to="/" className="underline">
          Go To Dashboard
        </NavLink>
      </div>
    </div>
  );
};

export default SuccessAction;
