import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthorizationHeader, http } from '../config/http';
import Layouts from '../layouts/Layouts';

export default function ListPembelian() {
  const [pembelian, setPembelian] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getAllPembelian() {
      await http
        .get(`pembelian`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setPembelian(res.data.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getAllPembelian();
  }, [navigate]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <Layouts>
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs sticky top-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Kategori
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Kondisi Barang
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Berat
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Karat
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Harga Beli
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Surat
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {pembelian.map((item, i) => (
                      <tr
                        key={item.id}
                        className="bg-white border-b dark:bg-gray-800 cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="px-6 py-4">{i + 1}</td>
                        <td className="px-6 py-4">{item.kategori.nama_kategori}</td>
                        <td className="px-6 py-4">{item.kondisi_barang}</td>
                        <td className="px-6 py-4">{item.berat}</td>
                        <td className="px-6 py-4">{item.karat}</td>
                        <td className="px-6 py-4">Rp {numberWithCommas(item.harga_beli)}</td>
                        <td className="px-6 py-4">
                          {item.surat === 0 ? (
                            <p className=" bg-pink-500 rounded-sm text-white w-20 flex justify-center">Tidak</p>
                          ) : (
                            <p className="bg-green-700 w-20 rounded-sm text-white flex justify-center">Bersurat</p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
}
