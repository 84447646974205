import { IconCheck } from '@tabler/icons-react';
import { IconCopy, IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import ModalItemPindah from './ModalItemPindah';

export default function DefaultItem({
  items,
  getItemEdit,
  handleCheckboxChange,
  showDetailItem,
  startIndex,
  selectedItems,
  getIdDelete,
  cabang,
}) {
  // console.log(selectedItems)
  const [copyItemId, setCopyItemId] = useState(null);

  const handleCopy = (id, nama) => {
    navigator.clipboard.writeText(nama);
    setCopyItemId(id);
    setTimeout(() => {
      setCopyItemId(null);
    }, 3000);
  };

  const [isOpenModalPindah, setOpenModalPindah] = useState(false);
  function closeModalPindah() {
    setOpenModalPindah(false);
  }
  


 

  return (
    <>
      <ModalItemPindah cabang={cabang} isOpenModalPindah={isOpenModalPindah} closeModalPindah={closeModalPindah} />
      {items.map((item, index) => (
        <tr
          key={item.id}
          onClick={handleCheckboxChange}
          className="bg-white border-b dark:bg-gray-800 cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          
          <td className="px-4 border-r py-1 whitespace-nowrap font-normal">{startIndex + index + 1}.</td>
          <td className="px-4 border-r py-2 whitespace-nowrap font-normal flex gap-0.5">
            <span>
              {copyItemId === item.id ? (
                <IconCheck className="cursor-pointer w-5 h-5 stroke-1" />
              ) : (
                <IconCopy
                  className="cursor-pointer w-5 h-5 stroke-1"
                  onClick={() => handleCopy(item.id, item.kode_item)}
                />
              )}
            </span>
            {item.is_ready === true ? <>{item.kode_item}</> : <span className="line-through">{item.kode_item}</span>}
          </td>
          <td className="px-6 border-r py-1 whitespace-nowrap font-normal">{item.jenis_kategori?.nama_jenis}</td>

          <td className="px-6 border-r py-1 whitespace-nowrap font-normal">
            {item.jenis_kategori.kategori.kode}
            {item.nomor_rak}
          </td>
          <td className="px-6 border-r py-1 whitespace-nowrap font-normal">{item.berat} gr | {item.karat} krt</td>
          <td className="px-6 border-r py-1 whitespace-nowrap font-normal">{item.cabang?.nama_cabang}</td>
          <td className="pl-5 border-r py-1 flex gap-2">
            <button
              onClick={() => showDetailItem(item.id)}
              className="py-1.5 px-0.5 [&>svg]:w-5 h-7 flex items-center justify-center [&>svg]:h-5 [&>svg]:stroke-1 bg-green-500 text-white hover:bg-green-600 transition duration-300 text-xs"
            >
              <IconEye size={20} />
            </button>
            <button
              onClick={() => getItemEdit(item.id)}
              className="py-1.5 px-0.5 [&>svg]:w-5 h-7 flex items-center justify-center [&>svg]:h-5 [&>svg]:stroke-1 bg-orange-500 text-white hover:bg-orange-600 transition duration-300 text-xs"
            >
              <IconEdit size={20} />
            </button>

            <button
              onClick={() => getIdDelete(item.id)}
              className="py-1.5 px-0.5 [&>svg]:w-5 h-7 flex items-center justify-center [&>svg]:h-5 [&>svg]:stroke-1 bg-red-500 text-white hover:bg-red-600 transition duration-300 text-xs"
            >
              <IconTrash size={20} />
            </button>
            {/* <button
                onClick={() => setOpenModalPindah(true)}
                className="py-1.5 px-0.5 [&>svg]:w-5 h-7 flex items-center justify-center [&>svg]:h-5 [&>svg]:stroke-1 bg-blue-500 text-white hover:bg-blue-600 transition duration-300 text-xs"
              >
                <IconTransferOut size={20} />
              </button> */}
          </td>
          <td className="text-center whitespace-nowrap font-normal">
            <input
              checked={selectedItems.includes(item)}
              onChange={(event) => handleCheckboxChange(event, item)}
              
              type="checkbox"
              // checked={isCheckedItems[index]}
              // onChange={handleCheck}
              // data-index={index}
              className="cursor-pointer checkbox checkbox-primary w-4 h-4 border-slate-700 rounded"
            />
          </td>
        </tr>
      ))}
    </>
  );
}
