import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getAuthorizationHeader, http } from '../../config/http';
import LayoutsKasir from '../../layouts/LayoutsKasir';
import { DinamicTitle } from '../../utils/DinamicTitle';
import RfPembelian from './DetailPengembalian/RfPembelian';
import RfPenjualan from './DetailPengembalian/RfPenjualan';

const PengembalianBarang = () => {
  DinamicTitle('Pengembalian || Komaladewi');
  const [scanKode, setScanKode] = useState('');

  const [detailBarangPembelian, setDetailBarangPembelian] = useState([]);
  const [detailBarangPenjualan, setDetailBarangPenjualan] = useState([]);

  let [modalPembelian, setOpenModalPembelian] = useState(false);
  let [modalPenjualan, setOpenModalPenjualan] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingRf, setLodaingRf] = useState(false);
  const [checkKode, setCheckKode] = useState('');

  // MODAL PENJUALAN
  function closeModalPenjualan() {
    setOpenModalPenjualan(false);
    window.location.reload();
  }

  function openModalPenjualan() {
    setOpenModalPenjualan(true);
  }
  // MODAL PEMBELIAN

  function closeModalPembelian() {
    setOpenModalPembelian(false);
    window.location.reload();
  }

  function openModalPembelian() {
    setOpenModalPembelian(true);
  }

  async function refundBarang() {
    setLodaingRf(true);
    await http
      .put(`penjualan/refund/${scanKode}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        toast.success(res.data.message);
        setScanKode('');
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data.status === false) {
          toast.error(err.response.data.message);
        } else if (err.response.status === 403) {
          toast.error('Silahkan Reload / Login Kembali');
        }
      });
    setLodaingRf(false);
  }

  async function handleScanBarangRefund() {
    setLoading(true);
    await http
      .get(`penjualan/scan/${scanKode}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        if (res.data?.data?.kode_trx.substring(0, 4) === 'FKDB') {
          setDetailBarangPembelian(res.data.data);
          openModalPembelian();
        } else if (res.data?.data?.kode_trx.substring(0, 4) === 'FKDJ') {
          setDetailBarangPenjualan(res.data.data);
          openModalPenjualan();
        }
        setCheckKode(res.data.data.kode_trx);
      })
      .catch((err) => {
        if (err.response.data.status === false) {
          toast.error(err.response.data.message);
        } else if (err.response.status === 403) {
          toast.error('Silahkan Reload / Login Kembali');
        }
      });
    setLoading(false);
  }

  const [test1, setTest1] = useState('');

  useEffect(() => {
    if (scanKode === '') {
      setTest1('komala dewi');
    } else {
      handleScanBarangRefund();
    }
  }, [scanKode]);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key?.toLocaleLowerCase() === 'i' && e.ctrlKey) {
        document.getElementById('MySearch').focus();
      }
    });
  }, []);

  return (
    <LayoutsKasir>
      <div className="flex md:px-20 h-full flex-row relative">
        <div className="flex items-center justify-between">
          <div className="absolute md:left-24 left-2 top-3 px-2 py-2 rounded-full bg-green-500 text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <div className="absolute md:right-20 right-0  px-2 mx-4  bg-gray-400/20 rounded-lg ">
            <p className="text-sm md:text-base md:font-normal text-slate-500 mt-2">CTRL + I</p>
          </div>
        </div>
        <input
          type="text"
          id="MySearch"
          onChange={(e) => setScanKode(e.target.value)}
          className="bg-white rounded-lg shadow text-lg full w-full h-16 py-4 pl-16 transition-shadow focus:shadow-2xl focus:outline-dotted"
          placeholder="Masukkan KODE FAKTUR ...."
          x-model="keyword"
        />
      </div>
      <div>
        {loading ? (
          <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
            <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
          </div>
        ) : (
          <>
            {checkKode.substring(0, 4) == 'FKDB' ? (
              <RfPembelian
                detailBarangPembelian={detailBarangPembelian}
                refundBarang={refundBarang}
                loadingRf={loadingRf}
                modalPembelian={modalPembelian}
                closeModalPembelian={closeModalPembelian}
              />
            ) : checkKode.substring(0, 4) == 'FKDJ' ? (
              <RfPenjualan
                modalPenjualan={modalPenjualan}
                closeModalPenjualan={closeModalPenjualan}
                loadingRf={loadingRf}
                refundBarang={refundBarang}
                detailBarangPenjualan={detailBarangPenjualan}
              />
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </LayoutsKasir>
  );
};

export default PengembalianBarang;
