import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard';
import Pembelian from './pages/Kasir/Pembelian';
import Penjualan from './pages/Kasir/Penjualan';
import ListItemAdmin from './pages/ListItemAdmin';
import ListUsers from './pages/ListUsers';
import Login from './pages/Login';
import SuccessLogin from './pages/SuccessLogin';
import ProtectedRoute from './utils/ProtectedRoute';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ListPembelian from './pages/ListPembelian';
import BarangOpname from './pages/BarangOpname/BarangOpname';
import TrxPenjualan from './pages/Transaksi/TrxPenjualan';
import TrxPembelian from './pages/Transaksi/TrxPembelian';
import SaldoKAS from './pages/SaldoKAS';
import Summary from './pages/Summary';
import PengembalianBarang from './pages/Kasir/PengembalianBarang';
import HistorySaldoKasir from './pages/Kasir/HistorySaldoKasir';
import TambahBarang from './pages/operator/TambahBarang';
import BarangRusak from './pages/BarangRusak';
import LaporanKaratItem from './pages/LaporanKaratItem';
import Pengeluaran from './pages/Pengeluaran';
import TransaksiLuar from './pages/TransaksiLuar';
import LaporanCashflow from './pages/LaporanCashflow';

import TransaksiTertunda from './pages/transaksiTertunda/transaksiTertunda';
import TransferBarang from './pages/TransferBarang/transferBarang';
import ListTransaksiTertunda from './pages/transaksiTertunda/ListTransaksiTertunda';
import HistoriTransferMasuk from './pages/HistoryTransfer/HistoriTransferMasuk';
import HistoriTransferKeluar from './pages/HistoryTransfer/HistoriTransferKeluar';
import Riwayat from './pages/riwayat';
import BarangTerhapus from './pages/BarangTerhapus';
import PageNotFound from './pages/PageNotFound';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/success"
            element={
              <ProtectedRoute>
                <SuccessLogin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pengeluaran"
            element={
              <ProtectedRoute>
                <Pengeluaran />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
          <Route
            path="/barang-terhapus"
            element={
              <ProtectedRoute>
                <BarangTerhapus />
              </ProtectedRoute>
            }
          />
          <Route
            path="/laporan-cashflow"
            element={
              <ProtectedRoute>
                <LaporanCashflow />
              </ProtectedRoute>
            }
          />
          <Route
            path="/riwayat"
            element={
              <ProtectedRoute>
                <Riwayat />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/trx-luar"
            element={
              <ProtectedRoute>
                <TransaksiLuar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tambah-barang"
            element={
              <ProtectedRoute>
                <TambahBarang />
              </ProtectedRoute>
            }
          />
          <Route
            path="/transfer-barang"
            element={
              <ProtectedRoute>
                <TransferBarang />
              </ProtectedRoute>
            }
          />
          <Route
            path="/list-transaksi-tertunda"
            element={
              <ProtectedRoute>
                <ListTransaksiTertunda />
              </ProtectedRoute>
            }
          />
          <Route
            path="/laporan-karat-item"
            element={
              <ProtectedRoute>
                <LaporanKaratItem />
              </ProtectedRoute>
            }
          />
          <Route
            path="/transaksi-tertunda"
            element={
              <ProtectedRoute>
                <TransaksiTertunda />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/history-saldo-kasir"
            element={
              <ProtectedRoute>
                <HistorySaldoKasir />
              </ProtectedRoute>
            }
          />
          <Route
            path="/list-items-admin"
            element={
              <ProtectedRoute>
                <ListItemAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/histori-transfer-masuk"
            element={
              <ProtectedRoute>
                <HistoriTransferMasuk />
              </ProtectedRoute>
            }
          />
          <Route
            path="/histori-transfer-keluar"
            element={
              <ProtectedRoute>
                <HistoriTransferKeluar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/list-users"
            element={
              <ProtectedRoute>
                <ListUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/penjualan"
            element={
              <ProtectedRoute>
                <Penjualan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pengembelian"
            element={
              <ProtectedRoute>
                <PengembalianBarang />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pembelian"
            element={
              <ProtectedRoute>
                <Pembelian />
              </ProtectedRoute>
            }
          />
          <Route
            path="/data-pembelian"
            element={
              <ProtectedRoute>
                <ListPembelian />
              </ProtectedRoute>
            }
          />
          <Route
            path="/barang-opname"
            element={
              <ProtectedRoute>
                <BarangOpname />
              </ProtectedRoute>
            }
          />
          <Route
            path="/transaksi-penjualan"
            element={
              <ProtectedRoute>
                <TrxPenjualan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/transaksi-pembelian"
            element={
              <ProtectedRoute>
                <TrxPembelian />
              </ProtectedRoute>
            }
          />
          <Route
            path="/saldo-kas"
            element={
              <ProtectedRoute>
                <SaldoKAS />
              </ProtectedRoute>
            }
          />
          <Route
            path="/summary"
            element={
              <ProtectedRoute>
                <Summary />
              </ProtectedRoute>
            }
          />
          <Route
            path="/barang-rusak"
            element={
              <ProtectedRoute>
                <BarangRusak />
              </ProtectedRoute>
            }
          />
          {/* <Route element={<ProtectedRoute />}></Route> */}
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
