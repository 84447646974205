import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import ConfirmationRefundPembelian from '../../../components/Corfirmation/ConfirmationRefundPembelian';

export default function RfPembelian({
  modalPembelian,
  closeModalPembelian,
  detailBarangPembelian,
  refundBarang,
  loadingRf,
}) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  let [openConfirmationRefundPembelian, setOpenConfirmationRefundPembelian] = useState(false);

  function closeModalConfirmationRefundPembelian() {
    setOpenConfirmationRefundPembelian(false);
  }

  function openModalConfirmationRefundPembelian() {
    setOpenConfirmationRefundPembelian(true);
  }

  return (
    <>
      <Transition appear show={modalPembelian} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalPembelian}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <ConfirmationRefundPembelian
            loadingRf={loadingRf}
            detailBarangPembelian={detailBarangPembelian}
            refundBarang={refundBarang}
            openConfirmationRefundPembelian={openConfirmationRefundPembelian}
            closeModalConfirmationRefundPembelian={closeModalConfirmationRefundPembelian}
          />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    <span className="text-2xl">Detail Transaksi Pembelian</span>
                    <div className="text-base">
                      KODE FAKTUR Pembelian :{' '}
                      <span className="text-base font-light text-slate-700">{detailBarangPembelian.kode_trx}</span>
                    </div>
                  </Dialog.Title>
                  <div className="border rounded ">
                    <div className="p-1 border-b flex items-center justify-between bg-blue-500/10 text-slate-600">
                      <h2>Detail Transaksi Pembelian</h2>
                      <h2 className="font-light"></h2>
                    </div>
                    <div className="py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Jenis Barang
                      <p className="text-green-500">{detailBarangPembelian.jenis_kategori?.nama_jenis}</p>
                    </div>
                    <hr className="mx-3" />
                    <div className="py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Berat Item
                      <p className="text-green-500">{detailBarangPembelian.berat} gram</p>
                    </div>
                    <hr className="mx-3" />
                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Emas
                      <p className="text-green-500">{detailBarangPembelian.karat} karat</p>
                    </div>

                    <hr className="mx-3" />
                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Harga Beli / gram
                      <p className="text-green-500">
                        Rp{' '}
                        {numberWithCommas(
                          detailBarangPembelian.harga_beli == undefined ? 0 : detailBarangPembelian.harga_beli,
                        )}
                      </p>
                    </div>
                    <hr className="mx-3" />
                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Total Harga
                      <p className="text-green-500">
                        Rp{' '}
                        {numberWithCommas(
                          detailBarangPembelian.total_harga == undefined ? 0 : detailBarangPembelian.total_harga,
                        )}
                      </p>
                    </div>
                    <hr className="mx-3" />
                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Kondisi
                      <p className="text-green-500">{detailBarangPembelian.kondisi_barang}</p>
                    </div>
                    <hr className="mx-3" />
                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Diterbitkan di Cabang
                      <p className="text-green-500">{detailBarangPembelian.user?.cabang?.nama_cabang}</p>
                    </div>
                    <hr className="mx-3" />

                    <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                      Tanggal Transaksi
                      <p className="text-green-500">{detailBarangPembelian.created_at}</p>
                    </div>
                  </div>
                  <div className="py-2  flex  gap-2 justify-end">
                    <button
                      onClick={openModalConfirmationRefundPembelian}
                      className="bg-green-500 text-white
                 rounded-md px-3 py-3 hover:bg-green-600 transition duration-300"
                    >
                      Refund
                    </button>
                    <button
                      onClick={closeModalPembelian}
                      className="bg-slate-500 text-white
                 rounded-md px-3 py-3 hover:bg-slate-600 transition duration-300"
                    >
                      Tutup
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
