import React, { useEffect, useState } from 'react'
import { getAuthorizationHeader, http } from '../../config/http';
import { useNavigate } from 'react-router-dom';

const useKategori = () => {
  const [kategori, setKategori] = useState([]);
  const [loadingKategori, setLoadingKategori] = useState(false)
  const navigate = useNavigate();
  async function getKategori() {
    setLoadingKategori(true)
    await http
      .get('/kategori', {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setKategori(res.data);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingKategori(false)

  }
  useEffect(() => {
    getKategori();
  }, [navigate]);
  return {
    kategori,
    loadingKategori
  }
}

export default useKategori