import React from 'react';
import Layouts from '../layouts/Layouts';
import { DinamicTitle } from '../utils/DinamicTitle';

const TransaksiLuar = () => {
  DinamicTitle('Transaksi Luar');

  return (
    <Layouts title={'Transaksi Luar'}>
      <div className="sm:mx-8 mx-2 p-2 mt-2 rounded-md">
        <div className="grid lg:grid-cols-2 gap-2 m-2">
          <div className="w-full bg-white shadow-md  rounded">holo</div>
          <div className="w-full bg-white shadow-md  rounded">hola</div>
        </div>
      </div>
    </Layouts>
  );
};

export default TransaksiLuar;
