import React, { useState, useEffect } from 'react';
import { Blurhash } from 'react-blurhash';

function ImageComponent({ src, width, height, ...props }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = src;
  }, [src]);
  return (
    <>
      <div style={{ display: imageLoaded ? 'none' : 'inline' }}>
        <Blurhash
          hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
          width={width}
          height={height}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
      <img
        src={src}
        alt=""
        onLoad={() => setImageLoaded(true)}
        width={width}
        height={height}
        {...props}
        style={{ display: !imageLoaded ? 'none' : 'inline', maxHeight: '70px', maxWidth: '70px' }}
      />
    </>
  );
}

export default ImageComponent;
