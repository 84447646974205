import axios from 'axios';
// KOMALADEWI ENDPOINT
// const baseURL = process.env.REACT_APP_BASE_URL_KOMALADEWI;
// export const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL_KOMALADEWI;
// KOMALADEWI ENDPOINT

// NURULAROFAH ENDPOINT
const baseURL = process.env.REACT_APP_BASE_URL;
export const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
// NURULAROFAH ENDPOINT

export const getToken = () => (localStorage.getItem('token') ? localStorage.getItem('token') : null);
export const getAuthorizationHeader = () => `Bearer ${getToken()}`;
export const http = axios.create({
  baseURL,
  headers: { Authorization: getAuthorizationHeader() },
});
