import { Dialog, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { Fragment } from 'react';
import { getAuthorizationHeader, http } from '../../config/http';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../components/LoadingIndicator';

export default function ModalProsesTransaksiTertunda({
    isOpenModalRestore,
    closeModalRestore,
    reloadData,
    selectedIds
}) {

    const [loading, setLoading] = useState();
    const handleProsesSemuaTransaksiTertunda = async () => {
        setLoading(true)
        const data = { items: selectedIds }
        await http.post(`/transaksi_tertunda/proses`, data, {
            headers: { Authorization: getAuthorizationHeader() },
        }).then((res) => {
            toast.success('Data berhasil di proses!!', {
                autoClose: 1000
            });
            closeModalRestore();
            reloadData();
            console.log(res.data)
        }).catch((err) => {
            if (err.response.status === 422) {
                toast.error(err.response.data.message);
            }
            return err.response.status
        })
        setLoading(false)
    }
    return (
        <>
            {loading && <LoadingIndicator />}
            <Transition appear show={isOpenModalRestore} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center p-4 min-h-[440px]">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className={`w-full max-w-[30rem] transform overflow-hidden bg-white p-2 print:border text-left align-middle shadow-xl print:shadow-none transition-all`}>
                                    <h2 className='text-lg font-semibold border-b'>Proses Transaksi Penggarus</h2>
                                    <div>
                                        Apakah anda yakin untuk memproses transaksi ini?
                                    </div>

                                    <div className='flex w-full gap-1 justify-end'>
                                        <button className='mt-2 bg-slate-700 text-white px-3 py-2' onClick={closeModalRestore}>
                                            Tutup
                                        </button>
                                        <button disabled={loading} className='mt-2 bg-green-500 text-white px-3 py-2' onClick={handleProsesSemuaTransaksiTertunda}>
                                            {`${loading ? 'Loading...' : 'Proses Transaksi'}`}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
