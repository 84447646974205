import { IconCalendar } from '@tabler/icons-react';
import React from 'react';
import { Calendar } from 'react-date-range';
import SpinnerGetData from '../../components/common/SpinnerGetData';

const LaporanCashflowKasir = ({
  laporanHarian,
  loadingLaporanHarian,
  numberWithCommas,
  roundNumber,
  trxLuar,
  tglFilter,
  handleSelect,
  // barangTerkini,
  // loadingBarangTerkini,
  // totalBeratBarangTerkini,
  //         totalBijiBarangTerkini,
  //         totalHargaBarangTerkini,
}) => {
  return (
    <div className=" rounded-md">
      <div className="bg-white p-5 rounded-md">
        <div className="mb-6 flex justify-between items-center">
          <div className="font-normal text-lg">
            Tanggal {laporanHarian.tanggal} {!tglFilter == null && tglFilter}
          </div>
          <div>
            <div className="dropdown dropdown-end">
              <label
                tabIndex={0}
                className="bg-orange-500 p-2  flex items-center text-white hover:bg-orange-600 rounded-md cursor-pointer"
              >
                <IconCalendar />
              </label>
              <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box -left-72 w-52">
                <Calendar date={new Date()} onChange={handleSelect} />
              </div>
            </div>
          </div>
        </div>
        {loadingLaporanHarian ? (
          <SpinnerGetData />
        ) : (
          <>
            {laporanHarian == undefined ? (
              <div></div>
            ) : (
              <>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-semibold p-4 text-md md:text-lg">KAS</div>
                  <div className="border border-black col-span-2 p-4">
                    <span className="font-normal text-lg"> Rp. {numberWithCommas(laporanHarian.kas)}</span>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-semibold p-4 text-md md:text-lg">PEMBELIAN</div>
                  <div className="border border-black col-span-2 p-4">
                    {laporanHarian.pembelian?.total_harga == 0 ? (
                      <div>Data Kosong</div>
                    ) : (
                      <>
                        <div className="font-normal text-lg">
                          {' '}
                          Rp. {numberWithCommas(laporanHarian?.pembelian?.total_harga)} /{' '}
                          {roundNumber(laporanHarian?.pembelian?.total_berat)} gram / Rp.{' '}
                          {numberWithCommas(Math.round(laporanHarian?.pembelian?.rata_rata))}{' '}
                        </div>
                        <div className="mt-4">
                          {laporanHarian.pembelian?.items === undefined ? (
                            ''
                          ) : (
                            <>
                              {laporanHarian.pembelian?.items?.map((itp, i) => {
                                return (
                                  <div key={i} className="mb-2 ">
                                    <span className="text-lg text-red-500">*</span> {itp.karat} karat :{' '}
                                    {itp.total_berat} gram / Rp.
                                    {numberWithCommas(itp.total_harga)} / Rp.
                                    {numberWithCommas(Math.round(itp.rata_rata))}
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-semibold p-4 text-md md:text-lg">PENJUALAN</div>
                  <div className="border border-black col-span-2 p-4">
                    {laporanHarian.penjualan?.total_harga == 0 ? (
                      <div>Data Kosong</div>
                    ) : (
                      <>
                        <div className="font-normal text-lg">
                          {' '}
                          Rp. {numberWithCommas(laporanHarian?.penjualan?.total_harga)} /{' '}
                          {laporanHarian?.penjualan?.total_berat} gram / Rp.{' '}
                          {numberWithCommas(Math.round(laporanHarian?.penjualan?.rata_rata))}{' '}
                        </div>
                        <div className="mt-4">
                          {laporanHarian.penjualan?.items === undefined ? (
                            ''
                          ) : (
                            <>
                              {laporanHarian.penjualan?.items?.map((itp, i) => {
                                return (
                                  <div key={i} className="mb-2 ">
                                    <span className="text-lg">*</span> {itp.karat} karat : {itp.total_item} biji /{' '}
                                    {itp.total_berat} gram / Rp.
                                    {numberWithCommas(itp.total_harga)} / Rata-rata.{' '}
                                    {numberWithCommas(Math.round(itp.rata_rata, 1))}
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-semibold p-4 text-md md:text-lg">
                    LUAR TRANSAKSI
                    <div>Pengeluaran & Pemasukan</div>
                  </div>
                  <div className="border border-black col-span-2 p-4">
                    <div className="mt-2 text-md italic">
                      {trxLuar.map((res, i) => {
                        return (
                          <div key={i}>
                            <span className="text-lg text-red-500">*</span>
                            {res.title} /
                            <span className="font-normal text-lg">
                              Rp.
                              {numberWithCommas(res.nominal)} ({res.tipe})
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-semibold p-4 text-md md:text-lg">SISA KAS</div>
                  <div className="border border-black col-span-2 p-4">
                    <span className="font-normal text-lg"> Rp. {numberWithCommas(laporanHarian.sisa_kas)}</span>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LaporanCashflowKasir;

// {loadingBarangTerkini ? (
//   <SpinnerGetData />
// ) : (
//   <>
//     <table className="w-full border text-sm text-left text-gray-500 dark:text-gray-400 rounded-md overflow-hidden">
//       <thead className="text-xs sticky top-0 border-b text-gray-700 uppercase bg-gray-50 rounded-md dark:bg-gray-700 dark:text-gray-400">
//         <tr>
//           <th scope="col" className="px-6 py-1">
//             No
//           </th>
//           <th scope="col" className="px-6 py-1">
//             Kategori
//           </th>
//           <th scope="col" className="px-6 py-1">
//             Biji
//           </th>
//           <th scope="col" className="px-6 py-1">
//             Berat
//           </th>
//           <th scope="col" className="px-6 py-1">
//             Modal
//           </th>
//         </tr>
//       </thead>
//       <tbody>
//         {barangTerkini.map((bt, index) => (
//           <tr key={index}>
//             <td className="px-6 py-1 font-semibold text-black">
//               {index + 1}
//             </td>
//             <td className="px-6 py-1 font-semibold text-black">
//               {bt.kategori}
//             </td>
//             <td className="px-6 py-1 font-semibold text-black">
//               {bt.jumlah_item_terkini}
//             </td>
//             <td className="px-6 py-1 font-semibold text-black">
//               {bt.total_berat_terkini}
//             </td>
//             <td className="px-6 py-1 font-semibold text-black">
//               {bt.total_modal_terkini}
//             </td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//     <div className="mt-5  border rounded-md p-2 overflow-x-auto scrollbar-none flex items-center">
//       {/* <div className="font-semibold flex justify-between">JUMLAH :  </div> */}
//       <div className="whitespace-nowrap  border-black mr-6 font-semibold">
//         [{' '}
//         <span className="text-red-600">
//           {totalBijiBarangTerkini}{' '}
//         </span>{' '}
//         biji ] &nbsp; &nbsp; &nbsp;
//       </div>
//       <div className="whitespace-nowrap mr-6  font-semibold">
//         [{' '}
//         <span className="text-red-600">
//           {totalBeratBarangTerkini}{' '}
//         </span>{' '}
//         gram ] &nbsp; &nbsp; &nbsp;
//       </div>

//       <div className="whitespace-nowrap  font-semibold">
//         [{' '}
//         <span className="text-red-600">
//           Rp.
//           {numberWithCommas(
//             totalHargaBarangTerkini == undefined
//               ? '0'
//               : totalHargaBarangTerkini
//           )}
//         </span>{' '}
//         ]
//       </div>
//     </div>
//   </>
// )}
