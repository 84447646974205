import React from 'react';
import { IconPlus } from '@tabler/icons-react';
import Transition from '../../../utils/Transition';

const CustomBerat = ({ trigger, setDropdownOpen, dropdownOpen, dropdown, setCustomBerat }) => {
  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        {/* <img className="w-8 h-8 rounded-full" src={UserAvatar} width="32" height="32" alt="User" /> */}
        <div className="flex items-center truncate">
          <button className="bg-orange-500 text-white p-1 rounded">
            <IconPlus size={20} />
          </button>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
            <div className="flex">
              <input
                onChange={(e) => setCustomBerat(e.target.value)}
                type="number"
                name=""
                id=""
                placeholder="Berat lebih"
                className="p-2 border border-slate-500 rounded-md"
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default CustomBerat;
