import moment from 'moment';
import React from 'react';
import { useState } from 'react';

function KondisiItemPembelian({ kondisiItemPembelian, loadingKondisiItem, cabangFilterSm }) {
  const [openButton, setOpenButton] = useState(false);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  function handleSelect(date) {
    console.log(moment(date).format('DD-MM-YYYY'));
    // setDateFilterPenjualan(moment(date).format('DD-MM-YYYY'))
  }
  return (
    <div className="">
      <div className="px-4 pt-2 pb-1 flex items-center justify-between bg-green-500/50">
        <h6 className="font-bold uppercase text-sm text-slate-900">Kondisi Barang (Pembelian)</h6>
      </div>
      <div className="p-2">
        {loadingKondisiItem ? (
          <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
            <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                }}
              />
              <g className="spinner_Wezc">
                <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                <circle cx={12} cy="21.5" r="1.5" />
              </g>
            </svg>
            Memuat data....
          </div>
        ) : (
          <table className="w-full border text-sm text-left rounded-md overflow-hidden">
            <thead className="text-xs sticky top-0 border-b uppercase bg-gray-50 rounded-md dark:bg-gray-700">
              <tr>
                <th>No</th>
                <th>Kondisi</th>
                <th>biji</th>
                <th>berat</th>
                <th>Harga</th>
              </tr>
            </thead>
            <tbody>
              {kondisiItemPembelian.map((kip, i) => (
                <tr key={i} className="border">
                  <td>{i + 1}.</td>
                  <td>{kip.kondisi_barang}</td>
                  <td>{kip.total_item}</td>
                  <td>{kip.total_berat}</td>
                  <td>Rp {numberWithCommas(kip.total_harga === undefined ? '' : kip.total_harga)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default KondisiItemPembelian;
