import clsx from 'clsx';
import React from 'react';

function Input(props) {
  const { className = 'bg-gray-50' } = props;
  return (
    <input
      {...props}
      className={clsx(
        className,
        'peer h-10 w-full  px-2 border font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-blue-400',
      )}
    />
  );
}

export default Input;
