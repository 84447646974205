import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import ReactToPrint from 'react-to-print';

export default function CetakStroke({ closeModal, isOpen, strukComponent }) {
  function msg() {
    console.log('silahkan cetak stroke');
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={msg}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    <svg className="mb-4 h-20 w-20 text-green-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                      {' '}
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <h2 className="text-2xl mb-4 text-gray-800 text-center font-bold">Transaksi Berhasil</h2>
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="bg-white rounded-lg  flex items-center justify-between">
                      <div>
                        <div className="text-gray-600 w-full text-center mb-8">
                          Silahkan Klik tombol dibawah untuk
                          Mencetak Surat dan mengakhiri transaksi
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center">
                    
                    <ReactToPrint
                      trigger={() => (
                        <button className=" block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-100 bg-green-600 hover:bg-green-700 font-medium border">
                          Cetak Surat
                        </button>
                      )}
                      content={() => strukComponent.current}
                    />

                    <button
                      onClick={() => window.location.reload()}
                      className="block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-100 bg-slate-600 hover:bg-slate-700 font-medium border"
                    >
                      Tutup
                    </button>
                    
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
