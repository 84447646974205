import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAuthorizationHeader, http } from '../../config/http';

export default function EditItem({ isOpenEditItem, kategori, setIsEdit, closeModalEditItem, cabang, detailItem }) {
  const [jenisKategori, setJenisKategori] = useState([]);
  const [jenis_kategori_id, setJenisKategoriId] = useState('');
  const [berat, setBerat] = useState('');
  const [karat, setKarat] = useState('');
  const [nomor_rak, setNomorRak] = useState('');
  const [cabang_id, setCabangId] = useState('');
  const [harga_modal, setHargaModal] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpenEditItem) {
      setJenisKategoriId(detailItem.jenis_kategori_id);
      setBerat(detailItem.berat);
      setKarat(detailItem.karat);
      setCabangId(detailItem.cabang_id);
      setHargaModal(detailItem.harga_modal);
      setNomorRak(detailItem.nomor_rak);
    }
  }, [isOpenEditItem]);

  const [req, setReq] = useState({
    KategoriName: '',
    jenis_kategori_id: '',
  });

  async function SelectJenisKategori(e) {
    let name = e.target.name;
    let value = e.target.value;
    req[name] = value;
    let idData = value;
    await http
      .get(`/jenis/kategori/${idData}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setJenisKategori(res.data.data);

        return res.data.data;
      })
      .then((resId) => {
        setReq(resId);
      })
      .catch((err) => {
        console.log('error jenis kategori', err);
      });
  }

  // console.log({cabang_id, jenis_kategori_id, berat, karat, harga_modal, nomor_rak })

  async function handlesubmitUpdateItem(e) {
    // console.log("hallo")
    e.preventDefault();
    const data = { cabang_id, jenis_kategori_id, berat, karat, harga_modal, nomor_rak };
    // console.log('data::: ', data);
    setLoading(true);
    setIsEdit(true);
    try {
      const response = await http.put(`item/${detailItem.id}`, data, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      toast.success(response.data.message, {
        autoClose: 1000,
      });
      // console.log(response)
      setLoading(false);
      closeModalEditItem();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setIsEdit(false);
  }

  useEffect(() => {
    const regex = /^\d*\.?\d{0,3}$/;
    if (berat && !regex.test(berat)) {
      setBerat(berat.slice(0, -1));
    }
  }, [berat]);

  return (
    <>
      <Transition appear show={isOpenEditItem} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalEditItem}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Edit Barang : <span>{detailItem.jenis_kategori?.nama_jenis}</span>
                  </Dialog.Title>

                  <div className="mt-2">
                    <form>
                      <div className="grid grid-cols-2 gap-2">
                        <div className="mb-6">
                          <label
                            htmlFor="kategori"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Pilih Kategori{' '}
                            <span className="text-red-500 ">
                              ( {detailItem.jenis_kategori?.kategori?.nama_kategori} )
                            </span>
                          </label>
                          <select
                            onChange={SelectJenisKategori}
                            name="KategoriName"
                            className="select select-bordered rounded-none w-full"
                          >
                            <option value="">Pilih Kategori</option>
                            {kategori.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.nama_kategori}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-6">
                          <label
                            htmlFor="kategori"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Pilih Jenis{' '}
                            <span className="text-red-500 ">( {detailItem.jenis_kategori?.nama_jenis} )</span>
                          </label>
                          <select
                            onChange={(e) => setJenisKategoriId(e.target.value)}
                            name="jenis_kategori_id"
                            className="select select-bordered rounded-none w-full"
                          >
                            <option value="">Pilih Jenis</option>
                            {jenisKategori.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.nama_jenis}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="mb-6">
                        <label htmlFor="berat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Berat
                        </label>
                        <input
                          type="number"
                          id="berat"
                          name="berat"
                          autoComplete="off"
                          // value={detailItem.berat || ''}
                          defaultValue={detailItem.berat || ''}
                          onChange={(e) => setBerat(e.target.value)}
                          // onChange={handleOnChange}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        />
                      </div>
                      <div className="mb-6">
                        <label htmlFor="karat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Karat
                        </label>
                        <input
                          type="number"
                          id="karat"
                          name="karat"
                          defaultValue={detailItem.karat || ''}
                          onChange={(e) => setKarat(e.target.value)}
                          // onChange={handleOnChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          required
                        />
                      </div>
                      <div className="mb-6">
                        <label
                          htmlFor="nomor_rak"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Nomor BAKI
                        </label>
                        <input
                          type="number"
                          id="nomor_rak"
                          name="nomor_rak"
                          autoComplete="off"
                          // value={detailItem.nomor_rak || ''}
                          defaultValue={detailItem.nomor_rak || ''}
                          onChange={(e) => setNomorRak(e.target.value)}
                          // onChange={handleOnChange}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        />
                      </div>
                      <div className="mb-6">
                        <label
                          htmlFor="harga_modal"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Harga Modal / Gram
                        </label>
                        {/* <CurrencyInput
                        name="harga_modal"
                        autoComplete='off'
                        // defaultValue={harga_modal}
                        // defaultValue={detailItem.harga_modal || ''}
                        onChange={(value) => setHargaModal(value)} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      /> */}
                        <input
                          type="number"
                          id="harga_modal"
                          name="harga_modal"
                          defaultValue={detailItem.harga_modal || ''}
                          onChange={(e) => setHargaModal(e.target.value)}
                          // onChange={handleOnChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          required
                        />
                      </div>
                      <div className="mb-6">
                        <label
                          htmlFor="cabang_id"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Pilih Etalase <span className="text-red-500 ">( {detailItem.cabang?.nama_cabang} )</span>
                        </label>
                        <select
                          id="cabang_id"
                          name="cabang_id"
                          // onChange={handleOnChange}
                          onChange={(e) => setCabangId(e.target.value)}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="">Pilih Etalase</option>
                          {cabang.map((cb) => (
                            <option value={cb.id} key={cb.id}>
                              {cb.nama_cabang}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex justify-end gap-2">
                        <button
                          type="reset"
                          onClick={closeModalEditItem}
                          // disabled={loading ? true : false}
                          className="text-white  bg-slate-600 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium  text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                        >
                          Tutup
                        </button>
                        {detailItem.is_ready && (
                          <button
                            // type="submit"
                            disabled={loading ? true : false}
                            onClick={handlesubmitUpdateItem}
                            className="text-white  bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium  text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                          >
                            {loading ? 'Proses..' : 'Simpan'}
                          </button>
                        )}


                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
