import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthorizationHeader, http } from '../config/http';
import Layouts from '../layouts/Layouts';
import InputBarangRusak from './InputBarangRusak';
import useCabang from './features/useCabang';
import useKategori from './features/useKategori';
import { DinamicTitle } from '../utils/DinamicTitle';

function BarangRusak() {
  const navigate = useNavigate();
  let [isOpenModalBarangRusak, setIsOpenBarangRusak] = useState(false);
  const [barangRusak, setBarangRusak] = useState([]);
  const [isSubmitBarangRusak, setIsSubmitBarangRusak] = useState(false);
  const [loadingBarangRusak, setLoadingBarangRusak] = useState(false);
  const { cabang } = useCabang();
  const { kategori } = useKategori();
  DinamicTitle('Barang Rusak')

  useEffect(() => {
    async function getBarangRusak() {
      setLoadingBarangRusak(true);
      await http
        .get('/item_rusak', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          // console.log('res::: ', res);
          setBarangRusak(res.data.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
      setLoadingBarangRusak(false);
    }
    getBarangRusak();
  }, [navigate, isSubmitBarangRusak]);

  function closeModalBarangRusak() {
    setIsOpenBarangRusak(false);
  }

  function openModalBarangRusak() {
    setIsOpenBarangRusak(true);
  }
  return (
    <Layouts
      title={'Item Rusak / dikeluarkan'}
      compFilter={
        <button
          onClick={openModalBarangRusak}
          className="bg-blue-500 hover:bg-blue-600 px-4 py-2 text-white "
        >
          Tambah Item
        </button>
      }
    >
      <div >
        <InputBarangRusak
          setIsSubmitBarangRusak={setIsSubmitBarangRusak}
          cabang={cabang}
          kategori={kategori}
          isOpenModalBarangRusak={isOpenModalBarangRusak}
          closeModalBarangRusak={closeModalBarangRusak}
        />
        <div className="flex justify-between mt-0.5 items-center bg-white border-b">
          <div className="flex items-center gap-0.5">
            <select
              className="h-9 bg-white px-2 border font-medium shadow-md  cursor-pointer border-gray-200"
              name=""
              id=""
            >
              <option value="">Pilih Etalase</option>
              {cabang.map((cb) => (
                <option value={cb.id} key={cb.id}>
                  {cb.nama_cabang}
                </option>
              ))}
            </select>
            <select
              className="h-9 bg-white border px-2 font-medium shadow-md  cursor-pointer border-gray-200"
              name=""
              id=""
            >
              <option value="">Pilih Kategori</option>
              {kategori.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.nama_kategori}
                </option>
              ))}
            </select>
          </div>

        </div>
        <div className="relative overflow-x-auto mt-0.5 scrollbar-thin  w-full overflow-hidden">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs sticky top-0 border-b text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-2 border-r w-5 py-2.5 whitespace-nowrap">
                  No.
                </th>
                <th scope="col" className="px-4 border-r w-5 py-2.5 whitespace-nowrap">
                  Nama Item
                </th>

                <th scope="col" className="px-4 border-r w-5 py-2.5 whitespace-nowrap">
                  Berat / Karat
                </th>
                <th scope="col" className="px-4 border-r w-5 py-2.5 whitespace-nowrap">
                  Lokasi
                </th>
                <th scope="col" className="px-4 border-r w-5 py-2.5 whitespace-nowrap">
                  Tanggal
                </th>
              </tr>
            </thead>
            <tbody>
              {barangRusak.length > 0 ? (
                barangRusak.map((barang, index) => (
                  <tr
                    // onClick={handleCheckboxChange}
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-2 w-5 py-2 border-r whitespace-nowrap font-normal">{index + 1}.</td>
                    <td className="px-4 w-5 py-2 border-r whitespace-nowrap font-normal">{barang.jenis_kategori?.nama_jenis}</td>
                    <td className="px-4 w-5 py-2 border-r whitespace-nowrap font-normal">
                      {' '}
                      {barang.berat} gr / {barang.karat} krt
                    </td>
                    <td className="px-4 w-5 py-2 border-r whitespace-nowrap font-normal">{barang.cabang?.nama_cabang}</td>
                    <td className="px-4 w-5 py-2 border-r whitespace-nowrap font-normal">{barang.created_at}</td>
                    {/* <td className="px-4 py-1 whitespace-nowrap font-normal"></td> */}

                    {/* <td className="pl-5 py-1 flex gap-2">
                    <button className="py-1.5 px-0.5 rounded-[2px] bg-green-500 text-white hover:bg-green-600 transition duration-300 text-xs">
                      <IconEye size={20} />
                    </button>
                    <button className="py-1.5 px-0.5 rounded-[2px] bg-orange-500 text-white hover:bg-orange-600 transition duration-300 text-xs">
                      <IconEdit size={20} />
                    </button>
                  </td> */}
                  </tr>
                ))
              ) : loadingBarangRusak ? (
                <span className="">
                  <span className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
                    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                        }}
                      />
                      <g className="spinner_Wezc">
                        <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                        <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                        <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                        <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                        <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                        <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                        <circle cx={12} cy="21.5" r="1.5" />
                      </g>
                    </svg>
                    Memuat data....
                  </span>
                </span>
              ) : (
                <span className="ml-4">Data kosong</span>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layouts>
  );
}

export default BarangRusak;
