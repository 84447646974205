import React from 'react';
import { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { PhotoProvider } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const Layouts = ({ children, title, compFilter, openModal, dateFilter }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex h-screen overflow-hidden">
      {/* <Navbar /> */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto">
        <Header
          openModal={openModal}
          compFilter={compFilter}
          title={title}
          dateFilter={dateFilter}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <main className=" w-full overflow-x-auto scrollbar-none">
          <PhotoProvider>
          <div>{children}</div>
          </PhotoProvider>
        </main>
      </div>
    </div>
  );
};

export default Layouts;
