import { useEffect, useState } from 'react'
import { getAuthorizationHeader, http } from '../../config/http';
import { useNavigate } from 'react-router-dom';

const useCabang = () => {
    const navigate = useNavigate();
    const [cabang, setCabang] = useState([]);

    useEffect(() => {
        async function getCabangSelect() {
            await http
                .get(`cabang`, {
                    headers: { Authorization: getAuthorizationHeader() },
                })
                .then((res) => {
                    setCabang(res.data.data);
                })
                .catch((err) => {
                    if (err.response === 'undefined') {
                        return null;
                    } else if (err.response.status === 401) {
                        navigate('/login');
                    }
                });
        }
        getCabangSelect();
    }, [navigate]);
    return {
        cabang
    }
}

export default useCabang